import { useCallback, useMemo } from 'react'

import { InitiateFn, SubmitFn } from './useStage'
import { AddressCollectionInitiationRequest } from '../../stages/initiate_request'

import Stage from '../../stages/model'
import { AddressCollectionRequest } from '../../stages/submit_request'
import { Address } from '../../types'
import { useJourneyContext } from './'
import useActionTimer, { ActionTimer } from './util/useActionTimer'

const keys = [
  'primaryLine',
  'secondaryLine',
  'city',
  'region',
  'postalCode',
  'country',
] as const

export default function (): {
  initiate: InitiateFn<'address-collection'>
  submit: SubmitFn<'address-collection'>
  timer: ActionTimer
} {
  const timer = useActionTimer()
  const { journey } = useJourneyContext()

  const stage = useMemo(() => {
    return new Stage<'address-collection'>(journey, 'address-collection')
  }, [journey])

  const initiate = useCallback(
    async ({ address }: AddressCollectionInitiationRequest) => {
      timer.start('initiate')
      if (!address) {
        const resp = await stage.initiate({})
        timer.succeeded()
        return resp
      }

      const encryptionKey = await stage.encryptionKey()
      if (!encryptionKey) throw new Error('no encryption key available')

      // const encryptedAddress = await encryptionFn({ address }, stage.encrypt.bind(stage))
      const encryptedAddress = address

      const resp = await stage.initiate({
        address: encryptedAddress,
        // @ts-ignore
        encryptionKey,
      })
      timer.succeeded()
      return resp
    },
    [stage]
  )

  const submit = useCallback(
    async (request: AddressCollectionRequest): Promise<void> => {
      try {
        timer.start('submit')

        const address = await encryptionFn(request, stage.encrypt.bind(stage))

        const resp = await stage.submit({ address })
        timer.succeeded()
        return resp
      } catch (err) {
        timer.failed()
        throw err
      }
    },
    [encryptionFn, stage, timer]
  )

  // @ts-ignore
  return { initiate, submit, timer }
}

async function encryptionFn(
  req: AddressCollectionRequest,
  encrypt: (plaintext: string) => Promise<string>
) {
  const encryptions = keys.map((key) => {
    const fieldValue = req.address[key]
    if (!fieldValue) {
      return Promise.resolve(undefined)
    }
    return encrypt(fieldValue)
  })
  const encryptedResponses = await Promise.all(encryptions)

  return keys.reduce((memo, key, idx) => {
    const encrypted = encryptedResponses[idx]
    if (encrypted) {
      memo[key] = encrypted
    }
    return memo
  }, {} as Address)
}
