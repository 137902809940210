import { useCallback, useMemo } from 'react'

import { InitiateRequest } from '../../stages/initiate_request'
import { InitiateResponse } from '../../stages/initiate_response'
import Stage from '../../stages/model'
import { SubmitRequest } from '../../stages/submit_request'
import { SubmitResponse } from '../../stages/submit_response'
import {
  doesStageTypeRequireInitiation,
  StageType,
  StageTypeWithEncryption,
  StageTypeWithInitiation,
} from '../../types'
import { useJourneyContext } from './useJourneyContext'
import useActionTimer, { ActionTimer } from './util/useActionTimer'

export type EncryptionFn<T extends StageTypeWithEncryption> = (
  request: SubmitRequest<T>['data'],
  encrypt: (plaintext: string) => Promise<string>
) => Promise<SubmitRequest<T>['data']>

export type InitiateFn<T extends StageTypeWithInitiation> = (
  request: InitiateRequest<T>['data']
) => Promise<InitiateResponse<T>>

export type SubmitFn<T extends StageType> = (
  request: SubmitRequest<T>['data']
) => Promise<SubmitResponse<T>>

export function useStage<T extends StageType>(
  type: T,
  encryptionFn?: T extends StageTypeWithEncryption ? EncryptionFn<T> : never
): {
  initiate: T extends StageTypeWithInitiation ? InitiateFn<T> : never
  submit: SubmitFn<T>
  timer: ActionTimer
} {
  const timer = useActionTimer()
  const { journey } = useJourneyContext()

  const stage = useMemo(() => {
    return new Stage<T>(journey, type)
  }, [type])

  const initiate = useCallback(
    (request: InitiateRequest<StageTypeWithInitiation>['data']) => {
      if (!doesStageTypeRequireInitiation(type)) return

      try {
        timer.start('initiate')

        const resp = (stage as Stage<StageTypeWithInitiation>).initiate(request)

        timer.succeeded()
        return resp
      } catch (err) {
        timer.failed()
        throw err
      }
    },
    [type, stage]
  )

  const submit: SubmitFn<T> = useCallback(
    async (request: SubmitRequest<T>['data']) => {
      try {
        timer.start('submit')

        if (encryptionFn) {
          // @ts-ignore
          request = await encryptionFn(request, stage.encrypt.bind(stage))
        }

        const resp = await stage.submit(request)
        timer.succeeded()
        return resp
      } catch (err) {
        timer.failed()
        throw err
      }
    },
    [encryptionFn, stage, timer]
  )

  // @ts-ignore TODO: fix
  return useMemo(() => ({ initiate, submit, timer }), [initiate, submit, timer])
}
