import qs from 'qs'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import useActionTimer from 'hooks/useActionTimer'

import LoginScreen from '../LoginScreen'
import { useOAuth } from './_useOAuth'

const EntryOAuth: FC = () => {
  const { error, clearError, authenticate, query } = useOAuth()

  const timer = useActionTimer()
  const history = useHistory()
  const handleSubmit = useCallback(
    async (uniqueId: string) => {
      try {
        timer.start()

        const resp = await authenticate({ uniqueId })

        const uri = `/pipeline?${qs.stringify(resp)}`
        history.replace(uri)
      } catch (err) {
        console.error(err)
        timer.reset()
      }
    },
    [timer, history, authenticate]
  )

  /**
   * If the url specified a unique id, use that one
   */
  useEffect(() => {
    if (!query.unique_id) return
    handleSubmit(query.unique_id)
  }, [handleSubmit, query])

  const [showLogin, setShowLogin] = useState(false)
  useEffect(() => {
    if (query.unique_id && !error) return
    setShowLogin(true)
  }, [query.unique_id, error])

  if (!showLogin) return <></>

  return <LoginScreen error={error} timer={timer} onChange={clearError} onSubmit={handleSubmit} />
}

export default EntryOAuth
