export type ErrorResponse<T extends Record<string, any> = Record<string, any>> = {
  statusCode: number
} & APIErrorResponse<T>

type APIErrorResponse<T extends Record<string, any> = Record<string, any>> = {
  error: string
  errors?: { [P in keyof T]: string }
  code?: string
}

export class APIError extends Error {
  statusCode: number
  apiResponse: APIErrorResponse

  constructor(statusCode: number, apiResponse: APIErrorResponse) {
    super(apiResponse.error)
    this.statusCode = statusCode
    this.apiResponse = apiResponse
  }

  get error(): string {
    return this.apiResponse.error
  }

  get fieldErrors(): APIErrorResponse['errors'] {
    return this.response.errors
  }

  get errorCode(): string | undefined {
    return this.response.code
  }

  get response(): ErrorResponse {
    return Object.assign({}, this.apiResponse, {
      statusCode: this.statusCode,
    })
  }
}

export function isAPIError(err: any): err is APIError {
  if (!(err instanceof Error)) return false
  return 'statusCode' in err && 'apiResponse' in err
}

export function isAPIErrorResponse<T extends Record<string, any> = Record<string, any>>(err: ErrorResponse | any): err is ErrorResponse<T> {
  return 'error' in err
}
