import { ConfigurationFor } from './stage_configuration'
import { MetadataFor } from './stage_metadata'
import { PipelineStage, StageFor, StageType } from './stages'

/**
 * Initiation
 */
export const stageTypesWithInitiation = [
  'address-collection',
  'credit-card-payment',
  'device-authentication',
  'document-collection',
  'document-signature',
  'drivers-license',
  'facial-authentication-3d',
  'facial-enrollment',
  'form',
  'government-id',
  'government-id-selfie',
  'knowledge-based-authentication',
  'national-id-card',
  'ocr',
  'passport',
  'payment-details',
  'phone-number-registration',
  'phone-number-verification',
  'social-security-number',
  'totp-registration',
  'webauthn-authentication',
  'webauthn-registration',
] as const

export type StageTypeWithInitiation = (typeof stageTypesWithInitiation)[number]

/**
 * Encryption
 */
export const stageTypesRequiringEncryption = [
  'address-collection',
  'credit-card-payment',
  'document-collection',
  'document-signature',
  'drivers-license',
  'form',
  'government-id',
  'knowledge-based-authentication',
  'national-id-card',
  'passport',
  'payment-details',
  'social-security-number',
] as const

export type StageTypeWithEncryption =
  (typeof stageTypesRequiringEncryption)[number]

/**
 * Mobile-only Stages
 */
export const stageTypesRequiringMobile = [
  'drivers-license',
  'facial-authentication-3d',
  'facial-enrollment',
  'government-id-selfie',
  'national-id-card',
  'ocr',
  'passport',
] as const

export type StageTypeRequiringMobile =
  (typeof stageTypesRequiringMobile)[number]

/**
 * Document
 */
export const stageTypesWithDocument = [
  'document-signature',
  'schedule',
] as const

export type StageTypeWithDocument = (typeof stageTypesWithDocument)[number]

/**
 * Requires Configuration
 */
export const stageTypesRequiringConfiguration = [
  'credit-card-payment',
  'schedule',
] as const

export type StageTypeRequiringConfiguration =
  (typeof stageTypesRequiringConfiguration)[number]

/**
 * Requires existing customer
 */
export const stageTypesRequiringUserId = [
  'face-authentication',
  'facial-authentication-3d',
  'facial-authentication',
  'phone-number-verification',
  'totp-authentication',
  'webauthn-authentication',
] as const

export type StageTypeRequiringUserId =
  (typeof stageTypesRequiringUserId)[number]

/**
 * Helper functions
 */

export function doesStageTypeRequireInitiation(
  type: StageType,
): type is StageTypeWithInitiation {
  return stageTypesWithInitiation.some((s) => s === type)
}

export function doesStageRequireInitiation(
  stage: StageFor<StageType>,
): stage is StageFor<StageTypeWithInitiation> {
  return doesStageTypeRequireInitiation(stage.type)
}

export function doesStageTypeRequireEncryption(
  type: StageType,
): type is StageTypeWithEncryption {
  return stageTypesRequiringEncryption.some((s) => s === type)
}

export function doesStageTypeRequireMobile(
  stageType: StageType,
): stageType is StageTypeRequiringMobile {
  return (stageTypesRequiringMobile as readonly StageType[]).includes(stageType)
}

export function doesStageRequireMobile(
  stage: PipelineStage,
): stage is StageFor<StageTypeRequiringMobile> {
  return doesStageTypeRequireMobile(stage.type)
}

export function doesStageHaveADocument(
  stage: PipelineStage,
): stage is StageFor<StageTypeWithDocument> {
  return doesStageTypeHaveADocument(stage.type)
}

export function doesStageTypeHaveADocument(
  type: StageType,
): type is StageTypeWithDocument {
  return stageTypesWithDocument.some((s) => s === type)
}

export function doesStageTypeRequireConfiguration(
  type: StageType,
): type is StageTypeRequiringConfiguration {
  return stageTypesRequiringConfiguration.some((s) => s === type)
}

export function doesStageRequireConfiguration(
  stage: Pick<StageFor<StageType>, 'type' | 'metadata'>,
) {
  if (doesStageTypeRequireConfiguration(stage.type)) return true

  if (stage.type === 'document-signature') {
    const { variables } = stage.metadata as MetadataFor<'document-signature'>
    return variables && variables.length > 0
  }

  return false
}

type Configuration = Partial<{
  [key in StageType]: ConfigurationFor<key>
}>

export function stageTypesThatRequireConfiguration(
  stages: Pick<StageFor<StageType>, 'type' | 'metadata'>[],
  configuration: Configuration,
): StageType[] {
  const stageTypes: StageType[] = []

  stages.forEach((stage) => {
    if (doesStageRequireConfiguration(stage)) {
      stageTypes.push(stage.type)
    }
  })

  return stageTypes.filter((type) => !(type in configuration))
}
