import React, { FC } from 'react'

import {
  ReactComponent as DocumentCollection,
} from '~p/assets/illustrations/document-collection.svg'
import { ReactComponent as FacialBiometrics } from '~p/assets/illustrations/facial-biometrics.svg'
import { ReactComponent as SuccessScreen } from '~p/assets/illustrations/success-screen.svg'
import { ReactComponent as Webauthn } from '~p/assets/illustrations/webauthn.svg'

const illustrations = {
  'facial-biometrics': FacialBiometrics,
  'document-collection': DocumentCollection,
  'success-screen': SuccessScreen,
  webauthn: Webauthn,
} as const

type Props = {
  type: keyof typeof illustrations
}

const Illustration: FC<Props> = ({ type }) => {
  const I = illustrations[type]

  return (
    <main className="items-center pt-16 sm:pb-8">
      <I className="h-56 text-theme-primary" />
    </main>
  )
}

export default Illustration
