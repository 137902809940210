import { useCallback, useMemo, useState } from 'react'

import { isAPIErrorResponse } from 'sdk/common/errors'
import { SAML2Request } from 'sdk/entries/saml2'
import { unwrapResult } from '@reduxjs/toolkit'

import useAppDispatch from 'hooks/useAppDispatch'
import { entriesSAML2 } from 'thunks/entries/saml2'
import { parseQueryString } from 'util/queryString'

type QueryParams = {
  SAMLRequest: string
  RelayState?: string
  EntityID: string
}

const query = parseQueryString<QueryParams>()

export function useSAML2() {
  const [error, setError] = useState<string>()

  const dispatch = useAppDispatch()
  const authenticate = useCallback(
    async ({ uniqueId }: { uniqueId?: string }) => {
      try {
        const req: SAML2Request = {
          samlRequest: query.SAMLRequest,
          relayState: query.RelayState,
          entityId: query.EntityID,
          uniqueId,
        }

        return await dispatch(entriesSAML2(req)).then(unwrapResult)
      } catch (err) {
        if (isAPIErrorResponse(err)) {
          if (err.code === 'REQUEST_EXPIRED') {
            setError('The request has expired')
          } else if (err.code === 'UNKNOWN_UNIQUE_ID') {
            setError('That Unique ID is invalid')
          } else if (err.error) {
            setError(err.error)
          } else {
            setError('An error occurred initiating the SAML flow')
          }
        }
        throw err
      }
    },
    [setError, dispatch]
  )

  const clearError = useCallback(() => {
    setError(undefined)
  }, [setError])

  return useMemo(() => ({ authenticate, error, clearError }), [authenticate, error, clearError])
}
