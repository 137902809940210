import toast from 'react-hot-toast'
import { useEffect } from 'react'
import { useJWTSelector } from 'store'

import { BootstrapReply } from 'sdk/executions/bootstrap'
import { StageTypeWithHook } from 'sdk/react/hooks/map'
import { journey } from 'sdk/react/hooks/useJourneyContext'
import { StageFor } from 'sdk/types'
import { unwrapResult } from '@reduxjs/toolkit'

import useAppDispatch from 'hooks/useAppDispatch'
import { Bootstrap, executionsBootstrap } from 'thunks/executions/bootstrap'
import { transfersUnwrap } from 'thunks/transfers/unwrap'

export function useBootstrap(txToken: string | undefined) {
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!txToken) return

    async function run() {
      if (!txToken) return
      try {
        const { token } = await dispatch(transfersUnwrap({ token: txToken })).then(unwrapResult)
        journey.setAuthToken(token)
      } catch (err) {
        console.error(err)
        toast.error('Unable to transfer to mobile')
      }
    }

    run()
  }, [txToken, dispatch])

  const { token } = useJWTSelector()
  useEffect(() => {
    if (!token) return

    journey.executions.bootstrap().then(resp => {
      const bootstrap = translateBootstrap(resp)
      dispatch(executionsBootstrap(bootstrap))
    })
  }, [token, dispatch])
}

export function translateBootstrap(reply: BootstrapReply): Bootstrap {
  const { pipeline } = reply

  return {
    ...reply,
    pipeline: {
      ...pipeline,
      stages: pipeline.stages as StageFor<StageTypeWithHook>[],
    },
  }
}
