import { Descendant, Text } from 'slate'

export type SlateNodeType =
  | 'link'
  | 'h1'
  | 'h2'
  | 'numbered-list'
  | 'bulleted-list'
  | 'list-item'
  | 'paragraph'

export type SlateValue = SlateNode[]

export type SlateNode = Descendant & {
  type: SlateNodeType
} & SlateTextOptions &
  SlateNodeOptions

export type SlateTextOptions = {
  bold?: boolean
  italic?: boolean
  code?: boolean
}

export type SlateNodeOptions = {
  url?: boolean
}

function serializeNode(node: SlateNode): string {
  if (Text.isText(node)) {
    var string = node.text
    if (node.bold) {
      string = `<strong>${string}</strong>`
    }
    if (node.italic) {
      string = `<em>${string}</em>`
    }
    if (node.code) {
      string = `<code>${string}</code>`
    }
    return string
  }

  const children = node.children.map(n => serializeNode(n as SlateNode)).join('')

  switch (node.type) {
    case 'h1':
      return `<h1>${children}</h1>`
    case 'h2':
      return `<h2>${children}</h2>`
    case 'paragraph':
      return `<p>${children}</p>`
    case 'bulleted-list':
      return `<ul>${children}</ul>`
    case 'numbered-list':
      return `<ol>${children}</ol>`
    case 'list-item':
      return `<li>${children}</list>`
    case 'link':
      return `<a href="${node.url}" target="_blank" rel="noreferrer">${children}</a>`
    default:
      return ''
  }
}

export default function serializeSlateValue(value: SlateValue): string {
  return value.map(serializeNode).join('')
}
