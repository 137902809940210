import classNames from 'classnames'
import React, { FC } from 'react'

import s from './index.module.scss'

type Props = {
  headline?: string
  className?: string
}

const Header: FC<Props> = ({ headline, className, children }) => {
  return (
    <header className={classNames(s.Header, className)}>
      {headline && <h6 className="std">{headline}</h6>}
      <h4 className="std">{children}</h4>
    </header>
  )
}

export default Header
