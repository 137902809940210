import classNames from 'classnames'
import React, { FC } from 'react'

import { ReactComponent as AmericanExpressIcon } from '~p/assets/payment-methods/american-express.svg'
import { ReactComponent as DinersClubIcon } from '~p/assets/payment-methods/diners-club.svg'
import { ReactComponent as DiscoverIcon } from '~p/assets/payment-methods/discover.svg'
import { ReactComponent as JCBIcon } from '~p/assets/payment-methods/jcb.svg'
import { ReactComponent as MaestroIcon } from '~p/assets/payment-methods/maestro.svg'
import { ReactComponent as MastercardIcon } from '~p/assets/payment-methods/mastercard.svg'
import { ReactComponent as UnionPayIcon } from '~p/assets/payment-methods/unionpay.svg'
import { ReactComponent as VisaIcon } from '~p/assets/payment-methods/visa.svg'

const Icons = {
  visa: VisaIcon,
  mastercard: MastercardIcon,
  'american-express': AmericanExpressIcon,
  'diners-club': DinersClubIcon,
  discover: DiscoverIcon,
  jcb: JCBIcon,
  unionpay: UnionPayIcon,
  maestro: MaestroIcon,
}

export type CCBrand =
  | 'visa'
  | 'mastercard'
  | 'american-express'
  | 'diners-club'
  | 'discover'
  | 'jcb'
  | 'unionpay'
  | 'maestro'

type Props = {
  brand: CCBrand
  className?: string
}

const CreditCardBrandIcon: FC<Props> = ({ brand, className }) => {
  const Icon = Icons[brand]
  return <Icon className={classNames(className)} />
}

export default CreditCardBrandIcon
