import React, { ReactElement, useCallback, useState } from 'react'

import { ErrorResponse, isAPIErrorResponse } from '../../common/errors'
import { InitiateRequest } from '../../stages/initiate_request'
import { SubmitRequest } from '../../stages/submit_request'
import { StageFor, StageTypeRequiringMobile, StageTypeWithInitiation } from '../../types'
import stageHooks, { StageTypeWithHook } from '../hooks/map'
import { InitiateFn, SubmitFn } from '../hooks/useStage'
import { ActionTimer } from '../hooks/util/useActionTimer'

export type StageProps<T extends StageTypeWithHook> = {
  stage: StageFor<T>
  timer: ActionTimer
  error?: ErrorResponse
  onContinue: T extends StageTypeRequiringMobile ? () => void : never
  onInitiate: T extends StageTypeWithInitiation ? InitiateFn<T> : never
  onSubmit: SubmitFn<T>
  onReset: () => void
}

export type StagesProps<T extends StageTypeWithHook = StageTypeWithHook> = {
  type: T
  props: StageProps<T>
}

export type RenderFn = (props: StagesProps) => ReactElement

type PipelineStageProps<T extends StageTypeWithHook = StageTypeWithHook> = {
  stage: StageFor<T>
  hook: typeof stageHooks[T]
  onSuccess: () => void
  onError: (error: Error) => void
  render: (props: StagesProps<T>) => React.ReactElement
}

const PipelineStage: React.FC<PipelineStageProps> = ({ stage, hook, onSuccess, render }) => {
  const { initiate, submit, timer } = hook()

  const [error, setError] = useState<ErrorResponse>()
  const handleError = useCallback((err: ErrorResponse | Error | unknown) => {
    if (isAPIErrorResponse(err)) {
      setError(err)
    } else if (err instanceof Error) {
      setError({
        statusCode: -1,
        error: err.message,
      })
    } else {
      console.error('[Journey SDK] unknown error:', err)
      setError({
        statusCode: -1,
        error: 'Unexpected error submitting the information',
      })
    }
  }, [])

  const onInitiate: InitiateFn<StageTypeWithInitiation> = useCallback(
    async (req: InitiateRequest<StageTypeWithInitiation>['data']) => {
      try {
        setError(undefined)

        const initiateFn: InitiateFn<StageTypeWithInitiation> = initiate
        return initiateFn(req)
      } catch (err) {
        handleError(err)
        throw err
      }
    },
    [initiate]
  )

  const onSubmit: SubmitFn<StageTypeWithHook> = useCallback(
    async (req: SubmitRequest<StageTypeWithHook>['data']) => {
      try {
        setError(undefined)

        const submitFn: SubmitFn<StageTypeWithHook> = submit
        await submitFn(req)

        setTimeout(onSuccess, timer.delay - 250)
      } catch (err) {
        handleError(err)
      }
    },
    [submit]
  )

  const onReset = useCallback(() => {
    setError(undefined)
  }, [])

  return render({
    type: stage.type,
    props: {
      stage,
      onContinue: onSuccess,
      onInitiate,
      onSubmit,
      onReset,
      error,
      timer,
    },
  })
}

export default PipelineStage
