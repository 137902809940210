import { APIError, ErrorResponse } from 'sdk/common/errors'
import { SecureLinkReply, SecureLinkRequest } from 'sdk/entries/secure_link'
import { journey } from 'sdk/react/hooks/useJourneyContext'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const entriesSecureLink = createAsyncThunk<
  SecureLinkReply,
  SecureLinkRequest,
  { rejectValue: ErrorResponse }
>('entries/link', async (req, api) => {
  try {
    return await journey.entries.secureLink(req)
  } catch (err) {
    if (err instanceof APIError) {
      return api.rejectWithValue(err.response)
    } else {
      throw err
    }
  }
})
