import React, { FC } from 'react'
import { Route, Switch } from 'react-router'
import r from 'routes'

import Error from '~p/pages/Error'
import Execution from '~p/pages/Execution'
import Success from '~p/pages/Success'

const TransferredToMobile = React.lazy(() => import('~p/pages/TransferredToMobile'))

const Pipeline: FC = () => {
  return (
    <Switch>
      <Route path={r.pipeline.bootstrap} exact component={Execution} />
      <Route path={r.pipeline.success} exact component={Success} />
      <Route path={r.pipeline.error()} exact component={Error} />
      <Route path={r.transfer.incoming()} exact component={TransferredToMobile} />
    </Switch>
  )
}

export default Pipeline
