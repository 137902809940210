import classNames from 'classnames'
import React, { FC } from 'react'

import styles from './index.module.scss'

type Props = {
  size?: 'sm' | 'md' | 'lg'
  color?: 'primary' | 'white'
  className?: string
}

const LoadingDots: FC<Props> = ({ size = 'md', color = 'primary', className }) => {
  return (
    <div
      className={classNames(
        styles.Container,
        {
          [styles.Small]: size === 'sm',
          [styles.Medium]: size === 'md',
          [styles.Large]: size === 'lg',
        },
        {
          [styles.Primary]: color === 'primary',
          [styles.White]: color === 'white',
        },
        className
      )}
    >
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}

export default LoadingDots
