import React, { FC } from 'react'
import { t } from 'translation'

import FooterButton from '~p/components/atoms/FooterButton'
import Header from '~pc/organisms/Header'

type Props = {
  onSelect: (selection: 'credit-card' | 'ach') => void
}

const MethodSelection: FC<Props> = ({ onSelect }) => {
  return (
    <section>
      <Header headline={t('Payment Method')}>
        {t('Which payment method would you like to use?')}
      </Header>
      <main className="px-4 pt-16 sm:px-0">
        <div className="flex flex-col space-y-4">
          <FooterButton onClick={() => onSelect('credit-card')}>{t(`Credit Card`)}</FooterButton>
          <FooterButton onClick={() => onSelect('ach')}>{t('Bank Account (ACH)')}</FooterButton>
        </div>
      </main>
    </section>
  )
}

export default MethodSelection
