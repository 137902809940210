import React, { FC, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { useEffectOnce } from 'react-use'

import { journey } from 'sdk/react/hooks/useJourneyContext'

import LoadingDots from 'components/molecules/LoadingDots'

type Error = 'invalid-code'

type RouteParams = {
  code: string
}

const Shortcode: FC = () => {
  const [error, setError] = useState<Error>()
  const history = useHistory()
  const { params } = useRouteMatch<RouteParams>()

  useEffectOnce(() => {
    ;(async () => {
      try {
        const { code } = params
        const { uri } = await journey.shortcodes.retrieve({ code })
        history.push(uri)
      } catch (err) {
        setError('invalid-code')
      }
    })()
  })

  if (error) {
    return (
      <div className="w-full max-w-4xl mx-auto mt-20 text-xl font-bold text-center">
        The shortcode is either invalid or has expired.
      </div>
    )
  }

  return (
    <div>
      <LoadingDots className="mt-32" />
    </div>
  )
}

export default Shortcode
