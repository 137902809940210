import React, { FC } from 'react'

import { Agent } from 'sdk/types'

import { ReactComponent as StarIcon } from '~p/assets/star-icon.svg'

import s from './AgentHeader.module.scss'

type Props = {
  agent: Agent
}

const AgentHeader: FC<Props> = ({ agent }) => {
  const hasLastInitial = agent.lastInitial.replaceAll(/ /g, '').length > 0

  return (
    <div className={s.AgentHeader}>
      <div className="py-4 pl-4">
        {agent.photoUrl && <img src={agent.photoUrl} alt={agent.firstName} />}
      </div>
      <div className={s.Agent}>
        <div>
          {agent.firstName} {hasLastInitial ? `${agent.lastInitial}.` : ''}
        </div>
        <div>{agent.publicIdentifier}</div>
      </div>
      <div>
        {agent.rating && (
          <div className={s.Rating}>
            <StarIcon />
            <div>{agent.rating.toFixed(2)}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AgentHeader
