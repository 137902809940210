import { createSlice } from '@reduxjs/toolkit'

import { executionsBootstrap } from 'thunks/executions/bootstrap'

export type ExecutionState = {
  id?: string
  pipelineId?: string
  sessionId?: string
  agentId?: string
}

const initialState: ExecutionState = {}

const slice = createSlice({
  name: 'execution',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(executionsBootstrap.fulfilled, (state, { payload }) => {
      return {
        id: payload.id,
        pipelineId: payload.pipeline.id,
        sessionId: payload.session?.id,
        agentId: payload.agent?.id,
      }
    })
  },
})

export default slice

export const actions = slice.actions
