import classNames from 'classnames'
import React, { FC } from 'react'

type Props = {
  className?: string
}

const ErrorMessage: FC<Props> = ({ className, children }) => {
  return <div className={classNames('text-red-600 font-bold text-sm', className)}>{children}</div>
}

export default ErrorMessage
