import QueryString from 'qs'
import React, { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import useActionTimer from 'hooks/useActionTimer'
import LoginScreen from '../LoginScreen'
import { useSAML2 } from './_useSAML2'

const EntrySAML2: FC = () => {
  const { error, clearError, authenticate } = useSAML2()

  const timer = useActionTimer()
  const history = useHistory()
  const handleSubmit = useCallback(
    async (uniqueId: string) => {
      try {
        timer.start()

        const resp = await authenticate({ uniqueId })

        const uri = `/pipeline?${QueryString.stringify(resp)}`
        history.replace(uri)
      } catch (err) {
        console.error(err)
        timer.reset()
      }
    },
    [timer, history, authenticate]
  )

  return <LoginScreen error={error} timer={timer} onChange={clearError} onSubmit={handleSubmit} />
}

export default EntrySAML2
