import { JourneyClient } from '../'
import { CreateReply, CreateRequest } from './create'

class DeviceClient {
  private client: JourneyClient

  constructor(client: JourneyClient) {
    this.client = client
  }

  async create(request: CreateRequest): Promise<CreateReply> {
    return await this.client.request('POST', `/api/client/devices`, request)
  }
}

export default DeviceClient
