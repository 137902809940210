import { ConnectedRouter } from 'connected-react-router'
import Pipeline from 'pipeline'
import React, { FC, Suspense, useEffect } from 'react'
import Div100vh from 'react-div-100vh'
import { Toaster } from 'react-hot-toast'
import { Provider } from 'react-redux'
import { Route, RouteProps, Switch } from 'react-router'
import { useLocation } from 'react-use'
import r from 'routes'

import { JourneyContext, useJourneyContext } from './sdk/react/hooks'
import { journey } from './sdk/react/hooks/useJourneyContext'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import LoadingDots from 'components/molecules/LoadingDots'
import SSOScan from 'components/pages/SSO/Scan'

import EntryOAuth2 from '~p/pages/Entry/OAuth2'
import EntrySAML2 from '~p/pages/Entry/SAML2'
import EntrySecureLink from '~p/pages/Entry/SecureLink'
import Shortcode from '~p/pages/Shortcode'

import store, { history } from './store'

const TransferredToMobile = React.lazy(() => import('~p/pages/TransferredToMobile'))

if (_env_.SENTRY_DSN) {
  Sentry.init({
    dsn: _env_.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    attachStacktrace: true,
    environment: _env_.JOURNEY_ENV,
  })
}

journey.setOption('origin', _env_.JOURNEY_ORIGIN)

const App: FC<RouteProps> = () => {
  const context = useJourneyContext()

  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <JourneyContext.Provider value={context}>
      <Div100vh className="flex flex-col selection:bg-theme-primary selection:text-white">
        <Toaster />
        <Suspense fallback={<LoadingDots className="mt-16" />}>
          <Switch>
            <Route path="/pipeline" component={Pipeline} />
            <Route path={r.entry.oauth2} component={EntryOAuth2} />
            <Route path={r.entry.saml2} component={EntrySAML2} />
            <Route path={r.entry.secureLink} component={EntrySecureLink} />
            <Route path={r.shortcodes.retrieve()} component={Shortcode} />
            <Route path={r.transfer.incoming()} component={TransferredToMobile} />
            <Route path={r.sso.scan} component={SSOScan} />
          </Switch>
        </Suspense>
      </Div100vh>
    </JourneyContext.Provider>
  )
}

const Wrapper: FC = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>
)

export default Wrapper
