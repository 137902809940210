function str2ab(str: string): ArrayBuffer {
  const buf = new ArrayBuffer(str.length)
  const bufView = new Uint8Array(buf)
  for (let i = 0, strLen = str.length; i < strLen; i += 1) {
    bufView[i] = str.charCodeAt(i)
  }
  return buf
}

function ab2str(buffer: ArrayBuffer): string {
  const arr = Array.from(new Uint8Array(buffer))
  const keyStr = arr.map((byte) => String.fromCharCode(byte)).join('')
  return btoa(keyStr)
}

function concat(buffer1: Uint8Array, buffer2: Uint8Array): Uint8Array {
  const tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength)
  tmp.set(buffer1, 0)
  tmp.set(buffer2, buffer1.byteLength)
  return tmp
}

const createAESKey = async (): Promise<{ iv: Uint8Array; key: CryptoKey }> => {
  const iv = crypto.getRandomValues(new Uint8Array(12))
  const keyData = crypto.getRandomValues(new Uint8Array(32))
  const alg = { name: 'AES-GCM', iv }
  const key = await crypto.subtle.importKey('raw', keyData, alg, true, [
    'encrypt',
  ])
  return { iv, key }
}

const encryptWithAES = async (
  iv: Uint8Array,
  key: CryptoKey,
  plaintext: string
): Promise<Uint8Array> => {
  const ptUint8 = new TextEncoder().encode(plaintext)
  const alg = { name: 'AES-GCM', iv }
  const ctBuffer = await crypto.subtle.encrypt(alg, key, ptUint8)
  return new Uint8Array(ctBuffer)
}

const importRsaKey = (pem: string): PromiseLike<CryptoKey> => {
  const pemHeader = '-----BEGIN PUBLIC KEY-----'
  const pemFooter = '-----END PUBLIC KEY-----'
  const pemContents = pem
    .substring(pemHeader.length + 1, pem.length - pemFooter.length - 1)
    .replace(/\n/g, '')
  const binaryDerString = atob(pemContents)
  const binaryDer = str2ab(binaryDerString)

  const alg = { name: 'RSA-OAEP', hash: 'SHA-256' }
  return crypto.subtle.importKey('spki', binaryDer, alg, false, ['encrypt'])
}

const encryptWithRSA = async (
  publicKey: string,
  plaintext: ArrayBuffer
): Promise<ArrayBuffer> => {
  const rsaPublicKey = await importRsaKey(publicKey)
  const alg = { name: 'RSA-OAEP' }
  return crypto.subtle.encrypt(alg, rsaPublicKey, plaintext)
}

/**
 * Encrypt data using a just in time, randomly generated AES key.
 * The AESkey is itself encrypted using the RSA publicKey provided
 * as a parameter. The encryptedAESKey is sent with the ciphertext to
 * the jig, which uses the matching RSA private key to decrypt the AESKey,
 * which then is used to decrypt the ciphertext.
 * @param  {string}  publicKey the public key - used to encrypt the AES key
 * @param  {string}  plaintext the plaintext data to be encrypted via AES
 * @return {
 *  {ArrayBuffer} key [the RSA-encoded AES key used to encrypt the data],
 *  {Uint8Array} ciphertext [the encrypted data]
 * }
 */
const generateEncryptionKey = async (
  publicKey: string
): Promise<[Uint8Array, CryptoKey, Uint8Array]> => {
  // create nonce and AES key
  const { iv, key } = await createAESKey()

  // encrypt the AES key with RSA publicKey
  const rawKey = await crypto.subtle.exportKey('raw', key)
  const encryptedAESKey = await encryptWithRSA(publicKey, rawKey)

  // prepend the nonce to the encryptedAESKey
  const aesArray = new Uint8Array(encryptedAESKey)
  const keyConcat = concat(iv, aesArray)

  // base64 encode the nonce+encryptedAESKey
  return [iv, key, keyConcat]
}

export class EncryptionHelper {
  private iv?: Uint8Array
  private aesKey?: CryptoKey
  private encryptionKey?: string

  constructor(publicKey: string) {
    generateEncryptionKey(publicKey).then((res) => {
      const [iv, aesKey, key] = res
      this.iv = iv
      this.aesKey = aesKey
      this.encryptionKey = ab2str(key)
    })
  }

  async encrypt(plaintext: string): Promise<string> {
    if (!this.iv || !this.aesKey) throw new Error('Not ready to encrypt data')
    const ciphertext = await encryptWithAES(this.iv, this.aesKey, plaintext)
    return ab2str(ciphertext)
  }

  async key(): Promise<string> {
    return this.encryptionKey || ''
  }
}

// export const Encryptor = (
//   typeof global !== 'undefined' ? global.Journey.Encryptor : EncryptionHelper
// ) as typeof EncryptionHelper
export const Encryptor = EncryptionHelper as typeof EncryptionHelper
