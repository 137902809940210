import googleFonts from 'vendor/google-fonts'

export default async function loadGoogleFont(fontName: string, weight: 'regular' | 'bold') {
  const font = googleFonts.find(f => f.name === fontName)
  if (!font) return

  const fontFace = new FontFace(font.name, `url(${font[weight]})`)
  await fontFace.load()
  fontFace.weight = weight === 'regular' ? '400' : '600'
  document.fonts.add(fontFace)

  const varType = weight === 'regular' ? 'body' : 'headers'
  document.documentElement.style.setProperty(`--theme-font-${varType}`, `'${fontName}', sans-serif`)
}
