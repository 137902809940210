import { APIError, ErrorResponse } from 'sdk/common/errors'
import { SAML2Reply, SAML2Request } from 'sdk/entries/saml2'
import { journey } from 'sdk/react/hooks/useJourneyContext'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const entriesSAML2 = createAsyncThunk<
  SAML2Reply,
  SAML2Request,
  { rejectValue: ErrorResponse }
>('entries/saml2', async (req, api) => {
  try {
    return await journey.entries.saml2(req)
  } catch (err) {
    if (err instanceof APIError) {
      return api.rejectWithValue(err.response)
    } else {
      throw err
    }
  }
})
