import { BootstrapReply, Branding } from 'sdk/executions/bootstrap'
import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { executionsBootstrap } from 'thunks/executions/bootstrap'
import loadGoogleFont from 'util/googleFont'

export type BrandingState = {
  branding?: Branding
}

const initialState: BrandingState = {
  branding: undefined,
}

const setBranding: CaseReducer<BrandingState, PayloadAction<Branding>> = (state, { payload }) => {
  return { branding: payload }
}

const slice = createSlice({
  name: 'branding',
  initialState,
  reducers: {
    setBranding,
  },
  extraReducers: builder => {
    builder.addCase(executionsBootstrap.fulfilled, (state, { payload }) => {
      if (!payload.branding) return

      state.branding = payload.branding

      const s = document.documentElement.style
      s.setProperty('--theme-primary', payload.branding.primaryColor)

      const headerFont = payload.branding.googleFontHeaders
      if (headerFont) {
        loadGoogleFont(headerFont, 'bold')
      }

      const bodyFont = payload.branding.googleFontBody
      if (bodyFont) {
        loadGoogleFont(bodyFont, 'regular')
      }
    })
  },
})

export default slice
export const actions = slice.actions
