import { t } from 'i18next'
import React, { FC, useEffect, useMemo } from 'react'
import { useExecutionSelector, usePipelineSelector, useSessionSelector } from 'store'

import SlateHTML from 'components/molecules/SlateHTML'
import { isMobile } from 'util/isMobile'

import { ReactComponent as JourneyLogo } from '~p/assets/secured-by-journey-logo.svg'
import DataPrivacy from '~p/components/molecules/DataPrivacy'
import Header from '~p/components/organisms/Header'
import useBranding from '~p/hooks/useBranding'
import Illustration from '~pc/atoms/Illustration'
import Frame from '~pc/molecules/Frame'

import s from './Success.module.scss'

const Success: FC = () => {
  const { logoUrl } = useBranding()
  const { agent } = useSessionSelector()
  const { pipeline } = usePipelineSelector()

  const { session } = useSessionSelector()
  const execution = useExecutionSelector()
  useEffect(() => {
    if (!execution || !session) return

    const payload = {
      source: 'journey',
      execution: {
        id: execution.id,
      },
      session: {
        id: session.id,
        externalRef: session.externalRef,
      },
    }

    if (!window.parent) return

    console.log('[JOURNEY window.parent.postMessage]', payload)
    window.parent.postMessage(JSON.stringify(payload), '*')
  }, [execution, session])

  const value = useMemo(() => {
    if (!pipeline) return
    try {
      if (!pipeline.success?.text) return ''
      return JSON.parse(pipeline?.success.text)
    } catch {
      return pipeline.success.text
    }
  }, [pipeline])

  return (
    <Frame hasLoaded agent={agent} logoUrl={logoUrl} className={s.Success}>
      <Header headline={t('Success!')} className="text-center">
        {pipeline?.success?.title || 'Great! Your identity has been verified!'}
      </Header>

      <Illustration type="success-screen" />

      {typeof value === 'object' ? (
        <SlateHTML legal value={value} className="px-4" />
      ) : (
        <aside>{pipeline?.success?.text}</aside>
      )}

      {isMobile() ? (
        <div className="px-4 mb-4">
          <DataPrivacy />
        </div>
      ) : (
        <footer className={s.DesktopFooter}>
          <a href="https://journeyid.com/" target="_blank" rel="noreferrer">
            <JourneyLogo />
          </a>
        </footer>
      )}
    </Frame>
  )
}

export default Success
