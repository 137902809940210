import qs from 'qs'
import { useCallback, useState } from 'react'

import { isAPIErrorResponse } from 'sdk/common/errors'
import { SecureLinkRequest } from 'sdk/entries/secure_link'
import { unwrapResult } from '@reduxjs/toolkit'

import useAppDispatch from 'hooks/useAppDispatch'
import { entriesSecureLink } from 'thunks/entries/secure_link'

export function useSecureLink() {
  const [error, setError] = useState<string>()

  const dispatch = useAppDispatch()
  const authenticate = useCallback(
    async ({ uniqueId, linkId }: { uniqueId: string; linkId: string }) => {
      try {
        const req: SecureLinkRequest = { uniqueId, linkId }
        return await dispatch(entriesSecureLink(req)).then(unwrapResult)
      } catch (err) {
        if (isAPIErrorResponse(err)) {
          if (err.code === 'UNKNOWN_UNIQUE_ID') {
            setError('That Unique ID is invalid')
          } else {
            setError('An error occurred initiating the OAuth flow')
          }
        }
        throw err
      }
    },
    [setError, dispatch],
  )

  const clearError = useCallback(() => {
    setError(undefined)
  }, [setError])

  return {
    authenticate, error, clearError }
  }
