import { useEffect, useState } from 'react'

import { JourneyClient } from '../../'
import Websocket from '../../websockets'

export function useSessionWebsocket(
  journey: JourneyClient,
  sessionId?: string
): Websocket<'session'> | undefined {
  const [websocket, setWebsocket] = useState<Websocket<'session'>>()

  useEffect(() => {
    if (!sessionId) return

    const id = sessionId
    const ws = journey.sessions.websocket({ id })
    setWebsocket(ws)

    return () => {
      ws.close()
      setWebsocket(undefined)
    }
  }, [sessionId, journey.sessions])

  return websocket
}
