import { StagesProps } from '../Pipeline/Stage'
import * as hooks from './'

const stageHooks = {
  'address-collection': hooks.useAddressCollectionStage,
  blank: hooks.useBlankStage,
  'credit-card-payment': hooks.useCreditCardPaymentStage,
  'device-authentication': hooks.useDeviceAuthenticationStage,
  'document-collection': hooks.useDocumentCollectionStage,
  'document-signature': hooks.useDocumentSignatureStage,
  'drivers-license': hooks.useDriversLicenseStage,
  'face-authentication': hooks.useFaceAuthenticationStage,
  'face-enrollment': hooks.useFaceEnrollmentStage,
  'facial-authentication': hooks.useFacialAuthenticationStage,
  'facial-authentication-3d': hooks.useFacialAuthentication3dStage,
  'facial-enrollment': hooks.useFacialEnrollmentStage,
  form: hooks.useFormStage,
  'government-id': hooks.useGovernmentID,
  'government-id-selfie': hooks.useGovernmentIDSelfie,
  'knowledge-based-authentication': hooks.useKnowledgeBasedAuthenticationStage,
  'national-id-card': hooks.useNationalIDCardStage,
  ocr: hooks.useOCRStage,
  'outbound-scheduling': hooks.useOutboundSchedulingStage,
  passport: hooks.usePassportStage,
  'payment-details': hooks.usePaymentDetailsStage,
  'phone-number-registration': hooks.usePhoneNumberRegistrationStage,
  'phone-number-verification': hooks.usePhoneNumberVerificationStage,
  'random-code': hooks.useRandomCodeStage,
  schedule: hooks.useScheduleStage,
  'social-security-number': hooks.useSocialSecurityNumberStage,
  survey: hooks.useSurveyStage,
  'totp-authentication': hooks.useTOTPAuthenticationStage,
  'totp-registration': hooks.useTOTPRegistrationStage,
  'webauthn-authentication': hooks.useWebAuthnAuthenticationStage,
  'webauthn-registration': hooks.useWebAuthnRegistrationStage,
} as const

export default stageHooks

export type StageTypeWithHook = keyof typeof stageHooks

const stageTypes = Object.keys(stageHooks) as StageTypeWithHook[]

export const isHookStage = stageTypes.reduce((memo, stageType) => {
  type T = typeof stageType
  //@ts-ignore
  memo[stageType] = (stage: StagesProps): stage is StagesProps<T> => {
    return stage.type === stageType
  }
  return memo
}, {} as { [T in StageTypeWithHook]: (stage: StagesProps) => stage is StagesProps<T> })
