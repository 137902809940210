import classNames from 'classnames'
import React, { forwardRef, HTMLProps, ReactElement, ReactNode } from 'react'
import { FieldError } from 'react-hook-form'

import ErrorMessage from 'components/molecules/Form/ErrorMessage'
import Input from 'components/molecules/Form/Input'

import s from './index.module.scss'

export type CommonProps = {
  label: string | ReactNode
  name: string
  hint?: string
  isOptional?: boolean
  overlay?: ReactElement
  error?: FieldError
  aside?: ReactNode
  className?: string
}

type Props = CommonProps & Omit<HTMLProps<HTMLInputElement>, keyof CommonProps>

const Field = forwardRef<HTMLInputElement, Props>(
  ({ label, name, hint, isOptional, overlay, error, aside, className, children, ...rest }, ref) => {
    return (
      <div className={classNames(s.Field, className)}>
        <label htmlFor={name}>
          {label}
          {isOptional && <span className={s.Optional}>optional</span>}
          {!!aside && <aside>{aside}</aside>}
        </label>
        <div className={s.InputWrapper}>
          {children || <Input {...rest} ref={ref} id={name} name={name} />}
          {overlay && <div className={s.Overlay}>{overlay}</div>}
        </div>
        {error && <ErrorMessage error={error} />}
        {hint && <p className={s.Hint}>{hint}</p>}
      </div>
    )
  }
)

export default Field
