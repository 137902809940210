import { useCallback } from 'react'

import { EncryptionFn, useStage } from './useStage'

export default function () {
  const encryptionFn: EncryptionFn<'payment-details'> = useCallback(async (req, encrypt) => {
    if (req.creditCard) {
      req.creditCard.nameOnCard = await encrypt(req.creditCard.nameOnCard)
      req.creditCard.cardNumber = await encrypt(req.creditCard.cardNumber)
      req.creditCard.cvv = await encrypt(req.creditCard.cvv)
      req.creditCard.expirationMonth = await encrypt(req.creditCard.expirationMonth)
      req.creditCard.expirationYear = await encrypt(req.creditCard.expirationYear)
      req.creditCard.postalCode = await encrypt(req.creditCard.postalCode)
    }

    if (req.ach) {
      req.ach.routingNumber = await encrypt(req.ach.routingNumber)
      req.ach.accountNumber = await encrypt(req.ach.accountNumber)
    }

    return req
  }, [])

  return useStage('payment-details', encryptionFn)
}
