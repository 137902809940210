import { useCallback, useMemo } from 'react'
import { useBrandingSelector } from 'store'

import { Branding } from 'sdk/executions/bootstrap'

import useAppDispatch from 'hooks/useAppDispatch'
import { actions } from 'slices/branding'

export default function useBranding() {
  const dispatch = useAppDispatch()
  const { branding } = useBrandingSelector()

  const logoUrl = useMemo(() => {
    return branding?.logo || '/themes/default/header-logo.svg'
  }, [branding])

  const setBranding = useCallback(
    (branding: Branding) => {
      dispatch(actions.setBranding(branding))
    },
    [dispatch]
  )

  return { logoUrl, setBranding }
}
