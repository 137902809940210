import classNames from 'classnames'
import React, { ChangeEvent, forwardRef } from 'react'

import styles from '../Input/index.module.scss'

type Props = {
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
  onChangeText?: (text: string) => void
  options?: any[]
} & Omit<React.HTMLProps<HTMLSelectElement>, 'onChange'>

const Select = forwardRef<HTMLSelectElement, Props>(
  ({ className, children, onChangeText, options, ...rest }, ref) => {
    if (!rest.onChange && onChangeText) {
      rest.onChange = (e: ChangeEvent<HTMLSelectElement>) =>
        onChangeText && onChangeText(e.target.value)
    }

    return (
      <select ref={ref} id={rest.name} {...rest} className={classNames(styles.Input, className)}>
        <option value="" disabled hidden>
          Select one&hellip;
        </option>
        {children}
      </select>
    )
  }
)

export default Select
