import { useCallback } from 'react'

import { EncryptionFn, useStage } from './useStage'

export default function () {
  const encryptionFn: EncryptionFn<'document-collection'> = useCallback(async (req, encrypt) => {
    req.document = await encrypt(req.document)
    return req
  }, [])

  return useStage('document-collection', encryptionFn)
}
