import React, { FC } from 'react'

import shield from 'assets/black-shield.svg'
import useQueryString from 'hooks/useQueryString'

import { QRCodeSVG } from 'qrcode.react'

type RouteParams = {
  url: string
}

const SSOScan: FC = () => {
  const { url } = useQueryString<RouteParams>()

  return (
    <div className="flex justify-center pt-20">
      <div className="max-w-3xl p-8 pb-16 bg-white rounded-lg">
        <h2 className="text-gray-900 std">To begin, please scan the QR code.</h2>
        <h5 className="pt-2 text-gray-600 std">
          No application needs to be downloaded, simply open your phone's camera and point it at the
          QR code below.
        </h5>

        <div className="flex justify-center pt-12">
          <QRCodeSVG
            value={url}
            size={256}
            level="M"
            imageSettings={{
              width: 60,
              height: 60,
              excavate: true,
              src: shield,
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default SSOScan
