import { RootState } from 'store'

import { SubmitResponse } from 'sdk/executions/submit'
import { journey } from 'sdk/react/hooks/useJourneyContext'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { PipelineState } from 'slices/pipeline'

type Response = SubmitResponse & {
  pipeline: NonNullable<PipelineState['pipeline']>
}

export const executionsSubmit = createAsyncThunk<Response, void>(
  'executions/submit',
  async (_, api) => {
    const {
      pipeline: { pipeline },
    } = api.getState() as RootState
    if (!pipeline) {
      throw new Error('No pipeline')
    }

    const resp = await journey.executions.submit()

    return Object.assign(resp, { pipeline })
  }
)
