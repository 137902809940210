import { useCallback } from 'react'
import { EncryptionFn, useStage } from './useStage'

export default function() {
  const encryptionFn: EncryptionFn<'credit-card-payment'> = useCallback(
    async (req, encrypt) => {
      // req.nonce = req.nonce ? await encrypt(req.nonce) : req.nonce
      // req.token = req.token ? await encrypt(req.token) : req.token
      if (req.creditCard && req.creditCard.card_number) {
        req.creditCard.name_on_card = await encrypt(req.creditCard.name_on_card)
        req.creditCard.card_number = await encrypt(req.creditCard.card_number)
        req.creditCard.cvv = await encrypt(req.creditCard.cvv)
        req.creditCard.expiration_month = await encrypt(
          req.creditCard.expiration_month
        )
        req.creditCard.expiration_year = await encrypt(
          req.creditCard.expiration_year
        )
        req.creditCard.postal_code = await encrypt(req.creditCard.postal_code)
      }
      return req
    },
    []
  )

  return useStage('credit-card-payment', encryptionFn)
}
