import classNames from 'classnames'
import React, { FC } from 'react'

import { Agent } from 'sdk/types'

import AgentHeader from '~pc/molecules/AgentHeader'
import LogoHeader from '~pc/molecules/LogoHeader'

import styles from '../../pages/Execution.module.scss'
import s from './Frame.module.scss'

type Props = {
  agent?: Agent
  logoUrl?: string
  hasLoaded?: boolean
  className?: string
}

const Frame: FC<Props> = ({ agent, logoUrl, hasLoaded = true, className, children }) => {
  return (
    <div className={classNames(s.Frame, className || styles.Execution)}>
      <div>
        {hasLoaded ? (
          <>
            {agent?.lastInitial ? (
              <AgentHeader agent={agent} />
            ) : (
              <LogoHeader logoUrl={logoUrl || ''} />
            )}
          </>
        ) : (
          <div className={s.LoadingHeader} />
        )}

        {children}
      </div>
    </div>
  )
}

export default Frame
