import { useCallback, useEffect, useState } from 'react'

import { BootstrapReply } from '../../executions/bootstrap'
import { StageFor } from '../../types'
import { StageTypeWithHook } from '../hooks/map'
import { journey } from '../hooks/useJourneyContext'

export default function useBootstrap(
  token: string,
  onError: (error: Error) => void
): {
  stages: StageFor<StageTypeWithHook>[] | undefined
  bootstrap: BootstrapReply | undefined
} {
  const [bootstrap, setBootstrap] = useState<BootstrapReply>()
  const [stages, setStages] = useState<StageFor<StageTypeWithHook>[]>()

  const handleBootstrap = useCallback((reply: BootstrapReply) => {
    const { stages } = reply.pipeline
    setStages(stages as StageFor<StageTypeWithHook>[])
    journey.setDemoMode(reply.demoMode)
    setBootstrap(reply)
  }, [])

  useEffect(() => {
    setStages(undefined)

    journey.setAuthToken(token)
    journey.executions.bootstrap().then(handleBootstrap).catch(onError)
  }, [token])

  return { stages, bootstrap }
}
