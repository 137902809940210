import React, { FC, Suspense, useEffect } from 'react'
import { useExecutionSelector, useSessionSelector } from 'store'

import * as Sentry from '@sentry/browser'

import LoadingDots from 'components/molecules/LoadingDots'
import useExecution from 'hooks/useExecution'
import { isHookStage } from 'sdk/react/hooks/map'
import Pipeline from 'sdk/react/Pipeline'
import { doesStageRequireMobile } from 'sdk/types'
import { isMobile } from 'util/isMobile'

import useBranding from '~p/hooks/useBranding'
import PaymentDetails from '~p/stages/PaymentDetails'
import Frame from '~pc/molecules/Frame'

const CreditCardPayment = React.lazy(() => import('pipeline/stages/CreditCardPayment'))
const AddressCollection = React.lazy(() => import('~p/stages/AddressCollection'))
const NationalIDCard = React.lazy(() => import('~p/stages/NationalIDCard'))

const TransferToMobile = React.lazy(() => import('./TransferToMobile'))

const Blank = React.lazy(() => import('pipeline/stages/Blank'))
const DocumentCollection = React.lazy(() => import('pipeline/stages/DocumentCollection'))
const DocumentSignature = React.lazy(() => import('pipeline/stages/DocumentSignature'))
const DriversLicense = React.lazy(() => import('pipeline/stages/DriversLicense'))
const FaceAuthentication = React.lazy(() => import('pipeline/stages/FaceAuthentication'))
const FaceEnrollment = React.lazy(() => import('pipeline/stages/FaceEnrollment'))
const FacialAuthentication = React.lazy(() => import('pipeline/stages/FacialAuthentication'))
const FacialAuthentication3D = React.lazy(() => import('pipeline/stages/FacialAuthentication3D'))
const FacialEnrollment = React.lazy(() => import('pipeline/stages/FacialEnrollment'))
const Form = React.lazy(() => import('pipeline/stages/Form'))
const GovernmentID = React.lazy(() => import('pipeline/stages/GovernmentID'))
const GovernmentIDSelfie = React.lazy(() => import('pipeline/stages/GovernmentIDSelfie'))
const KnowledgeBasedAuthentication = React.lazy(
  () => import('pipeline/stages/KnowledgeBasedAuthentication')
)
const OCR = React.lazy(() => import('pipeline/stages/OCR'))
const OutboundScheduling = React.lazy(() => import('pipeline/stages/OutboundScheduling'))
const Passport = React.lazy(() => import('pipeline/stages/Passport'))
const PhoneNumberRegistration = React.lazy(() => import('pipeline/stages/PhoneNumberRegistration'))
const PhoneNumberVerification = React.lazy(() => import('pipeline/stages/PhoneNumberVerification'))
const RandomCode = React.lazy(() => import('pipeline/stages/RandomCode'))
const Schedule = React.lazy(() => import('pipeline/stages/Schedule'))
const SocialSecurityNumber = React.lazy(() => import('pipeline/stages/SocialSecurityNumber'))
const Survey = React.lazy(() => import('pipeline/stages/Survey'))
const TOTPAuthentication = React.lazy(() => import('pipeline/stages/TOTPAuthentication'))
const TOTPRegistration = React.lazy(() => import('pipeline/stages/TOTPRegistration'))
const WebAuthnAuthentication = React.lazy(() => import('pipeline/stages/WebAuthnAuthentication'))
const WebAuthnRegistration = React.lazy(() => import('pipeline/stages/WebAuthnRegistration'))

const Execution: FC = () => {
  const { token, onBootstrap, onSuccess, onError } = useExecution()
  const { id, pipelineId, sessionId, agentId } = useExecutionSelector()
  const { agent } = useSessionSelector()
  const { logoUrl } = useBranding()

  useEffect(() => {
    Sentry.setTags({
      executionId: id,
      pipelineId: pipelineId,
      sessionId: sessionId,
      agentId: agentId,
    })
  }, [id, pipelineId, sessionId, agentId])

  if (!token) return null

  return (
    <Frame hasLoaded={!!id} agent={agent} logoUrl={logoUrl}>
      <Suspense fallback={<LoadingDots className="mt-16" />}>
        <Pipeline token={token} onBootstrap={onBootstrap} onSuccess={onSuccess} onError={onError}>
          {stage => {
            /**
             * Transfer to mobile if necessary
             */
            if (
              !isMobile() &&
              doesStageRequireMobile(stage.props.stage) &&
              !localStorage.dontTransfer
            ) {
              return (
                <TransferToMobile stage={stage.props.stage} onSuccess={stage.props.onContinue} />
              )
            }

            if (isHookStage['address-collection'](stage)) {
              return <AddressCollection {...stage.props} />
            }
            if (isHookStage['blank'](stage)) {
              return <Blank {...stage.props} />
            }
            if (isHookStage['credit-card-payment'](stage)) {
              return <CreditCardPayment {...stage.props} />
            }
            if (isHookStage['document-collection'](stage)) {
              return <DocumentCollection {...stage.props} />
            }
            if (isHookStage['document-signature'](stage)) {
              return <DocumentSignature {...stage.props} />
            }
            if (isHookStage['drivers-license'](stage)) {
              return <DriversLicense {...stage.props} />
            }
            if (isHookStage['face-authentication'](stage)) {
              return <FaceAuthentication {...stage.props} />
            }
            if (isHookStage['face-enrollment'](stage)) {
              return <FaceEnrollment {...stage.props} />
            }
            if (isHookStage['facial-authentication'](stage)) {
              return <FacialAuthentication {...stage.props} />
            }
            if (isHookStage['facial-authentication-3d'](stage)) {
              return <FacialAuthentication3D {...stage.props} />
            }
            if (isHookStage['facial-enrollment'](stage)) {
              return <FacialEnrollment {...stage.props} />
            }
            if (isHookStage['form'](stage)) {
              return <Form {...stage.props} />
            }
            if (isHookStage['government-id'](stage)) {
              return <GovernmentID {...stage.props} />
            }
            if (isHookStage['government-id-selfie'](stage)) {
              return <GovernmentIDSelfie {...stage.props} />
            }
            if (isHookStage['knowledge-based-authentication'](stage)) {
              return <KnowledgeBasedAuthentication {...stage.props} />
            }
            if (isHookStage['national-id-card'](stage)) {
              return <NationalIDCard {...stage.props} />
            }
            if (isHookStage['ocr'](stage)) {
              return <OCR {...stage.props} />
            }
            if (isHookStage['outbound-scheduling'](stage)) {
              return <OutboundScheduling {...stage.props} />
            }
            if (isHookStage['passport'](stage)) {
              return <Passport {...stage.props} />
            }
            if (isHookStage['payment-details'](stage)) {
              return <PaymentDetails {...stage.props} />
            }
            if (isHookStage['phone-number-verification'](stage)) {
              return <PhoneNumberVerification {...stage.props} />
            }
            if (isHookStage['phone-number-registration'](stage)) {
              return <PhoneNumberRegistration {...stage.props} />
            }
            if (isHookStage['random-code'](stage)) {
              return <RandomCode {...stage.props} />
            }
            if (isHookStage['schedule'](stage)) {
              return <Schedule {...stage.props} />
            }
            if (isHookStage['social-security-number'](stage)) {
              return <SocialSecurityNumber {...stage.props} />
            }
            if (isHookStage['survey'](stage)) {
              return <Survey {...stage.props} />
            }
            if (isHookStage['totp-authentication'](stage)) {
              return <TOTPAuthentication {...stage.props} />
            }
            if (isHookStage['totp-registration'](stage)) {
              return <TOTPRegistration {...stage.props} />
            }
            if (isHookStage['webauthn-authentication'](stage)) {
              return <WebAuthnAuthentication {...stage.props} />
            }
            if (isHookStage['webauthn-registration'](stage)) {
              return <WebAuthnRegistration {...stage.props} />
            }
            return <>stage error: {stage.type}</>
          }}
        </Pipeline>
      </Suspense>
    </Frame>
  )
}

export default Execution
