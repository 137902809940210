import { createContext } from 'react'

import Journey, { JourneyClient } from '../../'

type Context = {
  journey: JourneyClient
}

export const journey = new Journey()

export const context: Context = { journey }

export const JourneyContext = createContext<Context>(context)

export function useJourneyContext(): Context {
  return context
}
