import { useCallback } from 'react'

import { EncryptionFn, useStage } from './useStage'

export default function () {
  const encryptionFn: EncryptionFn<'social-security-number'> = useCallback(async (req, encrypt) => {
    req.ssn = await encrypt(req.ssn)
    return req
  }, [])

  return useStage('social-security-number', encryptionFn)
}
