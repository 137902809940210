window._env_ = {
  JOURNEY_ENV: import.meta.env.VITE_JOURNEY_ENV,
  JOURNEY_ORIGIN: import.meta.env.VITE_JOURNEY_ORIGIN,
  JOURNEY_CLIENT: import.meta.env.VITE_JOURNEY_CLIENT,

  AGENT_ORIGIN: import.meta.env.VITE_JOURNEY_AGENT,

  MICROBLINK_ID_KEY: import.meta.env.VITE_MICROBLINK_ID_KEY,
  MICROBLINK_CARD_KEY: import.meta.env.VITE_MICROBLINK_CARD_KEY,

  GOOGLE_MAPS_API_KEY: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,

  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
} as typeof window._env_
