import { useCallback } from 'react'

import { FormRequest } from '../../stages/submit_request'
import { EncryptionFn, useStage } from './useStage'

type Response = FormRequest['responses'][0]

export default function () {
  const encryptionFn: EncryptionFn<'form'> = useCallback(async (req, encrypt) => {
    const encryptions = req.responses.map(({ response }) => encrypt(response))
    const encryptedResponses = await Promise.all(encryptions)

    const responses: Response[] = req.responses.map(({ questionId }, idx) => ({
      questionId,
      response: encryptedResponses[idx],
    }))

    return { responses }
  }, [])

  return useStage('form', encryptionFn)
}
