import { JourneyClient } from '../'
import { UnwrapReply, UnwrapRequest } from './unwrap'
import { WrapReply } from './wrap'

class TransferClient {
  private client: JourneyClient

  constructor(client: JourneyClient) {
    this.client = client
  }

  async wrap(): Promise<WrapReply> {
    return await this.client.request('POST', '/api/client/transfers/wrap')
  }

  async unwrap(request: UnwrapRequest): Promise<UnwrapReply> {
    return await this.client.request('POST', '/api/client/transfers/unwrap', request)
  }
}

export default TransferClient
