import { APIError, ErrorResponse } from 'sdk/common/errors'
import { OAuth2Reply, OAuth2Request } from 'sdk/entries/oauth2'
import { journey } from 'sdk/react/hooks/useJourneyContext'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const entriesOAuth = createAsyncThunk<
  OAuth2Reply,
  OAuth2Request,
  { rejectValue: ErrorResponse }
>('entries/oauth', async (req, api) => {
  try {
    return await journey.entries.oauth2(req)
  } catch (err) {
    if (err instanceof APIError) {
      return api.rejectWithValue(err.response)
    } else {
      throw err
    }
  }
})
