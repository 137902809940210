import AuthClient from './auth/client'
import { APIError } from './common/errors'
import DeviceClient from './devices/client'
import EntryClient from './entries/client'
import ExecutionClient from './executions/client'
import SessionClient from './sessions/client'
import ShortcodeClient from './shortcodes/client'
import StageClient from './stages/client'
import TransferClient from './transfers/client'

export type JourneyOptions = {
  origin?: string
}

export class JourneyClient {
  authToken?: string
  origin?: string
  options: Omit<JourneyOptions, 'origin'>
  demoMode?: boolean

  private authClient?: AuthClient
  private deviceClient?: DeviceClient
  private entryClient?: EntryClient
  private executionClient?: ExecutionClient
  private sessionClient?: SessionClient
  private shortcodeClient?: ShortcodeClient
  private stageClient?: StageClient
  private transferClient?: TransferClient

  constructor(options?: JourneyOptions) {
    this.origin = options?.origin
    this.options = options || {}
  }

  setAuthToken(authToken: string): void {
    this.authToken = authToken
  }

  clearAuthToken(): void {
    this.authToken = undefined
  }

  setDemoMode(demoMode: boolean) : void {
    this.demoMode = demoMode
  }

  setOption(key: 'origin', val: JourneyOptions['origin']): void {
    this.origin = val
  }

  async request(
    method: RequestInit['method'],
    uri: string,
    payload?: Record<string, any>
  ): Promise<any> {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    if (this.authToken) {
      headers.append('Authorization', `Bearer ${this.authToken}`)
    }

    let url = this.origin ? `https://${this.origin}${uri}` : uri
    if (method === 'GET' && payload && Object.keys(payload).length > 0) {
      const query = new URLSearchParams(payload).toString()
      if (query !== '') {
        url = `${url}?${query}`
      }
    }

    const opts: RequestInit = {
      headers,
      method,
    }
    if (method !== 'GET') {
      opts.body = JSON.stringify(payload)
    }

    const response = await fetch(url, opts)
    const resp = await response.json()

    if (response.status >= 400) {
      throw new APIError(response.status, resp)
    }

    return resp
  }

  get auth(): AuthClient {
    if (!this.authClient) {
      this.authClient = new AuthClient()
    }
    return this.authClient
  }

  get devices(): DeviceClient {
    if (!this.deviceClient) {
      this.deviceClient = new DeviceClient(this)
    }
    return this.deviceClient
  }

  get entries(): EntryClient {
    if (!this.entryClient) {
      this.entryClient = new EntryClient(this)
    }
    return this.entryClient
  }

  get executions(): ExecutionClient {
    if (!this.executionClient) {
      this.executionClient = new ExecutionClient(this)
    }
    return this.executionClient
  }

  get sessions(): SessionClient {
    if (!this.sessionClient) {
      this.sessionClient = new SessionClient(this)
    }
    return this.sessionClient
  }

  get shortcodes(): ShortcodeClient {
    if (!this.shortcodeClient) {
      this.shortcodeClient = new ShortcodeClient(this)
    }
    return this.shortcodeClient
  }

  get stages(): StageClient {
    if (!this.stageClient) {
      this.stageClient = new StageClient(this)
    }
    return this.stageClient
  }

  get transfers(): TransferClient {
    if (!this.transferClient) {
      this.transferClient = new TransferClient(this)
    }
    return this.transferClient
  }
}
