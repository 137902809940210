import classNames from 'classnames'
import React, { FC, useCallback, useState } from 'react'
import { t } from 'translation'

import Input from 'components/molecules/Form/Input'
import Select from 'components/molecules/Form/Select'
import { ActionTimer } from 'hooks/useActionTimer'
import { ErrorResponse } from 'sdk/common/errors'
import { SubmitFn } from 'sdk/react/hooks/useStage'
import { ACHDetails } from 'sdk/types'
import insertSpaces from 'util/insertSpaces'

import Header from '~p/components/organisms/Header'
import FooterButton from '~pc/atoms/FooterButton'

import s from '../index.module.scss'

type Props = {
  timer: ActionTimer
  error: ErrorResponse | undefined
  onComplete: SubmitFn<'payment-details'>
  onCancel: () => void
}

const ACH: FC<Props> = ({ timer, error, onComplete, onCancel }) => {
  const [ach, setACH] = useState<ACHDetails>({
    name: '',
    routingNumber: '',
    accountNumber: '',
    accountType: 'checking',
  })

  const [errors, setErrors] = useState<Partial<ACHDetails>>({})
  const checkErrors = useCallback((info: ACHDetails) => {
    const errors: Partial<ACHDetails> = {}

    if (!info.name) errors.name = t('An account holder name is required')
    if (!info.routingNumber) errors.routingNumber = t('A routing number is required')
    if (!info.accountNumber) errors.accountNumber = t('An account number is required')

    setErrors(errors)

    return Object.keys(errors).length === 0
  }, [])

  const setValue = useCallback(
    (key: keyof ACHDetails, value: string) => {
      if (Object.keys(errors).length > 0) setErrors({})
      setACH({ ...ach, [key]: value })
    },
    [ach, errors]
  )

  const onSubmit = useCallback(() => {
    if (!checkErrors(ach)) {
      return
    }

    onComplete({ ach: { ...ach } })
  }, [ach, checkErrors, onComplete])

  return (
    <section>
      <Header>
        <div className="flex items-center justify-between -mr-8">
          {t('Bank account details')}
          <span className="text-sm text-gray-400 cursor-pointer" onClick={onCancel}>
            cancel
          </span>
        </div>
      </Header>
      <form>
        <main className="px-4 sm:p-0">
          <div className="mt-4 space-y-4">
            <div>
              <label className={s.Label}>{t('Account Holder Name')}</label>
              <Input
                autoComplete="cc-name"
                autoFocus
                value={ach.name}
                onChange={v => setValue('name', v.target.value)}
              />
              {errors.name && <p className={s.Error}>{errors.name}</p>}
            </div>

            <div>
              <label className={s.Label}>{t('Routing number')}</label>
              <Input
                autoComplete="ach-routing"
                maxLength={11}
                value={insertSpaces(ach.routingNumber, [3, 6])}
                onChange={v => setValue('routingNumber', v.target.value.replaceAll(/[^\d]/g, ''))}
              />
              {errors.routingNumber && <p className={s.Error}>{errors.routingNumber}</p>}
            </div>

            <div>
              <label className={s.Label}>{t('Account number')}</label>
              <Input
                autoComplete="ach-account"
                maxLength={20}
                value={ach.accountNumber}
                onChange={v => setValue('accountNumber', v.target.value.replaceAll(/[^\d]/g, ''))}
              />
              {errors.accountNumber && <p className={s.Error}>{errors.accountNumber}</p>}
            </div>

            <div>
              <label className={s.Label}>{t('Account type')}</label>
              <Select
                value={ach.accountType}
                onChange={v => setValue('accountType', v.target.value)}
              >
                <option value="checking">Checking</option>
                <option value="savings">Savings</option>
              </Select>
            </div>
          </div>
        </main>
        <footer className="px-4 mt-6 sm:px-0">
          {error && <p className={classNames(s.Error, 'mb-4')}>{error.error}</p>}
          <FooterButton timer={timer} disabled={timer.isLoading} onClick={onSubmit}>
            {t('Continue')}
          </FooterButton>
        </footer>
      </form>
    </section>
  )
}

export default ACH
