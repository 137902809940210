import { parseJwt } from '../util/jwt'
import { AuthTokenPayload, jwtClaims } from './types'

class AuthClient {
  decodeToken(authToken: string): AuthTokenPayload {
    const { sub, eat, eid } = parseJwt<jwtClaims>(authToken)
    return {
      subject: sub,
      expiresAt: new Date(eat * 1000),
      executionId: eid,
    }
  }
}

export default AuthClient
