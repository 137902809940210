import classNames from 'classnames'
import React, { FC, MouseEvent, MouseEventHandler } from 'react'
import { useHistory } from 'react-router-dom'

import { ReactComponent as SpinnerIcon } from 'assets/icons/spinner-third.svg'

import ButtonGroup from './_ButtonGroup'
import styles from './index.module.scss'

export type ButtonProps = {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  variant?: 'primary' | 'secondary' | 'tertiary' | 'link' | 'green' | 'red' | 'red-outline'
  type?: 'submit' | 'button'
  inline?: boolean
  block?: boolean
  isLoading?: boolean
  onClick?: MouseEventHandler
  to?: string
  className?: string
} & Omit<React.HTMLProps<HTMLButtonElement>, 'size' | 'onClick'>

const Button: FC<ButtonProps> = ({
  size = 'md',
  variant = 'primary',
  type = 'button',
  inline = true,
  block = false,
  disabled = false,
  isLoading = false,
  onClick,
  to,
  children,
  className,
  ...rest
}) => {
  const colors = {
    [styles.Primary]: variant === 'primary',
    [styles.Secondary]: variant === 'secondary',
    [styles.Default]: variant === 'tertiary',
    [styles.Link]: variant === 'link',
    [styles.Green]: variant === 'green',
    [styles.Red]: variant === 'red',
    [styles.RedOutline]: variant === 'red-outline',
  }

  const sizes = {
    [styles.XSmall]: size === 'xs',
    [styles.Small]: size === 'sm',
    [styles.Medium]: size === 'md',
    [styles.Large]: size === 'lg',
    [styles.XLarge]: size === 'xl',
  }

  const miscStyles = {
    [styles.Disabled]: disabled,
    [styles.Inline]: inline,
    [styles.Block]: block,
  }

  const history = useHistory()
  function handleClick(ev: MouseEvent) {
    if (onClick) {
      ev.preventDefault()
      onClick(ev)
    } else if (to) {
      ev.preventDefault()
      history.push(to)
    }
  }

  return (
    <button
      onClick={handleClick}
      type={type}
      className={classNames(styles.Button, colors, sizes, miscStyles, className)}
      {...rest}
    >
      {isLoading && <SpinnerIcon className={classNames(styles.LoadingIcon, 'animate-spin')} />}
      {children}
    </button>
  )
}

export { ButtonGroup }
export default Button
