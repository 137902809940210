import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { useSelector } from 'react-redux'
import { combineReducers } from 'redux'
import thunk from 'redux-thunk'

import { configureStore, isPlain } from '@reduxjs/toolkit'

import branding from 'slices/branding'
import execution from 'slices/execution'
import jwt from 'slices/jwt'
import pipeline from 'slices/pipeline'
import session from 'slices/session'

export const history = createBrowserHistory()

const rootReducer = combineReducers({
  router: connectRouter(history),
  branding: branding.reducer,
  execution: execution.reducer,
  jwt: jwt.reducer,
  pipeline: pipeline.reducer,
  session: session.reducer,
})

const serializableCheck = {
  isSerializable: (value: any) => isPlain(value) || value instanceof Date,
}

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefault =>
    getDefault({ serializableCheck }).concat(routerMiddleware(history)).concat(thunk),
  devTools: _env_.JOURNEY_ENV !== 'production' ? { name: 'Journey Client' } : false,
})

export default store

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch

/**
 * Store selectors
 */

export function useBrandingSelector() {
  return useSelector((state: RootState) => state.branding)
}

export function useExecutionSelector() {
  return useSelector((state: RootState) => state.execution)
}

export function useJWTSelector() {
  return useSelector((state: RootState) => state.jwt)
}

export function usePipelineSelector() {
  return useSelector((state: RootState) => state.pipeline)
}

export function useSessionSelector() {
  return useSelector((state: RootState) => state.session)
}
