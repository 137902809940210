import QueryString from 'qs'

import { SubmitResponse } from 'sdk/executions/submit'

type Redirect = NonNullable<SubmitResponse['redirect']>

export default function useRedirect() {
  return (redirect: Redirect) => {
    const query = QueryString.parse(redirect.query)

    if (query.continuousAuth === 'true') {
      const url = `https://${_env_.AGENT_ORIGIN}/auth/launch?token=${query.token}`
      window.open(url, '_blank')
    }

    if (redirect.post) {
      redirectPOST(redirect)
    } else {
      redirectGET(redirect)
    }
  }
}

function redirectPOST(redirect: Redirect) {
  const query = QueryString.parse(redirect.query)

  const form = document.createElement('form')
  form.setAttribute('action', redirect.url)
  form.setAttribute('method', 'post')
  form.style.display = 'none'

  for (var key in query) {
    const el = document.createElement('input')
    el.setAttribute('type', 'hidden')
    el.setAttribute('name', key)
    el.setAttribute('value', query[key] as string)
    form.appendChild(el)
  }

  document.body.appendChild(form)
  form.submit()
}

function redirectGET(redirect: Redirect) {
  const url = `${redirect.url}?${redirect.query}`
  window.location.href = url
}
