import React, { FC, ReactNode } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { t } from 'translation'

import { ReactComponent as Illustration } from '~p/assets/illustrations/error.svg'
import Header from '~p/components/organisms/Header'
import Frame from '~pc/molecules/Frame'

export type PipelineError =
  | 'no-bootstrap-token'
  | 'bootstrap-failed'
  | 'expected-redirect'
  | 'no-pipeline'
  | 'invalid-stage'
  | 'no-transfer-token'
  | 'general-error'

type RouteParams = {
  type: PipelineError | string
}

const Error: FC = () => {
  const { params } = useRouteMatch<RouteParams>()

  return (
    <Frame logoUrl="/themes/default/header-logo.svg">
      <header className="flex flex-col items-center">
        <Illustration className="h-40 mt-8 mb-4 text-gray-700 fill-current" />
        <Header>There was a small hiccup, but don't worry!</Header>
      </header>
      <main className="px-4 mt-8 space-y-4 sm:px-0">
        {((): ReactNode => {
          switch (params.type) {
            case 'no-pipeline':
              return (
                <>
                  <p className="font-normal">
                    When you start the process, you must finish it in the same browser tab. This
                    error will happen if you refresh the page.
                  </p>
                  <p>Please click on the email, SMS, or URL again to restart the process.</p>
                </>
              )
            case 'no-bootstrap-token':
              return (
                <div>
                  {`This likely occurred because the page was refreshed. You must complete the pipeline once you've started it.`}
                </div>
              )
            case 'bootstrap-failed':
              return (
                <div>
                  {t(
                    `This issue occurred because we were unable to initialize the pipeline. This is likely due to a misconfiguration or service interruption.`
                  )}
                </div>
              )
            case 'expected-redirect':
              return (
                <div>
                  {t(
                    `This issue occurred because we expected to redirect you to another service, but didn't have information on where to send you.`
                  )}
                </div>
              )
            case 'invalid-stage':
              return (
                <div>
                  {t(
                    `This issue occurred because you landed on an invalid pipeline stage. You should begin the pipeline again via the original link`
                  )}
                </div>
              )
            case 'no-transfer-token':
              return (
                <div>
                  {t(
                    `The isssue occurred because the transfer between your computer and phone failed. Please re-scan the QR code.`
                  )}
                </div>
              )
            case 'ALREADY_COMPLETED':
              return (
                <div>
                  {t(
                    `This operation has already been completed! You'll need another request if you need to perform the task again.`
                  )}
                </div>
              )
            case 'EXECUTION_ARCHIVED':
              return <div>{t(`The request to complete this task has been cancelled.`)}</div>
            default:
              return (
                <div>
                  {t(
                    'Unfortunately there was an unexpected error. Contact support@journeyid.com if you need assistance.'
                  )}
                </div>
              )
          }
        })()}
      </main>
      <footer className="mt-32 text-[10px] text-gray-600 px-4 sm:px-0">
        error reference: {params.type}
      </footer>
    </Frame>
  )
}

export default Error
