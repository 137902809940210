import React, { FC, useEffect, useState } from 'react'

import { StageProps } from 'sdk/react/Pipeline/Stage'
import { ACHDetails, CreditCardDetails } from 'sdk/types'

import ACH from '~p/stages/PaymentDetails/pages/ACH'
import CreditCard from '~p/stages/PaymentDetails/pages/CreditCard'
import MethodSelection from '~p/stages/PaymentDetails/pages/MethodSelection'

type Props = StageProps<'payment-details'>

type Step = 'select-method' | 'credit-card' | 'ach' | 'confirm'

type State = {
  step: Step
  creditCard?: CreditCardDetails
  ach?: ACHDetails
}

const PaymentDetails: FC<Props> = ({ stage, timer, error, onInitiate, onSubmit }) => {
  const [state, setState] = useState<State>({
    step: stage.configuration?.paymentDetailsType || 'select-method',
  })

  useEffect(() => {
    onInitiate({})
  }, [onInitiate])

  switch (state.step) {
    case 'select-method':
      return <MethodSelection onSelect={method => setState({ step: method })} />
    case 'credit-card':
      return (
        <CreditCard
          timer={timer}
          error={error}
          onComplete={onSubmit}
          onCancel={() => setState({ step: 'select-method' })}
        />
      )
    case 'ach':
      return (
        <ACH
          timer={timer}
          error={error}
          onComplete={onSubmit}
          onCancel={() => setState({ step: 'select-method' })}
        />
      )
    default:
      return null
  }
}

export default PaymentDetails
