type Font = {
  name: string
  regular: string
  bold: string
}

const googleFonts: Font[] = [
  {
    name: 'Abhaya Libre',
    regular: 'https://fonts.gstatic.com/s/abhayalibre/v6/e3tmeuGtX-Co5MNzeAOqinEQfEnX.woff2',
    bold: 'https://fonts.gstatic.com/s/abhayalibre/v6/e3t5euGtX-Co5MNzeAOqinEYo23CrdZJ.woff2',
  },
  {
    name: 'Advent Pro',
    regular: 'https://fonts.gstatic.com/s/adventpro/v11/V8mAoQfxVT4Dvddr_yOwhTqtKA.woff2',
    bold: 'https://fonts.gstatic.com/s/adventpro/v11/V8mDoQfxVT4Dvddr_yOwjeWJPbF4Cw.woff2',
  },
  {
    name: 'Alef',
    regular: 'https://fonts.gstatic.com/s/alef/v12/FeVfS0NQpLYgnjVRCg.woff2',
    bold: 'https://fonts.gstatic.com/s/alef/v12/FeVQS0NQpLYglo50H55Q2A.woff2',
  },
  {
    name: 'Alegreya',
    regular:
      'https://fonts.gstatic.com/s/alegreya/v19/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNG9hU4-6qj.woff2',
    bold: 'https://fonts.gstatic.com/s/alegreya/v19/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNGKBI4-6qj.woff2',
  },
  {
    name: 'Alegreya SC',
    regular: 'https://fonts.gstatic.com/s/alegreyasc/v15/taiOGmRtCJ62-O0HhNEa-Z6v2ZA.woff2',
    bold: 'https://fonts.gstatic.com/s/alegreyasc/v15/taiTGmRtCJ62-O0HhNEa-ZYU_IU2SKo.woff2',
  },
  {
    name: 'Alegreya Sans',
    regular: 'https://fonts.gstatic.com/s/alegreyasans/v14/5aUz9_-1phKLFgshYDvh6Vwt7VptvQ.woff2',
    bold: 'https://fonts.gstatic.com/s/alegreyasans/v14/5aUu9_-1phKLFgshYDvh6Vwt5eFIqEp2iw.woff2',
  },
  {
    name: 'Alegreya Sans SC',
    regular:
      'https://fonts.gstatic.com/s/alegreyasanssc/v13/mtGh4-RGJqfMvt7P8FUr0Q1j-Hf1Bkll9w.woff2',
    bold: 'https://fonts.gstatic.com/s/alegreyasanssc/v13/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DvJA4iNhMA.woff2',
  },
  {
    name: 'Aleo',
    regular: 'https://fonts.gstatic.com/s/aleo/v4/c4mv1nF8G8_swA3J0Q.woff2',
    bold: 'https://fonts.gstatic.com/s/aleo/v4/c4mg1nF8G8_syLbsxDJJnw.woff2',
  },
  {
    name: 'Allan',
    regular: 'https://fonts.gstatic.com/s/allan/v13/ea8XadU7WuTxEubxNdU.woff2',
    bold: 'https://fonts.gstatic.com/s/allan/v13/ea8aadU7WuTxEu5KEMCK02s.woff2',
  },
  {
    name: 'Almarai',
    regular: 'https://fonts.gstatic.com/s/almarai/v5/tsstApxBaigK_hnnQ1iFow.woff2',
    bold: 'https://fonts.gstatic.com/s/almarai/v5/tssoApxBaigK_hnnS-agtnqWow.woff2',
  },
  {
    name: 'Almendra',
    regular: 'https://fonts.gstatic.com/s/almendra/v15/H4ckBXKAlMnTn0CskxY9yL4.woff2',
    bold: 'https://fonts.gstatic.com/s/almendra/v15/H4cjBXKAlMnTn0Cskx6G7au_oqM.woff2',
  },
  {
    name: 'Alumni Sans',
    regular:
      'https://fonts.gstatic.com/s/alumnisans/v1/nwpHtKqkOwdO2aOIwhWudEWpx_zq_Xna-Xd9OO9gr1EI.woff2',
    bold: 'https://fonts.gstatic.com/s/alumnisans/v1/nwpHtKqkOwdO2aOIwhWudEWpx_zq_Xna-Xd95uhgr1EI.woff2',
  },
  {
    name: 'Amaranth',
    regular: 'https://fonts.gstatic.com/s/amaranth/v11/KtkuALODe433f0j1zMnFHdA.woff2',
    bold: 'https://fonts.gstatic.com/s/amaranth/v11/KtkpALODe433f0j1zMF-OMWl42E.woff2',
  },
  {
    name: 'Amatic SC',
    regular: 'https://fonts.gstatic.com/s/amaticsc/v16/TUZyzwprpvBS1izr_vOECuSf.woff2',
    bold: 'https://fonts.gstatic.com/s/amaticsc/v16/TUZ3zwprpvBS1izr_vOMscGKfrUC.woff2',
  },
  {
    name: 'Amiko',
    regular: 'https://fonts.gstatic.com/s/amiko/v5/WwkQxPq1DFK04uqieV8.woff2',
    bold: 'https://fonts.gstatic.com/s/amiko/v5/WwkdxPq1DFK04uJ9XUrDEIU.woff2',
  },
  {
    name: 'Amiri',
    regular: 'https://fonts.gstatic.com/s/amiri/v17/J7aRnpd8CGxBHpUutLM.woff2',
    bold: 'https://fonts.gstatic.com/s/amiri/v17/J7acnpd8CGxBHp2VkaY_zp4.woff2',
  },
  {
    name: 'Amita',
    regular: 'https://fonts.gstatic.com/s/amita/v9/HhyaU5si9Om7PTloC_U.woff2',
    bold: 'https://fonts.gstatic.com/s/amita/v9/HhyXU5si9Om7PTHTLuCFMIs.woff2',
  },
  {
    name: 'Andada Pro',
    regular:
      'https://fonts.gstatic.com/s/andadapro/v2/HhyEU5Qi9-SuOEhPe4LtKoVCuWGURPcg3DPJBb8bHr0.woff2',
    bold: 'https://fonts.gstatic.com/s/andadapro/v2/HhyEU5Qi9-SuOEhPe4LtKoVCuWGURPcg3DMXAr8bHr0.woff2',
  },
  {
    name: 'Andika New Basic',
    regular:
      'https://fonts.gstatic.com/s/andikanewbasic/v5/taiRGn9tCp-44eleq5Q-mszJivxSeKieEQ.woff2',
    bold: 'https://fonts.gstatic.com/s/andikanewbasic/v5/taiWGn9tCp-44eleq5Q-mszJivxScBO7BG2iGg.woff2',
  },
  {
    name: 'Anonymous Pro',
    regular: 'https://fonts.gstatic.com/s/anonymouspro/v14/rP2Bp2a15UIB7Un-bOeISG3pHls29Q.woff2',
    bold: 'https://fonts.gstatic.com/s/anonymouspro/v14/rP2cp2a15UIB7Un-bOeISG3pFuAT4C7c7Q.woff2',
  },
  {
    name: 'Antonio',
    regular: 'https://fonts.gstatic.com/s/antonio/v1/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVxx8StPaWE.woff2',
    bold: 'https://fonts.gstatic.com/s/antonio/v1/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVyv9itPaWE.woff2',
  },
  {
    name: 'Archivo',
    regular:
      'https://fonts.gstatic.com/s/archivo/v9/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTNDNZ9xdp.woff2',
    bold: 'https://fonts.gstatic.com/s/archivo/v9/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTT6jRZ9xdp.woff2',
  },
  {
    name: 'Archivo Narrow',
    regular: 'https://fonts.gstatic.com/s/archivonarrow/v12/tss0ApVBdCYD5Q7hcxTE1ArZ0bbwiXw.woff2',
    bold: 'https://fonts.gstatic.com/s/archivonarrow/v12/tss3ApVBdCYD5Q7hcxTE1ArZ0b4vrWld-9I.woff2',
  },
  {
    name: 'Aref Ruqaa',
    regular: 'https://fonts.gstatic.com/s/arefruqaa/v16/WwkbxPW1E165rajQKDulIIcoVQ.woff2',
    bold: 'https://fonts.gstatic.com/s/arefruqaa/v16/WwkYxPW1E165rajQKDulKDwNQNUY2Q.woff2',
  },
  {
    name: 'Arima Madurai',
    regular: 'https://fonts.gstatic.com/s/arimamadurai/v7/t5tmIRoeKYORG0WNMgnC3seB3T7Prw.woff2',
    bold: 'https://fonts.gstatic.com/s/arimamadurai/v7/t5t7IRoeKYORG0WNMgnC3seB1YXqupymfw.woff2',
  },
  {
    name: 'Arimo',
    regular: 'https://fonts.gstatic.com/s/arimo/v17/P5sfzZCDf9_T_3cV7NCUECyoxNk37cxcABrB.woff2',
    bold: 'https://fonts.gstatic.com/s/arimo/v17/P5sfzZCDf9_T_3cV7NCUECyoxNk3M8tcABrB.woff2',
  },
  {
    name: 'Arsenal',
    regular: 'https://fonts.gstatic.com/s/arsenal/v5/wXKrE3kQtZQ4pF3D51jcAA.woff2',
    bold: 'https://fonts.gstatic.com/s/arsenal/v5/wXKuE3kQtZQ4pF3D7-P5FeMKmA.woff2',
  },
  {
    name: 'Arvo',
    regular: 'https://fonts.gstatic.com/s/arvo/v14/tDbD2oWUg0MKqScQ7Q.woff2',
    bold: 'https://fonts.gstatic.com/s/arvo/v14/tDbM2oWUg0MKoZw1-LPK8w.woff2',
  },
  {
    name: 'Arya',
    regular: 'https://fonts.gstatic.com/s/arya/v9/ga6CawNG-HJdxUH_-A.woff2',
    bold: 'https://fonts.gstatic.com/s/arya/v9/ga6NawNG-HJdzfra7biLaQ.woff2',
  },
  {
    name: 'Asap',
    regular: 'https://fonts.gstatic.com/s/asap/v15/KFO9CniXp96a4Tc2EZzSuDAoKsE61phJW34.woff2',
    bold: 'https://fonts.gstatic.com/s/asap/v15/KFO9CniXp96a4Tc2EZzSuDAoKsHk0ZhJW34.woff2',
  },
  {
    name: 'Asap Condensed',
    regular: 'https://fonts.gstatic.com/s/asapcondensed/v8/pxidypY1o9NHyXh3WvSbGSggdOeMaEw.woff2',
    bold: 'https://fonts.gstatic.com/s/asapcondensed/v8/pxieypY1o9NHyXh3WvSbGSggdO9TTFlDims.woff2',
  },
  {
    name: 'Assistant',
    regular:
      'https://fonts.gstatic.com/s/assistant/v8/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtuZnIGaV3w.woff2',
    bold: 'https://fonts.gstatic.com/s/assistant/v8/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtjhgIGaV3w.woff2',
  },
  {
    name: 'Astloch',
    regular: 'https://fonts.gstatic.com/s/astloch/v14/TuGRUVJ8QI5GSeUjm9sbzQ.woff2',
    bold: 'https://fonts.gstatic.com/s/astloch/v14/TuGUUVJ8QI5GSeUjk2A-2MRFAg.woff2',
  },
  {
    name: 'Asul',
    regular: 'https://fonts.gstatic.com/s/asul/v12/VuJ-dNjKxYr42fQPXQ.woff2',
    bold: 'https://fonts.gstatic.com/s/asul/v12/VuJxdNjKxYr40U8qSKHdOQ.woff2',
  },
  {
    name: 'Athiti',
    regular: 'https://fonts.gstatic.com/s/athiti/v5/pe0vMISdLIZIv1wICxJX.woff2',
    bold: 'https://fonts.gstatic.com/s/athiti/v5/pe0sMISdLIZIv1wA1DZCBfe_.woff2',
  },
  {
    name: 'Atkinson Hyperlegible',
    regular:
      'https://fonts.gstatic.com/s/atkinsonhyperlegible/v1/9Bt23C1KxNDXMspQ1lPyU89-1h6ONRlW45G04pIo.woff2',
    bold: 'https://fonts.gstatic.com/s/atkinsonhyperlegible/v1/9Bt73C1KxNDXMspQ1lPyU89-1h6ONRlW45G8Wbc9dCWP.woff2',
  },
  {
    name: 'Atma',
    regular: 'https://fonts.gstatic.com/s/atma/v8/uK_84rqWc-Eoq2lRDw.woff2',
    bold: 'https://fonts.gstatic.com/s/atma/v8/uK_z4rqWc-Eoo7Z1GjA3Pw.woff2',
  },
  {
    name: 'Averia Libre',
    regular: 'https://fonts.gstatic.com/s/averialibre/v9/2V0aKIcMGZEnV6xygz7eNjESBanI.woff2',
    bold: 'https://fonts.gstatic.com/s/averialibre/v9/2V0FKIcMGZEnV6xygz7eNjEavozdaETs.woff2',
  },
  {
    name: 'Averia Sans Libre',
    regular:
      'https://fonts.gstatic.com/s/averiasanslibre/v11/ga6XaxZG_G5OvCf_rt7FH3B6BHLMEdVOEoI.woff2',
    bold: 'https://fonts.gstatic.com/s/averiasanslibre/v11/ga6SaxZG_G5OvCf_rt7FH3B6BHLMEd31N5cXL5I.woff2',
  },
  {
    name: 'Averia Serif Libre',
    regular:
      'https://fonts.gstatic.com/s/averiaseriflibre/v10/neIWzD2ms4wxr6GvjeD0X88SHPyX2xYOoguP.woff2',
    bold: 'https://fonts.gstatic.com/s/averiaseriflibre/v10/neIVzD2ms4wxr6GvjeD0X88SHPyX2xYGGS6axq0r.woff2',
  },
  {
    name: 'Azeret Mono',
    regular:
      'https://fonts.gstatic.com/s/azeretmono/v1/3XF5ErsiyJsY9O_Gepph-FvtTQgMQUdNekSfnPVR1byb.woff2',
    bold: 'https://fonts.gstatic.com/s/azeretmono/v1/3XF5ErsiyJsY9O_Gepph-FvtTQgMQUdNekSfQvJR1byb.woff2',
  },
  {
    name: 'B612',
    regular: 'https://fonts.gstatic.com/s/b612/v5/3JnySDDxiSz36j6yGQ.woff2',
    bold: 'https://fonts.gstatic.com/s/b612/v5/3Jn9SDDxiSz34oWXDDeBTA.woff2',
  },
  {
    name: 'B612 Mono',
    regular: 'https://fonts.gstatic.com/s/b612mono/v5/kmK_Zq85QVWbN1eW6lJV0A7d.woff2',
    bold: 'https://fonts.gstatic.com/s/b612mono/v5/kmK6Zq85QVWbN1eW6lJdayvIpcVO.woff2',
  },
  {
    name: 'Bai Jamjuree',
    regular: 'https://fonts.gstatic.com/s/baijamjuree/v4/LDI1apSCOBt_aeQQ7ftydoa8XsLL.woff2',
    bold: 'https://fonts.gstatic.com/s/baijamjuree/v4/LDIqapSCOBt_aeQQ7ftydoa0gebelJo0.woff2',
  },
  {
    name: 'Baloo 2',
    regular: 'https://fonts.gstatic.com/s/baloo2/v2/wXKrE3kTposypRyd51jcAA.woff2',
    bold: 'https://fonts.gstatic.com/s/baloo2/v2/wXKuE3kTposypRyd74f4FeMKmA.woff2',
  },
  {
    name: 'Baloo Bhai 2',
    regular: 'https://fonts.gstatic.com/s/baloobhai2/v4/sZlDdRSL-z1VEWZ4YNA7Y5IHdt7n.woff2',
    bold: 'https://fonts.gstatic.com/s/baloobhai2/v4/sZlcdRSL-z1VEWZ4YNA7Y5IPqfryh1x9.woff2',
  },
  {
    name: 'Baloo Bhaina 2',
    regular: 'https://fonts.gstatic.com/s/baloobhaina2/v4/qWczB6yyq4P9Adr3RtoX1q6ySiz8kjQ.woff2',
    bold: 'https://fonts.gstatic.com/s/baloobhaina2/v4/qWcwB6yyq4P9Adr3RtoX1q6ySiQjtiEnm4c.woff2',
  },
  {
    name: 'Baloo Chettan 2',
    regular: 'https://fonts.gstatic.com/s/baloochettan2/v2/vm8udRbmXEva26PK-NtuX4ynWEzv5_d6.woff2',
    bold: 'https://fonts.gstatic.com/s/baloochettan2/v2/vm8rdRbmXEva26PK-NtuX4ynWEznONNvNbce.woff2',
  },
  {
    name: 'Baloo Da 2',
    regular: 'https://fonts.gstatic.com/s/balooda2/v2/2-ci9J9j0IaUMQZwAJyJQundoA.woff2',
    bold: 'https://fonts.gstatic.com/s/balooda2/v2/2-ch9J9j0IaUMQZwAJyJSjb5tZ5iYg.woff2',
  },
  {
    name: 'Baloo Paaji 2',
    regular: 'https://fonts.gstatic.com/s/baloopaaji2/v4/i7dMIFFzbz-QHZUdV9_UGWZuUFWaHg.woff2',
    bold: 'https://fonts.gstatic.com/s/baloopaaji2/v4/i7dRIFFzbz-QHZUdV9_UGWZuWIq-CwO0ag.woff2',
  },
  {
    name: 'Baloo Tamma 2',
    regular: 'https://fonts.gstatic.com/s/balootamma2/v2/vEFX2_hCAgcR46PaajtrYlBbf0881w.woff2',
    bold: 'https://fonts.gstatic.com/s/balootamma2/v2/vEFK2_hCAgcR46PaajtrYlBbd5AYwtW_Wg.woff2',
  },
  {
    name: 'Baloo Tammudu 2',
    regular: 'https://fonts.gstatic.com/s/balootammudu2/v4/1Pt2g8TIS_SAmkLguUdFP8UaJcKOwnsX.woff2',
    bold: 'https://fonts.gstatic.com/s/balootammudu2/v4/1Ptzg8TIS_SAmkLguUdFP8UaJcKGHV8C5oCK.woff2',
  },
  {
    name: 'Baloo Thambi 2',
    regular: 'https://fonts.gstatic.com/s/baloothambi2/v2/cY9cfjeOW0NHpmOQXranrbDyu7hADpI.woff2',
    bold: 'https://fonts.gstatic.com/s/baloothambi2/v2/cY9ffjeOW0NHpmOQXranrbDyu7CfKoe-RB4.woff2',
  },
  {
    name: 'Balsamiq Sans',
    regular: 'https://fonts.gstatic.com/s/balsamiqsans/v3/P5sEzZiAbNrN8SB3lQQX7Pncwd4XIA.woff2',
    bold: 'https://fonts.gstatic.com/s/balsamiqsans/v3/P5sZzZiAbNrN8SB3lQQX7PncyWUyNYhsAg.woff2',
  },
  {
    name: 'Barlow',
    regular: 'https://fonts.gstatic.com/s/barlow/v5/7cHpv4kjgoGqM7E_DMs5.woff2',
    bold: 'https://fonts.gstatic.com/s/barlow/v5/7cHqv4kjgoGqM7E30-8s51os.woff2',
  },
  {
    name: 'Barlow Condensed',
    regular:
      'https://fonts.gstatic.com/s/barlowcondensed/v5/HTx3L3I-JCGChYJ8VI-L6OO_au7B6xHT2g.woff2',
    bold: 'https://fonts.gstatic.com/s/barlowcondensed/v5/HTxwL3I-JCGChYJ8VI-L6OO_au7B4873z3bWuQ.woff2',
  },
  {
    name: 'Barlow Semi Condensed',
    regular:
      'https://fonts.gstatic.com/s/barlowsemicondensed/v7/wlpvgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXeIqq.woff2',
    bold: 'https://fonts.gstatic.com/s/barlowsemicondensed/v7/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfp66_B2sl.woff2',
  },
  {
    name: 'Battambang',
    regular: 'https://fonts.gstatic.com/s/battambang/v15/uk-mEGe7raEw-HjkzZabPnKp4g.woff2',
    bold: 'https://fonts.gstatic.com/s/battambang/v15/uk-lEGe7raEw-HjkzZabNsmM9yNQ8w.woff2',
  },
  {
    name: 'Be Vietnam',
    regular: 'https://fonts.gstatic.com/s/bevietnam/v2/FBVzdDflz-iPfoPuIC2iIqYn6A.woff2',
    bold: 'https://fonts.gstatic.com/s/bevietnam/v2/FBVwdDflz-iPfoPuIC2iKnkD_UBFJg.woff2',
  },
  {
    name: 'Be Vietnam Pro',
    regular: 'https://fonts.gstatic.com/s/bevietnampro/v2/QdVPSTAyLFyeg_IDWvOJmVES_Hw3BXo.woff2',
    bold: 'https://fonts.gstatic.com/s/bevietnampro/v2/QdVMSTAyLFyeg_IDWvOJmVES_HToIW81Rb0.woff2',
  },
  {
    name: 'Bellota',
    regular: 'https://fonts.gstatic.com/s/bellota/v4/MwQ2bhXl3_qEpiwAKJVbtQ.woff2',
    bold: 'https://fonts.gstatic.com/s/bellota/v4/MwQzbhXl3_qEpiwAIC5-oGQfiA.woff2',
  },
  {
    name: 'Bellota Text',
    regular: 'https://fonts.gstatic.com/s/bellotatext/v4/0FlTVP2VnlWS4f3-UE9hHXMx_tkP.woff2',
    bold: 'https://fonts.gstatic.com/s/bellotatext/v4/0FlMVP2VnlWS4f3-UE9hHXM5Rfwaeg_x.woff2',
  },
  {
    name: 'BenchNine',
    regular: 'https://fonts.gstatic.com/s/benchnine/v9/ahcbv8612zF4jxrwMosbUMl0.woff2',
    bold: 'https://fonts.gstatic.com/s/benchnine/v9/ahcev8612zF4jxrwMosT6-xhgmy9.woff2',
  },
  {
    name: 'Besley',
    regular: 'https://fonts.gstatic.com/s/besley/v1/PlIhFlO1MaNwaNGWUC92IOH_mtG4fbbBedNiFQ.woff2',
    bold: 'https://fonts.gstatic.com/s/besley/v1/PlIhFlO1MaNwaNGWUC92IOH_mtG4fWjGedNiFQ.woff2',
  },
  {
    name: 'Big Shoulders Display',
    regular:
      'https://fonts.gstatic.com/s/bigshouldersdisplay/v6/fC1MPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx12wPgf9g-_3F0YdY87FFkwSA.woff2',
    bold: 'https://fonts.gstatic.com/s/bigshouldersdisplay/v6/fC1MPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx12wPgf9g-_3F0YdVE8FFkwSA.woff2',
  },
  {
    name: 'Big Shoulders Inline Display',
    regular:
      'https://fonts.gstatic.com/s/bigshouldersinlinedisplay/v10/_LOumyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CGNOFeNLxoYMPJ0nBE0R-wvY.woff2',
    bold: 'https://fonts.gstatic.com/s/bigshouldersinlinedisplay/v10/_LOumyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CGNOFeNLxoYMPJ0kfFER-wvY.woff2',
  },
  {
    name: 'Big Shoulders Inline Text',
    regular:
      'https://fonts.gstatic.com/s/bigshouldersinlinetext/v10/vm8XdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NMyHY74qpoNNcwga0y5GK548.woff2',
    bold: 'https://fonts.gstatic.com/s/bigshouldersinlinetext/v10/vm8XdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NMyHY74qpoNNcwgZqzJGK548.woff2',
  },
  {
    name: 'Big Shoulders Stencil Display',
    regular:
      'https://fonts.gstatic.com/s/bigshouldersstencildisplay/v10/6aeZ4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPytKb-oPRU-vkuLm_O0j_L3bm.woff2',
    bold: 'https://fonts.gstatic.com/s/bigshouldersstencildisplay/v10/6aeZ4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPytKb-oPRU-vkuLm_5U__L3bm.woff2',
  },
  {
    name: 'Big Shoulders Stencil Text',
    regular:
      'https://fonts.gstatic.com/s/bigshouldersstenciltext/v10/5aUV9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcab2SJYLLUtk1OGR04T4HiXO.woff2',
    bold: 'https://fonts.gstatic.com/s/bigshouldersstenciltext/v10/5aUV9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcab2SJYLLUtk1OGRDYP4HiXO.woff2',
  },
  {
    name: 'Big Shoulders Text',
    regular:
      'https://fonts.gstatic.com/s/bigshoulderstext/v8/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3Y-q7TUFNw.woff2',
    bold: 'https://fonts.gstatic.com/s/bigshoulderstext/v8/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3VGt7TUFNw.woff2',
  },
  {
    name: 'BioRhyme',
    regular: 'https://fonts.gstatic.com/s/biorhyme/v5/1cXwaULHBpDMsHYW_ExBr1Q.woff2',
    bold: 'https://fonts.gstatic.com/s/biorhyme/v5/1cX3aULHBpDMsHYW_ET6ikGHocU.woff2',
  },
  {
    name: 'BioRhyme Expanded',
    regular:
      'https://fonts.gstatic.com/s/biorhymeexpanded/v8/i7dQIE1zZzytGswgU577CDY9LjbffxSTT3E.woff2',
    bold: 'https://fonts.gstatic.com/s/biorhymeexpanded/v8/i7dVIE1zZzytGswgU577CDY9LjbffxwoamSAQ9U.woff2',
  },
  {
    name: 'Biryani',
    regular: 'https://fonts.gstatic.com/s/biryani/v6/hv-WlzNxIFoO84YdfUsTPA.woff2',
    bold: 'https://fonts.gstatic.com/s/biryani/v6/hv-TlzNxIFoO84YddZQ3KTdYUg.woff2',
  },
  {
    name: 'Bitter',
    regular: 'https://fonts.gstatic.com/s/bitter/v19/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8fbfOLjOXQ.woff2',
    bold: 'https://fonts.gstatic.com/s/bitter/v19/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8SjYOLjOXQ.woff2',
  },
  {
    name: 'Blinker',
    regular: 'https://fonts.gstatic.com/s/blinker/v4/cIf9MaFatEE-VTaP9C6hYQ.woff2',
    bold: 'https://fonts.gstatic.com/s/blinker/v4/cIf4MaFatEE-VTaP_PGFdGYmnQ.woff2',
  },
  {
    name: 'Bodoni Moda',
    regular:
      'https://fonts.gstatic.com/s/bodonimoda/v7/aFT67PxzY382XsXX63LUYL6GYFcan6NJrKp-VPjfJMShrpsGFUt8oU7a8Id4tA.woff2',
    bold: 'https://fonts.gstatic.com/s/bodonimoda/v7/aFT67PxzY382XsXX63LUYL6GYFcan6NJrKp-VPjfJMShrpsGFUt8oZDd8Id4tA.woff2',
  },
  {
    name: 'Bona Nova',
    regular: 'https://fonts.gstatic.com/s/bonanova/v1/B50NF7ZCpX7fcHfvIUB5jKJr.woff2',
    bold: 'https://fonts.gstatic.com/s/bonanova/v1/B50IF7ZCpX7fcHfvIUBxN4d-E46f.woff2',
  },
  {
    name: 'Brygada 1918',
    regular:
      'https://fonts.gstatic.com/s/brygada1918/v8/pe08MI6eKpdGqlF5LANrM--ACNaeo8mTUIR_y2-fyVgcug.woff2',
    bold: 'https://fonts.gstatic.com/s/brygada1918/v8/pe08MI6eKpdGqlF5LANrM--ACNaeo8mTUIR_y7GYyVgcug.woff2',
  },
  {
    name: 'Buenard',
    regular: 'https://fonts.gstatic.com/s/buenard/v12/OD5DuM6Cyma8FnnsDzD3qQ.woff2',
    bold: 'https://fonts.gstatic.com/s/buenard/v12/OD5GuM6Cyma8FnnsB4vSvGecAQ.woff2',
  },
  {
    name: 'Cabin',
    regular:
      'https://fonts.gstatic.com/s/cabin/v18/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkV2EH7alxw.woff2',
    bold: 'https://fonts.gstatic.com/s/cabin/v18/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkYODH7alxw.woff2',
  },
  {
    name: 'Cabin Condensed',
    regular:
      'https://fonts.gstatic.com/s/cabincondensed/v14/nwpMtK6mNhBK2err_hqkYhHRqmwqZ-LY.woff2',
    bold: 'https://fonts.gstatic.com/s/cabincondensed/v14/nwpJtK6mNhBK2err_hqkYhHRqmwiuMbN61d4.woff2',
  },
  {
    name: 'Cabin Sketch',
    regular: 'https://fonts.gstatic.com/s/cabinsketch/v14/QGYpz_kZZAGCONcK2A4bGOj8mNhN.woff2',
    bold: 'https://fonts.gstatic.com/s/cabinsketch/v14/QGY2z_kZZAGCONcK2A4bGOj0I_1Y5tjz.woff2',
  },
  {
    name: 'Cairo',
    regular: 'https://fonts.gstatic.com/s/cairo/v10/SLXGc1nY6HkvalIhTps.woff2',
    bold: 'https://fonts.gstatic.com/s/cairo/v10/SLXLc1nY6Hkvalr-ao6L59Y.woff2',
  },
  {
    name: 'Caladea',
    regular: 'https://fonts.gstatic.com/s/caladea/v2/kJEzBugZ7AAjhybUvRh9-g.woff2',
    bold: 'https://fonts.gstatic.com/s/caladea/v2/kJE2BugZ7AAjhybUtaNY790SqQ.woff2',
  },
  {
    name: 'Cambay',
    regular: 'https://fonts.gstatic.com/s/cambay/v7/SLXJc1rY6H0_ZDs2ab6J.woff2',
    bold: 'https://fonts.gstatic.com/s/cambay/v7/SLXKc1rY6H0_ZDs-0pucwPNx.woff2',
  },
  {
    name: 'Cantarell',
    regular: 'https://fonts.gstatic.com/s/cantarell/v10/B50NF7ZDq37KMUvlO015jKJr.woff2',
    bold: 'https://fonts.gstatic.com/s/cantarell/v10/B50IF7ZDq37KMUvlO01xN4d-E46f.woff2',
  },
  {
    name: 'Cardo',
    regular: 'https://fonts.gstatic.com/s/cardo/v14/wlp_gwjKBV1pqhv43IE.woff2',
    bold: 'https://fonts.gstatic.com/s/cardo/v14/wlpygwjKBV1pqhND-ZQW-WM.woff2',
  },
  {
    name: 'Catamaran',
    regular:
      'https://fonts.gstatic.com/s/catamaran/v8/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPHjd5a7duw.woff2',
    bold: 'https://fonts.gstatic.com/s/catamaran/v8/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPKba5a7duw.woff2',
  },
  {
    name: 'Caudex',
    regular: 'https://fonts.gstatic.com/s/caudex/v10/esDQ311QOP6BJUr4zfKB.woff2',
    bold: 'https://fonts.gstatic.com/s/caudex/v10/esDT311QOP6BJUrwdteUkp8D.woff2',
  },
  {
    name: 'Caveat',
    regular: 'https://fonts.gstatic.com/s/caveat/v10/WnznHAc5bAfYB2QRah7pcpNvOx-pjfJ9eIWpYQ.woff2',
    bold: 'https://fonts.gstatic.com/s/caveat/v10/WnznHAc5bAfYB2QRah7pcpNvOx-pjSx6eIWpYQ.woff2',
  },
  {
    name: 'Chakra Petch',
    regular: 'https://fonts.gstatic.com/s/chakrapetch/v4/cIf6MapbsEk7TDLdtEz1BwkWn6pg.woff2',
    bold: 'https://fonts.gstatic.com/s/chakrapetch/v4/cIflMapbsEk7TDLdtEz1BwkeQI51R5_F.woff2',
  },
  {
    name: 'Changa',
    regular: 'https://fonts.gstatic.com/s/changa/v11/2-c79JNi2YuVOUcOarRPgnNGooxCZ62xcjfj9w.woff2',
    bold: 'https://fonts.gstatic.com/s/changa/v11/2-c79JNi2YuVOUcOarRPgnNGooxCZ3O2cjfj9w.woff2',
  },
  {
    name: 'Charm',
    regular: 'https://fonts.gstatic.com/s/charm/v5/7cHmv4oii5K0MdYoK-4.woff2',
    bold: 'https://fonts.gstatic.com/s/charm/v5/7cHrv4oii5K0Md6TDvs7wH8.woff2',
  },
  {
    name: 'Charmonman',
    regular: 'https://fonts.gstatic.com/s/charmonman/v8/MjQDmiR3vP_nuxDv47jiaJaivQ.woff2',
    bold: 'https://fonts.gstatic.com/s/charmonman/v8/MjQAmiR3vP_nuxDv47jiYC2HqLhA9Q.woff2',
  },
  {
    name: 'Chathura',
    regular: 'https://fonts.gstatic.com/s/chathura/v13/_gP71R7-rzUuVjim428nq0E.woff2',
    bold: 'https://fonts.gstatic.com/s/chathura/v13/_gP81R7-rzUuVjim42ecjlS2avs.woff2',
  },
  {
    name: 'Cherry Swash',
    regular: 'https://fonts.gstatic.com/s/cherryswash/v11/i7dNIFByZjaNAMxtZcnfAy5MR3K_.woff2',
    bold: 'https://fonts.gstatic.com/s/cherryswash/v11/i7dSIFByZjaNAMxtZcnfAy5E_FeqHCSR.woff2',
  },
  {
    name: 'Chivo',
    regular: 'https://fonts.gstatic.com/s/chivo/v12/va9I4kzIxd1KFrBoQeM.woff2',
    bold: 'https://fonts.gstatic.com/s/chivo/v12/va9F4kzIxd1KFrjTZPZ4sK0.woff2',
  },
  {
    name: 'Cinzel',
    regular: 'https://fonts.gstatic.com/s/cinzel/v11/8vIU7ww63mVu7gtR-kwKxNvkNOjw-tbnfY3lDQ.woff2',
    bold: 'https://fonts.gstatic.com/s/cinzel/v11/8vIU7ww63mVu7gtR-kwKxNvkNOjw-gjgfY3lDQ.woff2',
  },
  {
    name: 'Cinzel Decorative',
    regular:
      'https://fonts.gstatic.com/s/cinzeldecorative/v9/daaCSScvJGqLYhG8nNt8KPPswUAPni7TTMw.woff2',
    bold: 'https://fonts.gstatic.com/s/cinzeldecorative/v9/daaHSScvJGqLYhG8nNt8KPPswUAPniZoadlESTE.woff2',
  },
  {
    name: 'Comfortaa',
    regular:
      'https://fonts.gstatic.com/s/comfortaa/v30/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4WjMDrMfIA.woff2',
    bold: 'https://fonts.gstatic.com/s/comfortaa/v30/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4bbLDrMfIA.woff2',
  },
  {
    name: 'Comic Neue',
    regular: 'https://fonts.gstatic.com/s/comicneue/v3/4UaHrEJDsxBrF37olUeD96rp5w.woff2',
    bold: 'https://fonts.gstatic.com/s/comicneue/v3/4UaErEJDsxBrF37olUeD_xHM8pxULg.woff2',
  },
  {
    name: 'Commissioner',
    regular:
      'https://fonts.gstatic.com/s/commissioner/v5/tDbe2o2WnlgI0FNDgduEk4jAhwgIy5k8SlfU5Ni_EOhjOA.woff2',
    bold: 'https://fonts.gstatic.com/s/commissioner/v5/tDbe2o2WnlgI0FNDgduEk4jAhwgIy5k8SlfU5Aa4EOhjOA.woff2',
  },
  {
    name: 'Content',
    regular: 'https://fonts.gstatic.com/s/content/v15/zrfl0HLayePhU_AwYaz4Iw.woff2',
    bold: 'https://fonts.gstatic.com/s/content/v15/zrfg0HLayePhU_AwaRzdNibVWQ.woff2',
  },
  {
    name: 'Corben',
    regular: 'https://fonts.gstatic.com/s/corben/v14/LYjDdGzzklQtCMpNpwNF.woff2',
    bold: 'https://fonts.gstatic.com/s/corben/v14/LYjAdGzzklQtCMpFHCZQqnoq.woff2',
  },
  {
    name: 'Cormorant',
    regular: 'https://fonts.gstatic.com/s/cormorant/v11/H4clBXOCl9bbnla_nHIq75u9.woff2',
    bold: 'https://fonts.gstatic.com/s/cormorant/v11/H4cgBXOCl9bbnla_nHIiML-ohYaz.woff2',
  },
  {
    name: 'Cormorant Garamond',
    regular:
      'https://fonts.gstatic.com/s/cormorantgaramond/v10/co3bmX5slCNuHLi8bLeY9MK7whWMhyjYqXtK.woff2',
    bold: 'https://fonts.gstatic.com/s/cormorantgaramond/v10/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQdl9fvg-I.woff2',
  },
  {
    name: 'Cormorant Infant',
    regular:
      'https://fonts.gstatic.com/s/cormorantinfant/v11/HhyPU44g9vKiM1sORYSiWeAsLN997_cV2Q.woff2',
    bold: 'https://fonts.gstatic.com/s/cormorantinfant/v11/HhyIU44g9vKiM1sORYSiWeAsLN995ygxzDQhQw.woff2',
  },
  {
    name: 'Cormorant SC',
    regular: 'https://fonts.gstatic.com/s/cormorantsc/v11/0yb5GD4kxqXBmOVLG30OGwsuqzAx.woff2',
    bold: 'https://fonts.gstatic.com/s/cormorantsc/v11/0ybmGD4kxqXBmOVLG30OGwsmdBQk-hfz.woff2',
  },
  {
    name: 'Cormorant Unicase',
    regular:
      'https://fonts.gstatic.com/s/cormorantunicase/v13/HI_QiZUaILtOqhqgDeXoF_n1_fTGX9vUlMI.woff2',
    bold: 'https://fonts.gstatic.com/s/cormorantunicase/v13/HI_ViZUaILtOqhqgDeXoF_n1_fTGX9MLsNco5m0.woff2',
  },
  {
    name: 'Cormorant Upright',
    regular:
      'https://fonts.gstatic.com/s/cormorantupright/v11/VuJrdM3I2Y35poFONtLdafkUCHw1y1vShDU.woff2',
    bold: 'https://fonts.gstatic.com/s/cormorantupright/v11/VuJudM3I2Y35poFONtLdafkUCHw1y1MNoCDrWdQ.woff2',
  },
  {
    name: 'Courier Prime',
    regular: 'https://fonts.gstatic.com/s/courierprime/v2/u-450q2lgwslOqpF_6gQ8kELawFpWg.woff2',
    bold: 'https://fonts.gstatic.com/s/courierprime/v2/u-4k0q2lgwslOqpF_6gQ8kELY7pMT-Dfqw.woff2',
  },
  {
    name: 'Cousine',
    regular: 'https://fonts.gstatic.com/s/cousine/v17/d6lIkaiiRdih4SpP_SQvzA.woff2',
    bold: 'https://fonts.gstatic.com/s/cousine/v17/d6lNkaiiRdih4SpP9Z8K2TnM0g.woff2',
  },
  {
    name: 'Crimson Pro',
    regular:
      'https://fonts.gstatic.com/s/crimsonpro/v14/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZzm1MP5s-.woff2',
    bold: 'https://fonts.gstatic.com/s/crimsonpro/v14/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZEGpMP5s-.woff2',
  },
  {
    name: 'Crimson Text',
    regular: 'https://fonts.gstatic.com/s/crimsontext/v11/wlp2gwHKFkZgtmSR3NB0oRJfbwhT.woff2',
    bold: 'https://fonts.gstatic.com/s/crimsontext/v11/wlppgwHKFkZgtmSR3NB0oRJXsCxGDNNQ.woff2',
  },
  {
    name: 'Cuprum',
    regular: 'https://fonts.gstatic.com/s/cuprum/v14/dg45_pLmvrkcOkBnKsOzXyGWTBcmg-X6VjvYJw.woff2',
    bold: 'https://fonts.gstatic.com/s/cuprum/v14/dg45_pLmvrkcOkBnKsOzXyGWTBcmgzv9VjvYJw.woff2',
  },
  {
    name: 'DM Sans',
    regular: 'https://fonts.gstatic.com/s/dmsans/v6/rP2Hp2ywxg089UriCZOIHQ.woff2',
    bold: 'https://fonts.gstatic.com/s/dmsans/v6/rP2Cp2ywxg089UriASitCBimCw.woff2',
  },
  {
    name: 'Dancing Script',
    regular:
      'https://fonts.gstatic.com/s/dancingscript/v16/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3Sup8.woff2',
    bold: 'https://fonts.gstatic.com/s/dancingscript/v16/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7B7y03Sup8.woff2',
  },
  {
    name: 'Darker Grotesque',
    regular:
      'https://fonts.gstatic.com/s/darkergrotesque/v2/U9MH6cuh-mLQlC4BKCtayOfARkSVq7HUJA.woff2',
    bold: 'https://fonts.gstatic.com/s/darkergrotesque/v2/U9MA6cuh-mLQlC4BKCtayOfARkSVo27wMWgrTQ.woff2',
  },
  {
    name: 'David Libre',
    regular: 'https://fonts.gstatic.com/s/davidlibre/v6/snfus0W_99N64iuYSvp4W8l74J8.woff2',
    bold: 'https://fonts.gstatic.com/s/davidlibre/v6/snfzs0W_99N64iuYSvp4W8HAxYqcQDI.woff2',
  },
  {
    name: 'Delius Unicase',
    regular: 'https://fonts.gstatic.com/s/deliusunicase/v16/845BNMEwEIOVT8BmgfSzIr_6mlLAfe8.woff2',
    bold: 'https://fonts.gstatic.com/s/deliusunicase/v16/845CNMEwEIOVT8BmgfSzIr_6mlp7WPr4DGg.woff2',
  },
  {
    name: 'Domine',
    regular: 'https://fonts.gstatic.com/s/domine/v11/L0xhDFMnlVwD4h3Lt9JWnbX3jG-2X3LAE1ofEw.woff2',
    bold: 'https://fonts.gstatic.com/s/domine/v11/L0xhDFMnlVwD4h3Lt9JWnbX3jG-2X6zHE1ofEw.woff2',
  },
  {
    name: 'Dosis',
    regular: 'https://fonts.gstatic.com/s/dosis/v19/HhyJU5sn9vOmLxNkIwRSjTVNWLEJN7Ml2xME.woff2',
    bold: 'https://fonts.gstatic.com/s/dosis/v19/HhyJU5sn9vOmLxNkIwRSjTVNWLEJ6bQl2xME.woff2',
  },
  {
    name: 'EB Garamond',
    regular:
      'https://fonts.gstatic.com/s/ebgaramond/v15/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-6_RkBI9_.woff2',
    bold: 'https://fonts.gstatic.com/s/ebgaramond/v15/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-NfNkBI9_.woff2',
  },
  {
    name: 'Economica',
    regular: 'https://fonts.gstatic.com/s/economica/v8/Qw3fZQZaHCLgIWa29ZBbNsIB.woff2',
    bold: 'https://fonts.gstatic.com/s/economica/v8/Qw3aZQZaHCLgIWa29ZBTjecUDXx4.woff2',
  },
  {
    name: 'Eczar',
    regular: 'https://fonts.gstatic.com/s/eczar/v9/BXRlvF3Pi-DLmz0lDO4.woff2',
    bold: 'https://fonts.gstatic.com/s/eczar/v9/BXRovF3Pi-DLmzX6KPt90Wo.woff2',
  },
  {
    name: 'El Messiri',
    regular:
      'https://fonts.gstatic.com/s/elmessiri/v10/K2FhfZBRmr9vQ1pHEey6GIGo8_pv3myYjuXwe55njDw.woff2',
    bold: 'https://fonts.gstatic.com/s/elmessiri/v10/K2FhfZBRmr9vQ1pHEey6GIGo8_pv3myYjuUufJ5njDw.woff2',
  },
  {
    name: 'Encode Sans',
    regular:
      'https://fonts.gstatic.com/s/encodesans/v8/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGGHjVtKF7Q.woff2',
    bold: 'https://fonts.gstatic.com/s/encodesans/v8/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGL_kVtKF7Q.woff2',
  },
  {
    name: 'Encode Sans Condensed',
    regular:
      'https://fonts.gstatic.com/s/encodesanscondensed/v5/j8_16_LD37rqfuwxyIuaZhE6cRXOLtm2gfT2hq-M.woff2',
    bold: 'https://fonts.gstatic.com/s/encodesanscondensed/v5/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-WYuZAC4I.woff2',
  },
  {
    name: 'Encode Sans Expanded',
    regular:
      'https://fonts.gstatic.com/s/encodesansexpanded/v5/c4m_1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpolKQY.woff2',
    bold: 'https://fonts.gstatic.com/s/encodesansexpanded/v5/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpL6DRNPVo0.woff2',
  },
  {
    name: 'Encode Sans SC',
    regular:
      'https://fonts.gstatic.com/s/encodesanssc/v1/jVyp7nLwCGzQ9zE7ZyRg0QRXHPZc_uUA6Kb3VJWLE_Pdtm7lcD6qvXT1HHhmwchHOQ.woff2',
    bold: 'https://fonts.gstatic.com/s/encodesanssc/v1/jVyp7nLwCGzQ9zE7ZyRg0QRXHPZc_uUA6Kb3VJWLE_Pdtm7lcD6qvXT1HKZhwchHOQ.woff2',
  },
  {
    name: 'Encode Sans Semi Condensed',
    regular:
      'https://fonts.gstatic.com/s/encodesanssemicondensed/v5/3qT4oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1yW9MQ.woff2',
    bold: 'https://fonts.gstatic.com/s/encodesanssemicondensed/v5/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1RJ0NFAZ9U.woff2',
  },
  {
    name: 'Encode Sans Semi Expanded',
    regular:
      'https://fonts.gstatic.com/s/encodesanssemiexpanded/v8/ke83OhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TO401Lg.woff2',
    bold: 'https://fonts.gstatic.com/s/encodesanssemiexpanded/v8/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TM1IROyfBJA.woff2',
  },
  {
    name: 'Enriqueta',
    regular: 'https://fonts.gstatic.com/s/enriqueta/v10/goksH6L7AUFrRvV44HVjTEqi.woff2',
    bold: 'https://fonts.gstatic.com/s/enriqueta/v10/gokpH6L7AUFrRvV44HVrk263n9xF.woff2',
  },
  {
    name: 'Epilogue',
    regular:
      'https://fonts.gstatic.com/s/epilogue/v7/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXMDPSC5_U.woff2',
    bold: 'https://fonts.gstatic.com/s/epilogue/v7/O4ZMFGj5hxF0EhjimngomvnCCtqb30OX7jTSC5_U.woff2',
  },
  {
    name: 'Exo',
    regular: 'https://fonts.gstatic.com/s/exo/v12/4UaZrEtFpBI4f1ZSIK9d4LjJ4lM3OwRmOw.woff2',
    bold: 'https://fonts.gstatic.com/s/exo/v12/4UaZrEtFpBI4f1ZSIK9d4LjJ4o0wOwRmOw.woff2',
  },
  {
    name: 'Exo 2',
    regular: 'https://fonts.gstatic.com/s/exo2/v10/7cH1v4okm5zmbvwkAx_sfcEuiD8jvvKsOdC_.woff2',
    bold: 'https://fonts.gstatic.com/s/exo2/v10/7cH1v4okm5zmbvwkAx_sfcEuiD8jYPWsOdC_.woff2',
  },
  {
    name: 'Expletus Sans',
    regular: 'https://fonts.gstatic.com/s/expletussans/v14/RLp5K5v5_bqufTYdnhFzDj2ddf4Yug.woff2',
    bold: 'https://fonts.gstatic.com/s/expletussans/v14/RLpkK5v5_bqufTYdnhFzDj2dfSE8r64PUQ.woff2',
  },
  {
    name: 'Fahkwang',
    regular: 'https://fonts.gstatic.com/s/fahkwang/v6/Noax6Uj3zpmBOgbNpOqIuLw.woff2',
    bold: 'https://fonts.gstatic.com/s/fahkwang/v6/Noa26Uj3zpmBOgbNpOJXnKlWHCs.woff2',
  },
  {
    name: 'Farro',
    regular: 'https://fonts.gstatic.com/s/farro/v4/i7dEIFl3byGNHaVKFrs.woff2',
    bold: 'https://fonts.gstatic.com/s/farro/v4/i7dJIFl3byGNHa3xM665UEM.woff2',
  },
  {
    name: 'Faustina',
    regular:
      'https://fonts.gstatic.com/s/faustina/v8/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHlsgoEfHl-X.woff2',
    bold: 'https://fonts.gstatic.com/s/faustina/v8/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHlsXIYfHl-X.woff2',
  },
  {
    name: 'Fira Code',
    regular:
      'https://fonts.gstatic.com/s/firacode/v12/uU9dCBsR6Z2vfE9aq3bpdva8iVtipIat--AvNZ-YI8HBrw.woff2',
    bold: 'https://fonts.gstatic.com/s/firacode/v12/uU9dCBsR6Z2vfE9aq3bpdva8iVtipIat--AvNUGfI8HBrw.woff2',
  },
  {
    name: 'Fira Mono',
    regular: 'https://fonts.gstatic.com/s/firamono/v9/N0bX2SlFPv1weGeLZDtgJv7S.woff2',
    bold: 'https://fonts.gstatic.com/s/firamono/v9/N0bS2SlFPv1weGeLZDtondvHnvfU.woff2',
  },
  {
    name: 'Fira Sans',
    regular: 'https://fonts.gstatic.com/s/firasans/v11/va9E4kDNxMZdWfMOD5Vvl4jL.woff2',
    bold: 'https://fonts.gstatic.com/s/firasans/v11/va9B4kDNxMZdWfMOD5VnSKzeRhf6.woff2',
  },
  {
    name: 'Fira Sans Condensed',
    regular:
      'https://fonts.gstatic.com/s/firasanscondensed/v5/wEOhEADFm8hSaQTFG18FErVhsC9x-tarUfbtrQ.woff2',
    bold: 'https://fonts.gstatic.com/s/firasanscondensed/v5/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWSnJuMR0cg.woff2',
  },
  {
    name: 'Fira Sans Extra Condensed',
    regular:
      'https://fonts.gstatic.com/s/firasansextracondensed/v5/NaPKcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1f-uug.woff2',
    bold: 'https://fonts.gstatic.com/s/firasansextracondensed/v5/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3SCKr3i-oQ.woff2',
  },
  {
    name: 'Frank Ruhl Libre',
    regular:
      'https://fonts.gstatic.com/s/frankruhllibre/v6/j8_w6_fAw7jrcalD7oKYNX0QfAnPW7Dl4Q.woff2',
    bold: 'https://fonts.gstatic.com/s/frankruhllibre/v6/j8_36_fAw7jrcalD7oKYNX0QfAnPUwvA9JfGhA.woff2',
  },
  {
    name: 'Fraunces',
    regular:
      'https://fonts.gstatic.com/s/fraunces/v10/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIctxuTCf7T.woff2',
    bold: 'https://fonts.gstatic.com/s/fraunces/v10/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIcaRyTCf7T.woff2',
  },
  {
    name: 'GFS Neohellenic',
    regular:
      'https://fonts.gstatic.com/s/gfsneohellenic/v15/8QIRdiDOrfiq0b7R8O1Iw9WLcY5jKaJO.woff2',
    bold: 'https://fonts.gstatic.com/s/gfsneohellenic/v15/8QIUdiDOrfiq0b7R8O1Iw9WLcY5rkYdb74Qe.woff2',
  },
  {
    name: 'Gaegu',
    regular:
      'https://fonts.gstatic.com/s/gaegu/v10/TuGfUVB6Up9NU6ZOyc4xxqgKdTBRWpq2sy8wKnARGTY.119.woff2',
    bold: 'https://fonts.gstatic.com/s/gaegu/v10/TuGSUVB6Up9NU573jvw-itFvCT5fRqCzuBY1OnoEfFpo9AQ.119.woff2',
  },
  {
    name: 'Gayathri',
    regular: 'https://fonts.gstatic.com/s/gayathri/v6/MCoQzAb429DbBilWLLA_-p4.woff2',
    bold: 'https://fonts.gstatic.com/s/gayathri/v6/MCoXzAb429DbBilWLLiE34v_J_U.woff2',
  },
  {
    name: 'Gelasio',
    regular: 'https://fonts.gstatic.com/s/gelasio/v4/cIf9MaFfvUQxTTqS9C6hYQ.woff2',
    bold: 'https://fonts.gstatic.com/s/gelasio/v4/cIf4MaFfvUQxTTqS_PGFdGYmnQ.woff2',
  },
  {
    name: 'Gemunu Libre',
    regular:
      'https://fonts.gstatic.com/s/gemunulibre/v1/X7n34bQ6Cfy7jKGXVE_YlqnbEQAFP-PIuTCp0xiJDvOBeQ.woff2',
    bold: 'https://fonts.gstatic.com/s/gemunulibre/v1/X7n34bQ6Cfy7jKGXVE_YlqnbEQAFP-PIuTCp08aODvOBeQ.woff2',
  },
  {
    name: 'Gentium Basic',
    regular: 'https://fonts.gstatic.com/s/gentiumbasic/v12/Wnz9HAw9aB_JD2VGQVR80We3LA2JiA.woff2',
    bold: 'https://fonts.gstatic.com/s/gentiumbasic/v12/WnzgHAw9aB_JD2VGQVR80We3JLasnT0ebQ.woff2',
  },
  {
    name: 'Gentium Book Basic',
    regular:
      'https://fonts.gstatic.com/s/gentiumbookbasic/v11/pe0zMJCbPYBVokB1LHA9bbyaQb8ZGjc4ULF6.woff2',
    bold: 'https://fonts.gstatic.com/s/gentiumbookbasic/v11/pe0wMJCbPYBVokB1LHA9bbyaQb8ZGjcw65RvzIfZ.woff2',
  },
  {
    name: 'Georama',
    regular:
      'https://fonts.gstatic.com/s/georama/v1/MCo5zAn438bIEyxFf6swMnNpvPcUwW4u4yRcDh-ZjxApn9K5GvgdngEK.woff2',
    bold: 'https://fonts.gstatic.com/s/georama/v1/MCo5zAn438bIEyxFf6swMnNpvPcUwW4u4yRcDh-ZjxApn9K5xP8dngEK.woff2',
  },
  {
    name: 'Glegoo',
    regular: 'https://fonts.gstatic.com/s/glegoo/v10/_Xmt-HQyrTKWaw25jKOY.woff2',
    bold: 'https://fonts.gstatic.com/s/glegoo/v10/_Xmu-HQyrTKWaw2xN4aND65o.woff2',
  },
  {
    name: 'Glory',
    regular: 'https://fonts.gstatic.com/s/glory/v2/q5uasoi9Lf1w5t3Est24nq9blIRQwImzoXWc.woff2',
    bold: 'https://fonts.gstatic.com/s/glory/v2/q5uasoi9Lf1w5t3Est24nq9blIRQHo6zoXWc.woff2',
  },
  {
    name: 'Gluten',
    regular: 'https://fonts.gstatic.com/s/gluten/v1/HhyIU5gk9fW7OUdVIPh2wBPxSqQJ_zh2zDQhQw.woff2',
    bold: 'https://fonts.gstatic.com/s/gluten/v1/HhyIU5gk9fW7OUdVIPh2wBPxSqQJ_-ZxzDQhQw.woff2',
  },
  {
    name: 'Goldman',
    regular: 'https://fonts.gstatic.com/s/goldman/v5/pe0uMIWbN4JFplR2HDVyBw.woff2',
    bold: 'https://fonts.gstatic.com/s/goldman/v5/pe0rMIWbN4JFplR2FI5XEtCaBg.woff2',
  },
  {
    name: 'Gorditas',
    regular: 'https://fonts.gstatic.com/s/gorditas/v10/ll8_K2aTVD26DsPEtTDvB68.woff2',
    bold: 'https://fonts.gstatic.com/s/gorditas/v10/ll84K2aTVD26DsPEtThUIroPtgs.woff2',
  },
  {
    name: 'Gothic A1',
    regular:
      'https://fonts.gstatic.com/s/gothica1/v8/CSR94z5ZnPydRjlCCwl6aaU4Qt0V05ZAcgT3T1VKO2vL6LbP.119.woff2',
    bold: 'https://fonts.gstatic.com/s/gothica1/v8/CSR44z5ZnPydRjlCCwlCtOMKTZFstupOfBjNSl5zPnvB_dOjwqCu.119.woff2',
  },
  {
    name: 'Gowun Batang',
    regular:
      'https://fonts.gstatic.com/s/gowunbatang/v1/ijwSs5nhRMIjYsdSgcMa3wRhWdnoyO9eQVvLv7TGp0JLNitJCzbi.119.woff2',
    bold: 'https://fonts.gstatic.com/s/gowunbatang/v1/ijwNs5nhRMIjYsdSgcMa3wRZ4J7ax6MnJCfFsaj8oklyMztDHlOOun84.119.woff2',
  },
  {
    name: 'Grandstander',
    regular:
      'https://fonts.gstatic.com/s/grandstander/v4/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD1--P3_ctw.woff2',
    bold: 'https://fonts.gstatic.com/s/grandstander/v4/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD4G5P3_ctw.woff2',
  },
  {
    name: 'Grenze',
    regular: 'https://fonts.gstatic.com/s/grenze/v4/O4ZTFGb7hR12BxqH-GIm.woff2',
    bold: 'https://fonts.gstatic.com/s/grenze/v4/O4ZQFGb7hR12BxqPJ0YzlS0X.woff2',
  },
  {
    name: 'Grenze Gotisch',
    regular:
      'https://fonts.gstatic.com/s/grenzegotisch/v6/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5Lz9kd4qc.woff2',
    bold: 'https://fonts.gstatic.com/s/grenzegotisch/v6/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i58Thkd4qc.woff2',
  },
  {
    name: 'Gudea',
    regular: 'https://fonts.gstatic.com/s/gudea/v10/neIFzCqgsI0mp9CI_oA.woff2',
    bold: 'https://fonts.gstatic.com/s/gudea/v10/neIIzCqgsI0mp9gz25WBFqw.woff2',
  },
  {
    name: 'Gupter',
    regular: 'https://fonts.gstatic.com/s/gupter/v4/2-cm9JNmxJqPO1QkZpy-.woff2',
    bold: 'https://fonts.gstatic.com/s/gupter/v4/2-cl9JNmxJqPO1Qs3bmrVc74.woff2',
  },
  {
    name: 'Hahmlet',
    regular:
      'https://fonts.gstatic.com/s/hahmlet/v1/BngXUXpCQ3nKpIo0TfPyfCdXfaeU4RhKONjtBtW8Zi8JD7XHu4nHED1fifd-09o.119.woff2',
    bold: 'https://fonts.gstatic.com/s/hahmlet/v1/BngXUXpCQ3nKpIo0TfPyfCdXfaeU4RiUP9jtBtW8Zi8JD7XHu4nHED1fifd-09o.119.woff2',
  },
  {
    name: 'Halant',
    regular: 'https://fonts.gstatic.com/s/halant/v8/u-4-0qaujRI2Pbsn2Nhn.woff2',
    bold: 'https://fonts.gstatic.com/s/halant/v8/u-490qaujRI2PbsvB_xynAxr.woff2',
  },
  {
    name: 'Hanuman',
    regular: 'https://fonts.gstatic.com/s/hanuman/v16/VuJxdNvD15HhpJJBSKHdOQ.woff2',
    bold: 'https://fonts.gstatic.com/s/hanuman/v16/VuJ0dNvD15HhpJJBQBr4LI5GZA.woff2',
  },
  {
    name: 'Harmattan',
    regular: 'https://fonts.gstatic.com/s/harmattan/v11/goksH6L2DkFvVvRp9XpjTEqi.woff2',
    bold: 'https://fonts.gstatic.com/s/harmattan/v11/gokpH6L2DkFvVvRp9Xpr92-3n9xF.woff2',
  },
  {
    name: 'Heebo',
    regular: 'https://fonts.gstatic.com/s/heebo/v12/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysdUmm.woff2',
    bold: 'https://fonts.gstatic.com/s/heebo/v12/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EVyusdUmm.woff2',
  },
  {
    name: 'Hepta Slab',
    regular:
      'https://fonts.gstatic.com/s/heptaslab/v9/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvkV5wfcaZE.woff2',
    bold: 'https://fonts.gstatic.com/s/heptaslab/v9/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvnL4AfcaZE.woff2',
  },
  {
    name: 'Hind',
    regular: 'https://fonts.gstatic.com/s/hind/v11/5aU69_a8oxmIdGl4BA.woff2',
    bold: 'https://fonts.gstatic.com/s/hind/v11/5aU19_a8oxmIfLZcERySjQ.woff2',
  },
  {
    name: 'Hind Guntur',
    regular: 'https://fonts.gstatic.com/s/hindguntur/v7/wXKvE3UZrok56nvamSuJd_QtvXI.woff2',
    bold: 'https://fonts.gstatic.com/s/hindguntur/v7/wXKyE3UZrok56nvamSuJd_zymWc0ld0.woff2',
  },
  {
    name: 'Hind Madurai',
    regular: 'https://fonts.gstatic.com/s/hindmadurai/v6/f0Xx0e2p98ZvDXdZQIOcpqjX9ocC.woff2',
    bold: 'https://fonts.gstatic.com/s/hindmadurai/v6/f0Xu0e2p98ZvDXdZQIOcpqjfKaMXfsEp.woff2',
  },
  {
    name: 'Hind Siliguri',
    regular: 'https://fonts.gstatic.com/s/hindsiliguri/v7/ijwTs5juQtsyLLR5jN4cxBEoTJzaxw.woff2',
    bold: 'https://fonts.gstatic.com/s/hindsiliguri/v7/ijwOs5juQtsyLLR5jN4cxBEoREP-0uYVKw.woff2',
  },
  {
    name: 'Hind Vadodara',
    regular: 'https://fonts.gstatic.com/s/hindvadodara/v7/neINzCKvrIcn5pbuuuriV9tTQJLVrA.woff2',
    bold: 'https://fonts.gstatic.com/s/hindvadodara/v7/neIQzCKvrIcn5pbuuuriV9tTSE3xuXQ-oA.woff2',
  },
  {
    name: 'IBM Plex Mono',
    regular: 'https://fonts.gstatic.com/s/ibmplexmono/v7/-F63fjptAgt5VM-kVkqdyU8n1i8q1w.woff2',
    bold: 'https://fonts.gstatic.com/s/ibmplexmono/v7/-F6qfjptAgt5VM-kVkqdyU8n3vAOwlBFgg.woff2',
  },
  {
    name: 'IBM Plex Sans',
    regular: 'https://fonts.gstatic.com/s/ibmplexsans/v9/zYXgKVElMYYaJe8bpLHnCwDKhdHeFQ.woff2',
    bold: 'https://fonts.gstatic.com/s/ibmplexsans/v9/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIFsdA.woff2',
  },
  {
    name: 'IBM Plex Sans Arabic',
    regular:
      'https://fonts.gstatic.com/s/ibmplexsansarabic/v1/Qw3CZRtWPQCuHme67tEYUIx3Kh0PHR9N6Ys93PU.woff2',
    bold: 'https://fonts.gstatic.com/s/ibmplexsansarabic/v1/Qw3NZRtWPQCuHme67tEYUIx3Kh0PHR9N6YPi-OCUXMQ.woff2',
  },
  {
    name: 'IBM Plex Sans Condensed',
    regular:
      'https://fonts.gstatic.com/s/ibmplexsanscondensed/v8/Gg8lN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYapyK4.woff2',
    bold: 'https://fonts.gstatic.com/s/ibmplexsanscondensed/v8/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY527LvspYY.woff2',
  },
  {
    name: 'IBM Plex Sans Devanagari',
    regular:
      'https://fonts.gstatic.com/s/ibmplexsansdevanagari/v1/XRXH3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O_PUkj1.woff2',
    bold: 'https://fonts.gstatic.com/s/ibmplexsansdevanagari/v1/XRXA3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O_HjWzgfOz9.woff2',
  },
  {
    name: 'IBM Plex Sans Hebrew',
    regular:
      'https://fonts.gstatic.com/s/ibmplexsanshebrew/v1/BCa2qYENg9Kw1mpLpO0bGM5lfHAAZHhDXE2i-lg.woff2',
    bold: 'https://fonts.gstatic.com/s/ibmplexsanshebrew/v1/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEV93k04jDs.woff2',
  },
  {
    name: 'IBM Plex Sans KR',
    regular:
      'https://fonts.gstatic.com/s/ibmplexsanskr/v1/vEFK2-VJISZe3O_rc3ZVYh4aTwNO8tfdTbzIx6SmGN7aVT6YLxnNoW0zig.119.woff2',
    bold: 'https://fonts.gstatic.com/s/ibmplexsanskr/v1/vEFN2-VJISZe3O_rc3ZVYh4aTwNOygqbf7OEvsHaFtDGbzuTFhzdq3hW5qs5jg.119.woff2',
  },
  {
    name: 'IBM Plex Sans Thai',
    regular:
      'https://fonts.gstatic.com/s/ibmplexsansthai/v1/m8JPje1VVIzcq1HzJq2AEdo2Tj_qvLqMCdYg.woff2',
    bold: 'https://fonts.gstatic.com/s/ibmplexsansthai/v1/m8JMje1VVIzcq1HzJq2AEdo2Tj_qvLqE1vI1auJH.woff2',
  },
  {
    name: 'IBM Plex Sans Thai Looped',
    regular:
      'https://fonts.gstatic.com/s/ibmplexsansthailooped/v1/tss_AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30L9BWKoQ.woff2',
    bold: 'https://fonts.gstatic.com/s/ibmplexsansthailooped/v1/tss6AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30L_MqutF6JGQ.woff2',
  },
  {
    name: 'IBM Plex Serif',
    regular: 'https://fonts.gstatic.com/s/ibmplexserif/v10/jizDREVNn1dOx-zrZ2X3pZvkTiUf2zc.woff2',
    bold: 'https://fonts.gstatic.com/s/ibmplexserif/v10/jizAREVNn1dOx-zrZ2X3pZvkTi3A_yI0q1s.woff2',
  },
  {
    name: 'Ibarra Real Nova',
    regular:
      'https://fonts.gstatic.com/s/ibarrarealnova/v8/sZlSdQiA-DBIDCcaWtQzL4BZHoiDundw4ATyjed3EXdg5PDqXz4.woff2',
    bold: 'https://fonts.gstatic.com/s/ibarrarealnova/v8/sZlSdQiA-DBIDCcaWtQzL4BZHoiDundw4ATyjed3EXe-4_DqXz4.woff2',
  },
  {
    name: 'Imbue',
    regular:
      'https://fonts.gstatic.com/s/imbue/v9/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP8iXTOwHNQ.woff2',
    bold: 'https://fonts.gstatic.com/s/imbue/v9/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoPxaQTOwHNQ.woff2',
  },
  {
    name: 'Inconsolata',
    regular:
      'https://fonts.gstatic.com/s/inconsolata/v21/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp4U8WR32lw.woff2',
    bold: 'https://fonts.gstatic.com/s/inconsolata/v21/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp1s7WR32lw.woff2',
  },
  {
    name: 'Inika',
    regular: 'https://fonts.gstatic.com/s/inika/v11/rnCm-x5X3QP-piTAT8Y.woff2',
    bold: 'https://fonts.gstatic.com/s/inika/v11/rnCr-x5X3QP-pix7atM5kng.woff2',
  },
  {
    name: 'Inknut Antiqua',
    regular: 'https://fonts.gstatic.com/s/inknutantiqua/v9/Y4GSYax7VC4ot_qNB4nYpBdaKUUE4J0.woff2',
    bold: 'https://fonts.gstatic.com/s/inknutantiqua/v9/Y4GRYax7VC4ot_qNB4nYpBdaKU3bxIj-Zhs.woff2',
  },
  {
    name: 'Inria Sans',
    regular: 'https://fonts.gstatic.com/s/inriasans/v4/ptRMTiqXYfZMCOiVj9kQ1On4KA.woff2',
    bold: 'https://fonts.gstatic.com/s/inriasans/v4/ptRPTiqXYfZMCOiVj9kQ3FLdPQxPqA.woff2',
  },
  {
    name: 'Inria Serif',
    regular: 'https://fonts.gstatic.com/s/inriaserif/v4/fC1lPYxPY3rXxEndZJAzN3Srdy0.woff2',
    bold: 'https://fonts.gstatic.com/s/inriaserif/v4/fC14PYxPY3rXxEndZJAzN3wQUjjCjl0.woff2',
  },
  {
    name: 'Inter',
    regular:
      'https://fonts.gstatic.com/s/inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZ9hiA.woff2',
    bold: 'https://fonts.gstatic.com/s/inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZ9hiA.woff2',
  },
  {
    name: 'Istok Web',
    regular: 'https://fonts.gstatic.com/s/istokweb/v15/3qTvojGmgSyUukBzKslpBmt_.woff2',
    bold: 'https://fonts.gstatic.com/s/istokweb/v15/3qTqojGmgSyUukBzKslhvU5q-WMV.woff2',
  },
  {
    name: 'Jaldi',
    regular: 'https://fonts.gstatic.com/s/jaldi/v7/or3sQ67z0_CI33NTbJE.woff2',
    bold: 'https://fonts.gstatic.com/s/jaldi/v7/or3hQ67z0_CI33voSYTwJrU.woff2',
  },
  {
    name: 'JetBrains Mono',
    regular:
      'https://fonts.gstatic.com/s/jetbrainsmono/v6/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8yKxTOlOV.woff2',
    bold: 'https://fonts.gstatic.com/s/jetbrainsmono/v6/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8FqtTOlOV.woff2',
  },
  {
    name: 'Josefin Sans',
    regular:
      'https://fonts.gstatic.com/s/josefinsans/v17/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjQbMZhLw.woff2',
    bold: 'https://fonts.gstatic.com/s/josefinsans/v17/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_ObXbMZhLw.woff2',
  },
  {
    name: 'Josefin Slab',
    regular:
      'https://fonts.gstatic.com/s/josefinslab/v13/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W71msR349Kg.woff2',
    bold: 'https://fonts.gstatic.com/s/josefinslab/v13/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W74erR349Kg.woff2',
  },
  {
    name: 'Jost',
    regular: 'https://fonts.gstatic.com/s/jost/v6/92zPtBhPNqw79Ij1E865zBUv7myjJTVBNIg.woff2',
    bold: 'https://fonts.gstatic.com/s/jost/v6/92zPtBhPNqw79Ij1E865zBUv7mx9IjVBNIg.woff2',
  },
  {
    name: 'Judson',
    regular: 'https://fonts.gstatic.com/s/judson/v13/FeVRS0Fbvbc14VxhCLl1.woff2',
    bold: 'https://fonts.gstatic.com/s/judson/v13/FeVSS0Fbvbc14Vxps5xg2pRm.woff2',
  },
  {
    name: 'Jura',
    regular: 'https://fonts.gstatic.com/s/jura/v16/z7NOdRfiaC4Vd8hhoPzfb5vBTP1d7ZumR_g.woff2',
    bold: 'https://fonts.gstatic.com/s/jura/v16/z7NOdRfiaC4Vd8hhoPzfb5vBTP2D6pumR_g.woff2',
  },
  {
    name: 'K2D',
    regular: 'https://fonts.gstatic.com/s/k2d/v4/J7aTnpF2V0EjcKUs.woff2',
    bold: 'https://fonts.gstatic.com/s/k2d/v4/J7aenpF2V0Err4E5k5Y9.woff2',
  },
  {
    name: 'Kadwa',
    regular: 'https://fonts.gstatic.com/s/kadwa/v5/rnCm-x5V0g7ipiTAT8Y.woff2',
    bold: 'https://fonts.gstatic.com/s/kadwa/v5/rnCr-x5V0g7ipix7atM5kng.woff2',
  },
  {
    name: 'Kaisei Decol',
    regular:
      'https://fonts.gstatic.com/s/kaiseidecol/v1/bMrwmSqP45sidWf3QmfFW6iyXjkf2XLRwM8C_3lcBLoqQF14cHSwsFA.119.woff2',
    bold: 'https://fonts.gstatic.com/s/kaiseidecol/v1/bMrvmSqP45sidWf3QmfFW6iK534r1iCvq68J8GxOD4A9QGR9YH6l1TxuoDM.119.woff2',
  },
  {
    name: 'Kaisei HarunoUmi',
    regular:
      'https://fonts.gstatic.com/s/kaiseiharunoumi/v1/HI_RiZQSLqBQoAHhK_C6N_nzy_jcGs6RoM0_rAnMbWq216sULRh7Dezy0i1T.119.woff2',
    bold: 'https://fonts.gstatic.com/s/kaiseiharunoumi/v1/HI_WiZQSLqBQoAHhK_C6N_nzy_jcInfWlMJt0mKsZmWjxaAuOhhCCPz4x0g_tAjU.119.woff2',
  },
  {
    name: 'Kaisei Opti',
    regular:
      'https://fonts.gstatic.com/s/kaiseiopti/v1/QldKNThJphYb8_g6c2nlIFlbhLlk15ORfPVIrjOHkekZbFsT8NP5pw.119.woff2',
    bold: 'https://fonts.gstatic.com/s/kaiseiopti/v1/QldXNThJphYb8_g6c2nlIGHiw41rhe36HP5HuyGMq_4ZVV4D-sacyzlvSQ.119.woff2',
  },
  {
    name: 'Kaisei Tokumin',
    regular:
      'https://fonts.gstatic.com/s/kaiseitokumin/v1/Gg8sN5wdZg7xCwuMsylww2ZiQkJavk0rnqxaCffAjdiO2udocHPNayluTw.119.woff2',
    bold: 'https://fonts.gstatic.com/s/kaiseitokumin/v1/Gg8vN5wdZg7xCwuMsylww2ZiQnrj-XkkzNIxafzPmMqF4PBoSXbdYTwLIw1xwQ.119.woff2',
  },
  {
    name: 'Kalam',
    regular: 'https://fonts.gstatic.com/s/kalam/v11/YA9dr0Wd4kDdMthROCc.woff2',
    bold: 'https://fonts.gstatic.com/s/kalam/v11/YA9Qr0Wd4kDdMtDqHTLMkiQ.woff2',
  },
  {
    name: 'Kameron',
    regular: 'https://fonts.gstatic.com/s/kameron/v11/vm82dR7vXErQxuzngLk6Lg.woff2',
    bold: 'https://fonts.gstatic.com/s/kameron/v11/vm8zdR7vXErQxuzniAIfO-rpfQ.woff2',
  },
  {
    name: 'Kanit',
    regular: 'https://fonts.gstatic.com/s/kanit/v7/nKKZ-Go6G5tXcraVGwA.woff2',
    bold: 'https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr5KPxWnVaE.woff2',
  },
  {
    name: 'Kantumruy',
    regular: 'https://fonts.gstatic.com/s/kantumruy/v11/sykx-yJ0m7wyVb-f4FOH8vLA.woff2',
    bold: 'https://fonts.gstatic.com/s/kantumruy/v11/syk0-yJ0m7wyVb-f4FOPQtfVqnWV.woff2',
  },
  {
    name: 'Karantina',
    regular: 'https://fonts.gstatic.com/s/karantina/v1/buE0po24ccnh31GVMAB59wo6.woff2',
    bold: 'https://fonts.gstatic.com/s/karantina/v1/buExpo24ccnh31GVMABxTC8v__RP.woff2',
  },
  {
    name: 'Karla',
    regular: 'https://fonts.gstatic.com/s/karla/v15/qkBIXvYC6trAT55ZBi1ueQVIjQTD-JqaE0lK.woff2',
    bold: 'https://fonts.gstatic.com/s/karla/v15/qkBIXvYC6trAT55ZBi1ueQVIjQTDJp2aE0lK.woff2',
  },
  {
    name: 'Karma',
    regular: 'https://fonts.gstatic.com/s/karma/v11/va9I4kzAzMZRGLBoQeM.woff2',
    bold: 'https://fonts.gstatic.com/s/karma/v11/va9F4kzAzMZRGLi3ZfZ4sK0.woff2',
  },
  {
    name: 'Khand',
    regular: 'https://fonts.gstatic.com/s/khand/v9/TwMA-IINQlQQ0bpSUnQ.woff2',
    bold: 'https://fonts.gstatic.com/s/khand/v9/TwMN-IINQlQQ0bKNdmEwbQc.woff2',
  },
  {
    name: 'Khula',
    regular: 'https://fonts.gstatic.com/s/khula/v7/OpNCnoEOns3V7GcOrg4.woff2',
    bold: 'https://fonts.gstatic.com/s/khula/v7/OpNPnoEOns3V7G_RihvTpi8.woff2',
  },
  {
    name: 'Klee One',
    regular:
      'https://fonts.gstatic.com/s/kleeone/v1/LDIxapCLNRc6A8oT4q4FUfemSYiv49tfllrv3pe-KYFKDXn9Sg.119.woff2',
    bold: 'https://fonts.gstatic.com/s/kleeone/v1/LDI2apCLNRc6A8oT4pbYF8OpG_bEg9BQg0jk5IC-EIRaB2yYJkvAGA.119.woff2',
  },
  {
    name: 'KoHo',
    regular: 'https://fonts.gstatic.com/s/koho/v6/K2F-fZ5fmddNNi4UfQ.woff2',
    bold: 'https://fonts.gstatic.com/s/koho/v6/K2FxfZ5fmddNPvEwaJnzJw.woff2',
  },
  {
    name: 'Kodchasan',
    regular: 'https://fonts.gstatic.com/s/kodchasan/v6/1cXxaUPOAJv9sG4I-DJWiHGF.woff2',
    bold: 'https://fonts.gstatic.com/s/kodchasan/v6/1cX0aUPOAJv9sG4I-DJeV1WQhuCp.woff2',
  },
  {
    name: 'Koh Santepheap',
    regular: 'https://fonts.gstatic.com/s/kohsantepheap/v1/gNMdW3p6SJbwyGj2rBZyeOrTjNPmHVk.woff2',
    bold: 'https://fonts.gstatic.com/s/kohsantepheap/v1/gNMeW3p6SJbwyGj2rBZyeOrTjNtdOEy-kj4.woff2',
  },
  {
    name: 'Kreon',
    regular: 'https://fonts.gstatic.com/s/kreon/v24/t5t9IRIUKY-TFF_LW5lnMR3v2DnvYtiWfT8e.woff2',
    bold: 'https://fonts.gstatic.com/s/kreon/v24/t5t9IRIUKY-TFF_LW5lnMR3v2DnvvN-WfT8e.woff2',
  },
  {
    name: 'Krub',
    regular: 'https://fonts.gstatic.com/s/krub/v4/sZlLdRyC6CRYbkEaDQ.woff2',
    bold: 'https://fonts.gstatic.com/s/krub/v4/sZlEdRyC6CRYZp4-GLZyRg.woff2',
  },
  {
    name: 'Kufam',
    regular: 'https://fonts.gstatic.com/s/kufam/v11/C8c-4cY7pG7w_oSJDszBXsKCcBH3lqkLrgqI.woff2',
    bold: 'https://fonts.gstatic.com/s/kufam/v11/C8c-4cY7pG7w_oSJDszBXsKCcBH3SK4LrgqI.woff2',
  },
  {
    name: 'Kulim Park',
    regular: 'https://fonts.gstatic.com/s/kulimpark/v4/fdN79secq3hflz1Uu3IwhFks5A.woff2',
    bold: 'https://fonts.gstatic.com/s/kulimpark/v4/fdN49secq3hflz1Uu3IwjIYI8alQZA.woff2',
  },
  {
    name: 'Kumbh Sans',
    regular:
      'https://fonts.gstatic.com/s/kumbhsans/v6/c4mw1n92AsfhuCq6tVsaoIx1CHIi4kToNorqShNPVo0.woff2',
    bold: 'https://fonts.gstatic.com/s/kumbhsans/v6/c4mw1n92AsfhuCq6tVsaoIx1CHIi4kToNoo0TRNPVo0.woff2',
  },
  {
    name: 'Laila',
    regular: 'https://fonts.gstatic.com/s/laila/v8/LYjMdG_8nE8jDLRagCY.woff2',
    bold: 'https://fonts.gstatic.com/s/laila/v8/LYjBdG_8nE8jDLyFpDNHjV8.woff2',
  },
  {
    name: 'Lato',
    regular: 'https://fonts.gstatic.com/s/lato/v20/S6uyw4BMUTPHjx4wXg.woff2',
    bold: 'https://fonts.gstatic.com/s/lato/v20/S6u9w4BMUTPHh6UVSwiPGQ.woff2',
  },
  {
    name: 'Lekton',
    regular: 'https://fonts.gstatic.com/s/lekton/v12/SZc43FDmLaWmWpBuWB3p.woff2',
    bold: 'https://fonts.gstatic.com/s/lekton/v12/SZc73FDmLaWmWpBm4zj8kmLW.woff2',
  },
  {
    name: 'Lemonada',
    regular:
      'https://fonts.gstatic.com/s/lemonada/v14/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGeutGnv-d.woff2',
    bold: 'https://fonts.gstatic.com/s/lemonada/v14/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGpOxGnv-d.woff2',
  },
  {
    name: 'Lexend',
    regular: 'https://fonts.gstatic.com/s/lexend/v7/~ChUKBkxleGVuZDoLCPTQnbsHFQAAyEMQByAF.woff2',
    bold: 'https://fonts.gstatic.com/s/lexend/v7/~ChUKBkxleGVuZDoLCPTQnbsHFQAAFkQQByAF.woff2',
  },
  {
    name: 'Lexend Deca',
    regular:
      'https://fonts.gstatic.com/s/lexenddeca/v10/K2FifZFYk-dHSE0UPPuwQ7CrD94i-NCKm-U48M1wqxnD.woff2',
    bold: 'https://fonts.gstatic.com/s/lexenddeca/v10/K2FifZFYk-dHSE0UPPuwQ7CrD94i-NCKm-U4LspwqxnD.woff2',
  },
  {
    name: 'Lexend Exa',
    regular:
      'https://fonts.gstatic.com/s/lexendexa/v15/UMBCrPdOoHOnxExyjdBeQCH18mulUxBvI9r7TpbAFp4.woff2',
    bold: 'https://fonts.gstatic.com/s/lexendexa/v15/UMBCrPdOoHOnxExyjdBeQCH18mulUxBvI9olSZbAFp4.woff2',
  },
  {
    name: 'Lexend Giga',
    regular:
      'https://fonts.gstatic.com/s/lexendgiga/v15/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRC2Li07MAp.woff2',
    bold: 'https://fonts.gstatic.com/s/lexendgiga/v15/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRCBr-07MAp.woff2',
  },
  {
    name: 'Lexend Mega',
    regular:
      'https://fonts.gstatic.com/s/lexendmega/v15/qFdX35aBi5JtHD41zSTFEuTByuvYFuE9IbDL8fmfuuaj.woff2',
    bold: 'https://fonts.gstatic.com/s/lexendmega/v15/qFdX35aBi5JtHD41zSTFEuTByuvYFuE9IbDLL_6fuuaj.woff2',
  },
  {
    name: 'Lexend Peta',
    regular:
      'https://fonts.gstatic.com/s/lexendpeta/v15/BXR4vFPGjeLPh0kCfI4OkFX-UTQHSCaxvBgR6SBCXFwv.woff2',
    bold: 'https://fonts.gstatic.com/s/lexendpeta/v15/BXR4vFPGjeLPh0kCfI4OkFX-UTQHSCaxvBgRNydCXFwv.woff2',
  },
  {
    name: 'Lexend Tera',
    regular:
      'https://fonts.gstatic.com/s/lexendtera/v15/RrQDbo98_jt_IXnBPwCWtYJLZ3P4hnaGKFiM5zMjcZb1.woff2',
    bold: 'https://fonts.gstatic.com/s/lexendtera/v15/RrQDbo98_jt_IXnBPwCWtYJLZ3P4hnaGKFiMOTQjcZb1.woff2',
  },
  {
    name: 'Lexend Zetta',
    regular:
      'https://fonts.gstatic.com/s/lexendzetta/v15/ll8uK2KYXje7CdOFnEWcU8synQbuVYjYB3BCy9bG4zlpbw.woff2',
    bold: 'https://fonts.gstatic.com/s/lexendzetta/v15/ll8uK2KYXje7CdOFnEWcU8synQbuVYjYB3BCywjB4zlpbw.woff2',
  },
  {
    name: 'Libre Baskerville',
    regular:
      'https://fonts.gstatic.com/s/librebaskerville/v9/kmKnZrc3Hgbbcjq75U4uslyuy4kn0qNZaxM.woff2',
    bold: 'https://fonts.gstatic.com/s/librebaskerville/v9/kmKiZrc3Hgbbcjq75U4uslyuy4kn0qviTgY3KcA.woff2',
  },
  {
    name: 'Libre Caslon Text',
    regular:
      'https://fonts.gstatic.com/s/librecaslontext/v2/DdT878IGsGw1aF1JU10PUbTvNNaDMfq41-I.woff2',
    bold: 'https://fonts.gstatic.com/s/librecaslontext/v2/DdT578IGsGw1aF1JU10PUbTvNNaDMfID8vdkPx4.woff2',
  },
  {
    name: 'Libre Franklin',
    regular:
      'https://fonts.gstatic.com/s/librefranklin/v7/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhLsWkANDJ.woff2',
    bold: 'https://fonts.gstatic.com/s/librefranklin/v7/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduh8MKkANDJ.woff2',
  },
  {
    name: 'Life Savers',
    regular: 'https://fonts.gstatic.com/s/lifesavers/v13/ZXuie1UftKKabUQMgxAal8lsHAs.woff2',
    bold: 'https://fonts.gstatic.com/s/lifesavers/v13/ZXu_e1UftKKabUQMgxAal8HXOR5UmcY.woff2',
  },
  {
    name: 'Literata',
    regular:
      'https://fonts.gstatic.com/s/literata/v23/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbBG_J_HWTA.woff2',
    bold: 'https://fonts.gstatic.com/s/literata/v23/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbM-4J_HWTA.woff2',
  },
  {
    name: 'Livvic',
    regular: 'https://fonts.gstatic.com/s/livvic/v8/rnCp-x1S2hzjrlfXaOM7.woff2',
    bold: 'https://fonts.gstatic.com/s/livvic/v8/rnCq-x1S2hzjrlfft8cutV3D.woff2',
  },
  {
    name: 'Lobster Two',
    regular: 'https://fonts.gstatic.com/s/lobstertwo/v13/BngMUXZGTXPUvIoyV6yN5-fN5qU.woff2',
    bold: 'https://fonts.gstatic.com/s/lobstertwo/v13/BngRUXZGTXPUvIoyV6yN5-92w7CGwR0.woff2',
  },
  {
    name: 'Lora',
    regular: 'https://fonts.gstatic.com/s/lora/v17/0QI6MX1D_JOuGQbT0gvTJPa787weuxJBkq0.woff2',
    bold: 'https://fonts.gstatic.com/s/lora/v17/0QI6MX1D_JOuGQbT0gvTJPa787zAvBJBkq0.woff2',
  },
  {
    name: 'Lusitana',
    regular: 'https://fonts.gstatic.com/s/lusitana/v8/CSR84z9ShvucWzsMKyhdTOI.woff2',
    bold: 'https://fonts.gstatic.com/s/lusitana/v8/CSR74z9ShvucWzsMKyDmafctaNY.woff2',
  },
  {
    name: 'M PLUS 1p',
    regular:
      'https://fonts.gstatic.com/s/mplus1p/v19/e3tjeuShHdiFyPFzBRro_VYUcXm4y4YtjOJGYMp5iAw4B3f5iUc.119.woff2',
    bold: 'https://fonts.gstatic.com/s/mplus1p/v19/e3tmeuShHdiFyPFzBRrQRBEgfivGoOYmg_dUa_BuiDU9F33s7CtHVU4.119.woff2',
  },
  {
    name: 'M PLUS Rounded 1c',
    regular:
      'https://fonts.gstatic.com/s/mplusrounded1c/v10/VdGEAYIAV6gnpUpoWwNkYvrugw9RuPWDpq8A_4TPv30lLyDK_FIhT0K-oTdprA.119.woff2',
    bold: 'https://fonts.gstatic.com/s/mplusrounded1c/v10/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM064ZsPrfqk33YqOjLBxkUhdkeuqyIMwGYkDA.119.woff2',
  },
  {
    name: 'Mada',
    regular: 'https://fonts.gstatic.com/s/mada/v11/7Auwp_0qnzeSfTLGLQ.woff2',
    bold: 'https://fonts.gstatic.com/s/mada/v11/7Au_p_0qnzeSde3iOCX2zw.woff2',
  },
  {
    name: 'Magra',
    regular: 'https://fonts.gstatic.com/s/magra/v9/uK_94ruaZus72n52KjI.woff2',
    bold: 'https://fonts.gstatic.com/s/magra/v9/uK_w4ruaZus72nbNDycQGvo.woff2',
  },
  {
    name: 'Maitree',
    regular: 'https://fonts.gstatic.com/s/maitree/v5/MjQGmil5tffhpBrknt6sfQ.woff2',
    bold: 'https://fonts.gstatic.com/s/maitree/v5/MjQDmil5tffhpBrklgGIaJaivQ.woff2',
  },
  {
    name: 'Mali',
    regular: 'https://fonts.gstatic.com/s/mali/v4/N0ba2SRONuN4SC3EDw.woff2',
    bold: 'https://fonts.gstatic.com/s/mali/v4/N0bV2SRONuN4QPLgGlNQJA.woff2',
  },
  {
    name: 'Manjari',
    regular: 'https://fonts.gstatic.com/s/manjari/v4/k3kQo8UPMOBO2w1UfdnoLg.woff2',
    bold: 'https://fonts.gstatic.com/s/manjari/v4/k3kVo8UPMOBO2w1UdWLNO0bhLA.woff2',
  },
  {
    name: 'Manrope',
    regular: 'https://fonts.gstatic.com/s/manrope/v4/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk79FN_C-bk.woff2',
    bold: 'https://fonts.gstatic.com/s/manrope/v4/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4jE9_C-bk.woff2',
  },
  {
    name: 'Manuale',
    regular:
      'https://fonts.gstatic.com/s/manuale/v15/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeHke4wE3zE.woff2',
    bold: 'https://fonts.gstatic.com/s/manuale/v15/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeE6fIwE3zE.woff2',
  },
  {
    name: 'Markazi Text',
    regular:
      'https://fonts.gstatic.com/s/markazitext/v16/sykh-ydym6AtQaiEtX7yhqb_rV1k_81ZVYYZtfSQf4QvBA.woff2',
    bold: 'https://fonts.gstatic.com/s/markazitext/v16/sykh-ydym6AtQaiEtX7yhqb_rV1k_81ZVYYZtSqXf4QvBA.woff2',
  },
  {
    name: 'Martel',
    regular: 'https://fonts.gstatic.com/s/martel/v5/PN_xRfK9oXHga0XdZsg_.woff2',
    bold: 'https://fonts.gstatic.com/s/martel/v5/PN_yRfK9oXHga0XVuewqghzW.woff2',
  },
  {
    name: 'Martel Sans',
    regular: 'https://fonts.gstatic.com/s/martelsans/v7/h0GsssGi7VdzDgKjM-4d8hjYx-4.woff2',
    bold: 'https://fonts.gstatic.com/s/martelsans/v7/h0GxssGi7VdzDgKjM-4d8hAH4_uAH0g.woff2',
  },
  {
    name: 'Marvel',
    regular: 'https://fonts.gstatic.com/s/marvel/v10/nwpVtKeoNgBV0qa4llTF.woff2',
    bold: 'https://fonts.gstatic.com/s/marvel/v10/nwpWtKeoNgBV0qawLXHQAF-n.woff2',
  },
  {
    name: 'Maven Pro',
    regular:
      'https://fonts.gstatic.com/s/mavenpro/v22/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8SX21nejog.woff2',
    bold: 'https://fonts.gstatic.com/s/mavenpro/v22/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8fvx1nejog.woff2',
  },
  {
    name: 'Merienda',
    regular: 'https://fonts.gstatic.com/s/merienda/v9/gNMHW3x8Qoy5_mf8uWMFMIo.woff2',
    bold: 'https://fonts.gstatic.com/s/merienda/v9/gNMAW3x8Qoy5_mf8uWu-FZ-13MY.woff2',
  },
  {
    name: 'Merriweather',
    regular: 'https://fonts.gstatic.com/s/merriweather/v25/u-440qyriQwlOrhSvowK_l5-fCZM.woff2',
    bold: 'https://fonts.gstatic.com/s/merriweather/v25/u-4n0qyriQwlOrhSvowK_l52xwNZWMf6.woff2',
  },
  {
    name: 'Merriweather Sans',
    regular:
      'https://fonts.gstatic.com/s/merriweathersans/v14/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZou4ViesH.woff2',
    bold: 'https://fonts.gstatic.com/s/merriweathersans/v14/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZfOkViesH.woff2',
  },
  {
    name: 'Mina',
    regular: 'https://fonts.gstatic.com/s/mina/v6/-nFzOGc18vARnzhp7w.woff2',
    bold: 'https://fonts.gstatic.com/s/mina/v6/-nF8OGc18vARl4NM-iLT9g.woff2',
  },
  {
    name: 'Miriam Libre',
    regular: 'https://fonts.gstatic.com/s/miriamlibre/v7/DdTh798HsHwubBAqfkcBTL_fZ5P-.woff2',
    bold: 'https://fonts.gstatic.com/s/miriamlibre/v7/DdT-798HsHwubBAqfkcBTL_X3LbrQsq6.woff2',
  },
  {
    name: 'Mirza',
    regular: 'https://fonts.gstatic.com/s/mirza/v10/co3ImWlikiN5EtraIsY.woff2',
    bold: 'https://fonts.gstatic.com/s/mirza/v10/co3FmWlikiN5EtIFBtO_k6Y.woff2',
  },
  {
    name: 'Mitr',
    regular: 'https://fonts.gstatic.com/s/mitr/v6/pxiLypw5ucZF-Tw4MQ.woff2',
    bold: 'https://fonts.gstatic.com/s/mitr/v6/pxiEypw5ucZF8eMcJJfecg.woff2',
  },
  {
    name: 'Monda',
    regular: 'https://fonts.gstatic.com/s/monda/v11/TK3tWkYFABsmjsphPho.woff2',
    bold: 'https://fonts.gstatic.com/s/monda/v11/TK3gWkYFABsmjsLaGw8Eneo.woff2',
  },
  {
    name: 'Montserrat',
    regular: 'https://fonts.gstatic.com/s/montserrat/v18/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2',
    bold: 'https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_bZF3gnD_g.woff2',
  },
  {
    name: 'Montserrat Alternates',
    regular:
      'https://fonts.gstatic.com/s/montserratalternates/v12/mFTvWacfw6zH4dthXcyms1lPpC8I_b0juU0566fQ.woff2',
    bold: 'https://fonts.gstatic.com/s/montserratalternates/v12/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xNIPFB7xG.woff2',
  },
  {
    name: 'Montserrat Subrayada',
    regular:
      'https://fonts.gstatic.com/s/montserratsubrayada/v12/U9MD6c-o9H7PgjlTHThBnNHGVUORwteQQH8MaOY.woff2',
    bold: 'https://fonts.gstatic.com/s/montserratsubrayada/v12/U9MM6c-o9H7PgjlTHThBnNHGVUORwteQQHe3TfMRiXk.woff2',
  },
  {
    name: 'Mountains of Christmas',
    regular:
      'https://fonts.gstatic.com/s/mountainsofchristmas/v13/3y9w6a4zcCnn5X0FDyrKi2ZRUBIy8uxoUo7eDNGsMQ.woff2',
    bold: 'https://fonts.gstatic.com/s/mountainsofchristmas/v13/3y9z6a4zcCnn5X0FDyrKi2ZRUBIy8uxoUo7eBGqJJPxIOw.woff2',
  },
  {
    name: 'Mukta',
    regular: 'https://fonts.gstatic.com/s/mukta/v8/iJWKBXyXfDDVXbnBrXw.woff2',
    bold: 'https://fonts.gstatic.com/s/mukta/v8/iJWHBXyXfDDVXbEeiWmd8WA.woff2',
  },
  {
    name: 'Mukta Mahee',
    regular: 'https://fonts.gstatic.com/s/muktamahee/v10/XRXQ3IOIi0hcP8iVU67hA9vKUT8.woff2',
    bold: 'https://fonts.gstatic.com/s/muktamahee/v10/XRXN3IOIi0hcP8iVU67hA9MVdSoALHY.woff2',
  },
  {
    name: 'Mukta Malar',
    regular: 'https://fonts.gstatic.com/s/muktamalar/v7/MCoXzAXyz8LOE2FpJMxZqIv_J_U.woff2',
    bold: 'https://fonts.gstatic.com/s/muktamalar/v7/MCoKzAXyz8LOE2FpJMxZqIMgA-AG4Ws.woff2',
  },
  {
    name: 'Mukta Vaani',
    regular: 'https://fonts.gstatic.com/s/muktavaani/v8/3Jn5SD_-ynaxmxnEfVHPIG0CduU.woff2',
    bold: 'https://fonts.gstatic.com/s/muktavaani/v8/3JnkSD_-ynaxmxnEfVHPIGXdUvBE8O4.woff2',
  },
  {
    name: 'Mulish',
    regular: 'https://fonts.gstatic.com/s/mulish/v5/1Ptyg83HX_SGhgqO0yLcmjzUAuWexZNR8aevGw.woff2',
    bold: 'https://fonts.gstatic.com/s/mulish/v5/1Ptyg83HX_SGhgqO0yLcmjzUAuWexU1W8aevGw.woff2',
  },
  {
    name: 'MuseoModerno',
    regular:
      'https://fonts.gstatic.com/s/museomoderno/v7/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMlZEiesQjA.woff2',
    bold: 'https://fonts.gstatic.com/s/museomoderno/v7/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMohDiesQjA.woff2',
  },
  {
    name: 'Nanum Gothic',
    regular:
      'https://fonts.gstatic.com/s/nanumgothic/v17/PN_3Rfi-oW3hYwmKDpxS7F_z-7rJxHVIsPV5MbNO2rV2_va-Nv6p.119.woff2',
    bold: 'https://fonts.gstatic.com/s/nanumgothic/v17/PN_oRfi-oW3hYwmKDpxS7F_LQv37yzkx1Yl3P690375P--a0I5vFgZwP.119.woff2',
  },
  {
    name: 'Nanum Gothic Coding',
    regular:
      'https://fonts.gstatic.com/s/nanumgothiccoding/v14/8QIVdjzHisX_8vv59_xMxtPFW4IXROwsy6FZQM9Ynbkmi96slCI8cUV15P99pg.119.woff2',
    bold: 'https://fonts.gstatic.com/s/nanumgothiccoding/v14/8QIYdjzHisX_8vv59_xMxtPFW4IXROws8xgecsAU5NxahdCwric3SEBl7uoYyrp7XQ.119.woff2',
  },
  {
    name: 'Nanum Myeongjo',
    regular:
      'https://fonts.gstatic.com/s/nanummyeongjo/v15/9Btx3DZF0dXLMZlywRbVRNhxy1LuEGI-gZ_Ll9dMHVruCTvHYAnNT2g.119.woff2',
    bold: 'https://fonts.gstatic.com/s/nanummyeongjo/v15/9Bty3DZF0dXLMZlywRbVRNhxy2pXV1Axzeau69lCAWDrAgLCcAPYKgRK4K8.119.woff2',
  },
  {
    name: 'Neuton',
    regular: 'https://fonts.gstatic.com/s/neuton/v13/UMBTrPtMoH62xUZCz4g6.woff2',
    bold: 'https://fonts.gstatic.com/s/neuton/v13/UMBQrPtMoH62xUZKdK0vfQr4.woff2',
  },
  {
    name: 'News Cycle',
    regular: 'https://fonts.gstatic.com/s/newscycle/v17/CSR64z1Qlv-GDxkbKVQ_fOAKTQ.woff2',
    bold: 'https://fonts.gstatic.com/s/newscycle/v17/CSR54z1Qlv-GDxkbKVQ_dFsvWNReuQ.woff2',
  },
  {
    name: 'Newsreader',
    regular:
      'https://fonts.gstatic.com/s/newsreader/v7/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438weI_wC-ZF.woff2',
    bold: 'https://fonts.gstatic.com/s/newsreader/v7/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438wpojwC-ZF.woff2',
  },
  {
    name: 'Niramit',
    regular: 'https://fonts.gstatic.com/s/niramit/v5/I_uuMpWdvgLdNxVLXbNakw.woff2',
    bold: 'https://fonts.gstatic.com/s/niramit/v5/I_urMpWdvgLdNxVLVWx-hi-Org.woff2',
  },
  {
    name: 'Nobile',
    regular: 'https://fonts.gstatic.com/s/nobile/v12/m8JTjflSeaOVl1iGWa3W.woff2',
    bold: 'https://fonts.gstatic.com/s/nobile/v12/m8JQjflSeaOVl1iO4ojDd5RA.woff2',
  },
  {
    name: 'Nokora',
    regular: 'https://fonts.gstatic.com/s/nokora/v16/hYkIPuwgTubzaWxgNzAO.woff2',
    bold: 'https://fonts.gstatic.com/s/nokora/v16/hYkLPuwgTubzaWxohxUbptd9.woff2',
  },
  {
    name: 'Noticia Text',
    regular: 'https://fonts.gstatic.com/s/noticiatext/v10/VuJ2dNDF2Yv9qppOePKYRP12ZjtY.woff2',
    bold: 'https://fonts.gstatic.com/s/noticiatext/v10/VuJpdNDF2Yv9qppOePKYRP1-3R5NuGvQ.woff2',
  },
  {
    name: 'Noto Kufi Arabic',
    regular:
      'https://fonts.gstatic.com/s/notokufiarabic/v8/CSRp4ydQnPyaDxEXLFF6LZVLKrodhu8t57o1kDc5Wh5v37bNlrU.woff2',
    bold: 'https://fonts.gstatic.com/s/notokufiarabic/v8/CSRp4ydQnPyaDxEXLFF6LZVLKrodhu8t57o1kDc5Wh6x2LbNlrU.woff2',
  },
  {
    name: 'Noto Naskh Arabic',
    regular:
      'https://fonts.gstatic.com/s/notonaskharabic/v10/RrQ5bpV-9Dd1b1OAGA6M9PkyDuVBePeKNaxcsss0Y7bwvc5Urqcy.woff2',
    bold: 'https://fonts.gstatic.com/s/notonaskharabic/v10/RrQ5bpV-9Dd1b1OAGA6M9PkyDuVBePeKNaxcsss0Y7bwY8lUrqcy.woff2',
  },
  {
    name: 'Noto Nastaliq Urdu',
    regular:
      'https://fonts.gstatic.com/s/notonastaliqurdu/v8/LhW4MUPbN-oZdNFcBy1-DJYsEoTq5pu3SvoM.woff2',
    bold: 'https://fonts.gstatic.com/s/notonastaliqurdu/v8/LhW7MUPbN-oZdNFcBy1-DJYsEoTq5pu_9N8ZMff4.woff2',
  },
  {
    name: 'Noto Rashi Hebrew',
    regular:
      'https://fonts.gstatic.com/s/notorashihebrew/v10/EJR_Qh82XsIK-QFmqXk4zvLwFVya0vFL-HlKM5e6C6HZB-HUQiC7.woff2',
    bold: 'https://fonts.gstatic.com/s/notorashihebrew/v10/EJR_Qh82XsIK-QFmqXk4zvLwFVya0vFL-HlKM5e6C6HZ2ebUQiC7.woff2',
  },
  {
    name: 'Noto Sans',
    regular: 'https://fonts.gstatic.com/s/notosans/v21/o-0IIpQlx3QUlC5A4PNr5TRA.woff2',
    bold: 'https://fonts.gstatic.com/s/notosans/v21/o-0NIpQlx3QUlC5A4PNjXhFVZNyB.woff2',
  },
  {
    name: 'Noto Sans Adlam',
    regular:
      'https://fonts.gstatic.com/s/notosansadlam/v10/neIczCCpqp0s5pPusPamd81eMfjPonvqdbYxxpgufnv0fAzLZQ.woff2',
    bold: 'https://fonts.gstatic.com/s/notosansadlam/v10/neIczCCpqp0s5pPusPamd81eMfjPonvqdbYxxpgufqXzfAzLZQ.woff2',
  },
  {
    name: 'Noto Sans Adlam Unjoined',
    regular:
      'https://fonts.gstatic.com/s/notosansadlamunjoined/v10/P5sszY2MYsLRsB5_ildkzPPDsLQXcOEmaFOqOGcaYrzFTIjsPam_Ye35DKQU-Q.woff2',
    bold: 'https://fonts.gstatic.com/s/notosansadlamunjoined/v10/P5sszY2MYsLRsB5_ildkzPPDsLQXcOEmaFOqOGcaYrzFTIjsPam_YTP-DKQU-Q.woff2',
  },
  {
    name: 'Noto Sans Arabic',
    regular:
      'https://fonts.gstatic.com/s/notosansarabic/v10/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfyGyfuXqA.woff2',
    bold: 'https://fonts.gstatic.com/s/notosansarabic/v10/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfFmufuXqA.woff2',
  },
  {
    name: 'Noto Sans Armenian',
    regular:
      'https://fonts.gstatic.com/s/notosansarmenian/v25/ZgN0jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idb74R9UdM3y2nZLorxb501aS8.woff2',
    bold: 'https://fonts.gstatic.com/s/notosansarmenian/v25/ZgN0jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idb74R9UdM3y2nZLoovaJ01aS8.woff2',
  },
  {
    name: 'Noto Sans Balinese',
    regular:
      'https://fonts.gstatic.com/s/notosansbalinese/v10/NaPwcYvSBuhTirw6IaFn6UrRDaqje-lpbbRtYf-Fwu2Ov7fdtG5fdg.woff2',
    bold: 'https://fonts.gstatic.com/s/notosansbalinese/v10/NaPwcYvSBuhTirw6IaFn6UrRDaqje-lpbbRtYf-Fwu2Ov2natG5fdg.woff2',
  },
  {
    name: 'Noto Sans Bamum',
    regular:
      'https://fonts.gstatic.com/s/notosansbamum/v11/uk-0EGK3o6EruUbnwovcbBTkkklK_Ya_PBHfNGTPEddOy6wByw.woff2',
    bold: 'https://fonts.gstatic.com/s/notosansbamum/v11/uk-0EGK3o6EruUbnwovcbBTkkklK_Ya_PBHfNGTPEQlJy6wByw.woff2',
  },
  {
    name: 'Noto Sans Bengali',
    regular:
      'https://fonts.gstatic.com/s/notosansbengali/v10/Cn-SJsCGWQxOjaGwMQ6fIiMywrNJIky6nvd8BjzVMvJx2mcSPVFpVEqE-6KmsolLicWu8w.woff2',
    bold: 'https://fonts.gstatic.com/s/notosansbengali/v10/Cn-SJsCGWQxOjaGwMQ6fIiMywrNJIky6nvd8BjzVMvJx2mcSPVFpVEqE-6KmsldMicWu8w.woff2',
  },
  {
    name: 'Noto Sans Canadian Aboriginal',
    regular:
      'https://fonts.gstatic.com/s/notosanscanadianaboriginal/v10/4C_TLjTuEqPj-8J01CwaGkiZ9os0iGVkezM1mUT-j_Lmlzda6uH_nnX1bzigWLnP4AEh.woff2',
    bold: 'https://fonts.gstatic.com/s/notosanscanadianaboriginal/v10/4C_TLjTuEqPj-8J01CwaGkiZ9os0iGVkezM1mUT-j_Lmlzda6uH_nnX1bzighr7P4AEh.woff2',
  },
  {
    name: 'Noto Sans Cham',
    regular:
      'https://fonts.gstatic.com/s/notosanscham/v10/pe06MIySN5pO62Z5YkFyQb_bbuRhe6D4yip43qfcERwsi7uz.woff2',
    bold: 'https://fonts.gstatic.com/s/notosanscham/v10/pe06MIySN5pO62Z5YkFyQb_bbuRhe6D4yip43qfczxssi7uz.woff2',
  },
  {
    name: 'Noto Sans Cherokee',
    regular:
      'https://fonts.gstatic.com/s/notosanscherokee/v10/KFOPCm6Yu8uF-29fiz9vQF9YWK6Z8O10cHNA0cSkZCHYWi5PPl2zrQ.woff2',
    bold: 'https://fonts.gstatic.com/s/notosanscherokee/v10/KFOPCm6Yu8uF-29fiz9vQF9YWK6Z8O10cHNA0cSkZCHYWvBIPl2zrQ.woff2',
  },
  {
    name: 'Noto Sans Devanagari',
    regular:
      'https://fonts.gstatic.com/s/notosansdevanagari/v10/TuGOUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv1lXfxk.woff2',
    bold: 'https://fonts.gstatic.com/s/notosansdevanagari/v10/TuGBUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv1GJWwxjKTM.woff2',
  },
  {
    name: 'Noto Sans Display',
    regular:
      'https://fonts.gstatic.com/s/notosansdisplay/v10/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp_3cKZTaa2w.woff2',
    bold: 'https://fonts.gstatic.com/s/notosansdisplay/v10/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp_6kNZTaa2w.woff2',
  },
  {
    name: 'Noto Sans Georgian',
    regular:
      'https://fonts.gstatic.com/s/notosansgeorgian/v25/PlIaFke5O6RzLfvNNVSitxkr76PRHBC4Ytyq-Gof7PUs4S7zWn-8YDB09HFNdpvnzGjmdZQ.woff2',
    bold: 'https://fonts.gstatic.com/s/notosansgeorgian/v25/PlIaFke5O6RzLfvNNVSitxkr76PRHBC4Ytyq-Gof7PUs4S7zWn-8YDB09HFNdps5y2jmdZQ.woff2',
  },
  {
    name: 'Noto Sans Gujarati',
    regular:
      'https://fonts.gstatic.com/s/notosansgujarati/v9/wlpsgx_HC1ti5ViekvcxnhMlCVo3f5pFwJBC.woff2',
    bold: 'https://fonts.gstatic.com/s/notosansgujarati/v9/wlpvgx_HC1ti5ViekvcxnhMlCVo3f5pNBLRXY4qq.woff2',
  },
  {
    name: 'Noto Sans Gurmukhi',
    regular:
      'https://fonts.gstatic.com/s/notosansgurmukhi/v10/w8g9H3EvQP81sInb43inmyN9zZ7hb7ATbSWo4q8dJ74a3cVrYFQ_bogT0-gPeG1OekbVbfo.woff2',
    bold: 'https://fonts.gstatic.com/s/notosansgurmukhi/v10/w8g9H3EvQP81sInb43inmyN9zZ7hb7ATbSWo4q8dJ74a3cVrYFQ_bogT0-gPeG2QfUbVbfo.woff2',
  },
  {
    name: 'Noto Sans HK',
    regular:
      'https://fonts.gstatic.com/s/notosanshk/v15/nKKQ-GM_FYFRJvXzVXaAPe9hMRhvEPeWV8Ydw9dTvaja5emiwCL1Dv89rTx5gtGuOA.119.woff2',
    bold: 'https://fonts.gstatic.com/s/notosanshk/v15/nKKP-GM_FYFRJvXzVXaAPe9ZiF9TH63iMrASx9ZaserX6q2vzTj-NOg9lDlpiMTLVMI0Og.119.woff2',
  },
  {
    name: 'Noto Sans Hanifi Rohingya',
    regular:
      'https://fonts.gstatic.com/s/notosanshanifirohingya/v9/5h17iYsoOmIC3Yu3MDXLDw3UZCgghyOEBBY7hhLNyo3tiaiuSIAqrIYY4g7At8o.woff2',
    bold: 'https://fonts.gstatic.com/s/notosanshanifirohingya/v9/5h17iYsoOmIC3Yu3MDXLDw3UZCgghyOEBBY7hhLNyo3tiaiuSIAqrIbG5Q7At8o.woff2',
  },
  {
    name: 'Noto Sans Hebrew',
    regular:
      'https://fonts.gstatic.com/s/notosanshebrew/v27/or3HQ7v33eiDljA1IufXTtVf7V6RvEEdhQlk0LlGxCyaeNKYZC0sqk3xXGiXd4qdpyhk.woff2',
    bold: 'https://fonts.gstatic.com/s/notosanshebrew/v27/or3HQ7v33eiDljA1IufXTtVf7V6RvEEdhQlk0LlGxCyaeNKYZC0sqk3xXGiXqY2dpyhk.woff2',
  },
  {
    name: 'Noto Sans JP',
    regular:
      'https://fonts.gstatic.com/s/notosansjp/v36/-F62fjtqLzI2JPCgQBnw7HFow2oe2EcP5pp0erwTqsSWs9Jezazjcb4.119.woff2',
    bold: 'https://fonts.gstatic.com/s/notosansjp/v36/-F6pfjtqLzI2JPCgQBnw7HFQei0q1xVxjfp_dakBof6Bs-tb3ab2FNISVac.119.woff2',
  },
  {
    name: 'Noto Sans Javanese',
    regular:
      'https://fonts.gstatic.com/s/notosansjavanese/v9/2V0AKJkDAIA6Hp4zoSScDjV0Y-eoHAH507U9.woff2',
    bold: 'https://fonts.gstatic.com/s/notosansjavanese/v9/2V0DKJkDAIA6Hp4zoSScDjV0Y-eoHAHxTpAob7yg.woff2',
  },
  {
    name: 'Noto Sans KR',
    regular:
      'https://fonts.gstatic.com/s/notosanskr/v21/PbykFmXiEBPT4ITbgNA5Cgm20xz64px_1hVWr0wuPNGmlQNMEfD4.119.woff2',
    bold: 'https://fonts.gstatic.com/s/notosanskr/v21/Pby7FmXiEBPT4ITbgNA5CgmOalvI7dAGs2lYoVAUOdqfkBNGBJWUFERI.119.woff2',
  },
  {
    name: 'Noto Sans Kannada',
    regular:
      'https://fonts.gstatic.com/s/notosanskannada/v9/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrDvN_T8GLw.woff2',
    bold: 'https://fonts.gstatic.com/s/notosanskannada/v9/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrOXK_T8GLw.woff2',
  },
  {
    name: 'Noto Sans Kayah Li',
    regular:
      'https://fonts.gstatic.com/s/notosanskayahli/v9/B50nF61OpWTRcGrhOVJJwOMXdca6Yecki3E06x2jVTX3WCc3Od7yEA.woff2',
    bold: 'https://fonts.gstatic.com/s/notosanskayahli/v9/B50nF61OpWTRcGrhOVJJwOMXdca6Yecki3E06x2jVTX3WPkwOd7yEA.woff2',
  },
  {
    name: 'Noto Sans Khmer',
    regular:
      'https://fonts.gstatic.com/s/notosanskhmer/v9/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYuNAKz0mgc.woff2',
    bold: 'https://fonts.gstatic.com/s/notosanskhmer/v9/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYtTB6z0mgc.woff2',
  },
  {
    name: 'Noto Sans Lao',
    regular:
      'https://fonts.gstatic.com/s/notosanslao/v13/bx6lNx2Ol_ixgdYWLm9BwxM3NW6BOkuf763Clj73CiQ_J1Djx9pidOt4ccbtcZkL.woff2',
    bold: 'https://fonts.gstatic.com/s/notosanslao/v13/bx6lNx2Ol_ixgdYWLm9BwxM3NW6BOkuf763Clj73CiQ_J1Djx9pidOt4r8HtcZkL.woff2',
  },
  {
    name: 'Noto Sans Lisu',
    regular:
      'https://fonts.gstatic.com/s/notosanslisu/v10/uk-3EGO3o6EruUbnwovcYhz6kh57_nqbcTdjJnHP2Vwdmtgk.woff2',
    bold: 'https://fonts.gstatic.com/s/notosanslisu/v10/uk-3EGO3o6EruUbnwovcYhz6kh57_nqbcTdjJnHPB1sdmtgk.woff2',
  },
  {
    name: 'Noto Sans Malayalam',
    regular:
      'https://fonts.gstatic.com/s/notosansmalayalam/v10/sJoi3K5XjsSdcnzn071rL37lpAOsUThnDZIfPdbeSNzVakglNM-Qw8EaeB8Nss-_RuDNG1bF.woff2',
    bold: 'https://fonts.gstatic.com/s/notosansmalayalam/v10/sJoi3K5XjsSdcnzn071rL37lpAOsUThnDZIfPdbeSNzVakglNM-Qw8EaeB8Nss-_mOfNG1bF.woff2',
  },
  {
    name: 'Noto Sans Medefaidrin',
    regular:
      'https://fonts.gstatic.com/s/notosansmedefaidrin/v10/WwkzxOq6Dk-wranENynkfeVsNbRZtbOIdLb1exeM4ZeuabBfmErWpave1eM.woff2',
    bold: 'https://fonts.gstatic.com/s/notosansmedefaidrin/v10/WwkzxOq6Dk-wranENynkfeVsNbRZtbOIdLb1exeM4ZeuabBfmJTRpave1eM.woff2',
  },
  {
    name: 'Noto Sans Mono',
    regular:
      'https://fonts.gstatic.com/s/notosansmono/v11/BngrUXNETWXI6LwhGYvaxZikqZqK6fBq6kPvUce2oAZcdthSBUsYck4-_FNJ093dVQ.woff2',
    bold: 'https://fonts.gstatic.com/s/notosansmono/v11/BngrUXNETWXI6LwhGYvaxZikqZqK6fBq6kPvUce2oAZcdthSBUsYck4-_I1O093dVQ.woff2',
  },
  {
    name: 'Noto Sans Myanmar',
    regular:
      'https://fonts.gstatic.com/s/notosansmyanmar/v14/AlZq_y1ZtY3ymOryg38hOCSdOnFq0Hnv1uQ.woff2',
    bold: 'https://fonts.gstatic.com/s/notosansmyanmar/v14/AlZv_y1ZtY3ymOryg38hOCSdOnFq0HEu8vEpgk0.woff2',
  },
  {
    name: 'Noto Sans Ol Chiki',
    regular:
      'https://fonts.gstatic.com/s/notosansolchiki/v10/N0b92TJNOPt-eHmFZCdQbrL32r-4CvhzDzRwlxOQYuVALWk22_YwgA.woff2',
    bold: 'https://fonts.gstatic.com/s/notosansolchiki/v10/N0b92TJNOPt-eHmFZCdQbrL32r-4CvhzDzRwlxOQYuVALbcx2_YwgA.woff2',
  },
  {
    name: 'Noto Sans Oriya',
    regular: 'https://fonts.gstatic.com/s/notosansoriya/v9/AYCTpXfzfccDCstK_hrjDyADv5eX-qfC.woff2',
    bold: 'https://fonts.gstatic.com/s/notosansoriya/v9/AYCWpXfzfccDCstK_hrjDyADv5efWILXcIx8.woff2',
  },
  {
    name: 'Noto Sans SC',
    regular:
      'https://fonts.gstatic.com/s/notosanssc/v20/k3kXo84MPvpLmixcA63oeALhLOCT-xWNm8Hqd37g1OkDRZe7lR4sg1IzSy-MNbE9VH8V.119.woff2',
    bold: 'https://fonts.gstatic.com/s/notosanssc/v20/k3kIo84MPvpLmixcA63oeALZlaet9E3g-Lbpcnr20eUBAZG30RMhmVkJXC-1MKE3QRp5zkww.119.woff2',
  },
  {
    name: 'Noto Sans Sinhala',
    regular:
      'https://fonts.gstatic.com/s/notosanssinhala/v17/yMJ2MJBya43H0SUF_WmcBEEf4rQVO2P524V5N_MxQzQtb-tf5dJbC30Fu9zUwg2a1kIBpQ.woff2',
    bold: 'https://fonts.gstatic.com/s/notosanssinhala/v17/yMJ2MJBya43H0SUF_WmcBEEf4rQVO2P524V5N_MxQzQtb-tf5dJbC30Fu9zUwtOd1kIBpQ.woff2',
  },
  {
    name: 'Noto Sans Sora Sompeng',
    regular:
      'https://fonts.gstatic.com/s/notosanssorasompeng/v10/PlIRFkO5O6RzLfvNNVSioxM2_OTrEhPyDLolKvCsHzCxWuGkYHR81_CDb3M.woff2',
    bold: 'https://fonts.gstatic.com/s/notosanssorasompeng/v10/PlIRFkO5O6RzLfvNNVSioxM2_OTrEhPyDLolKvCsHzCxWuGkYHSi0PCDb3M.woff2',
  },
  {
    name: 'Noto Sans Sundanese',
    regular:
      'https://fonts.gstatic.com/s/notosanssundanese/v10/FwZw7_84xUkosG2xJo2gm7nFwSLQkdymq2mkz3Gz1_b6ctxpNOH4gTo.woff2',
    bold: 'https://fonts.gstatic.com/s/notosanssundanese/v10/FwZw7_84xUkosG2xJo2gm7nFwSLQkdymq2mkz3Gz1_b6cty3M-H4gTo.woff2',
  },
  {
    name: 'Noto Sans Symbols',
    regular:
      'https://fonts.gstatic.com/s/notosanssymbols/v25/rP2up3q65FkAtHfwd-eIS2brbDN6gxP34F9jRRCe4W3gfQ8QA_9E.woff2',
    bold: 'https://fonts.gstatic.com/s/notosanssymbols/v25/rP2up3q65FkAtHfwd-eIS2brbDN6gxP34F9jRRCe4W3gowgQA_9E.woff2',
  },
  {
    name: 'Noto Sans TC',
    regular:
      'https://fonts.gstatic.com/s/notosanstc/v20/-nF7OG829Oofr2wohFbTp9iFOkMQAewlpbGXhhyYs0QF3kPVyLylzU95vTq1Ltj5xQez1g.119.woff2',
    bold: 'https://fonts.gstatic.com/s/notosanstc/v20/-nFkOG829Oofr2wohFbTp9i9gwQvDrVO3cuHnQmVtV4N3QDXzPiowFVyhy21F93pzxLWunL8lQ.119.woff2',
  },
  {
    name: 'Noto Sans Tai Tham',
    regular:
      'https://fonts.gstatic.com/s/notosanstaitham/v10/kJEbBv0U4hgtwxDUw2x9q7tbjLIfbPGHBoaVSAZ3MdLJBCUbDkakyA.woff2',
    bold: 'https://fonts.gstatic.com/s/notosanstaitham/v10/kJEbBv0U4hgtwxDUw2x9q7tbjLIfbPGHBoaVSAZ3MdLJBPscDkakyA.woff2',
  },
  {
    name: 'Noto Sans Tamil',
    regular:
      'https://fonts.gstatic.com/s/notosanstamil/v10/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7vGo40AoqE.woff2',
    bold: 'https://fonts.gstatic.com/s/notosanstamil/v10/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7sYpI0AoqE.woff2',
  },
  {
    name: 'Noto Sans Telugu',
    regular:
      'https://fonts.gstatic.com/s/notosanstelugu/v9/0FlxVOGZlE2Rrtr-HmgkMWJNjJ5_RyT8o8c7fHkeg-esVC5dzHkHIJQqrEntezbaWkze.woff2',
    bold: 'https://fonts.gstatic.com/s/notosanstelugu/v9/0FlxVOGZlE2Rrtr-HmgkMWJNjJ5_RyT8o8c7fHkeg-esVC5dzHkHIJQqrEntpTHaWkze.woff2',
  },
  {
    name: 'Noto Sans Thaana',
    regular:
      'https://fonts.gstatic.com/s/notosansthaana/v9/C8c14dM-vnz-s-3jaEsxlxHkBH-WZOETXfoQrfQ9Y4XrbiLElO8.woff2',
    bold: 'https://fonts.gstatic.com/s/notosansthaana/v9/C8c14dM-vnz-s-3jaEsxlxHkBH-WZOETXfoQrfQ9Y4U1aSLElO8.woff2',
  },
  {
    name: 'Noto Sans Thai',
    regular:
      'https://fonts.gstatic.com/s/notosansthai/v10/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU5RtlyJ0QA.woff2',
    bold: 'https://fonts.gstatic.com/s/notosansthai/v10/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU0pqlyJ0QA.woff2',
  },
  {
    name: 'Noto Sans Thai Looped',
    regular:
      'https://fonts.gstatic.com/s/notosansthailooped/v7/B50RF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3QKKAd.woff2',
    bold: 'https://fonts.gstatic.com/s/notosansthailooped/v7/B50cF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3Y44QIQQDy.woff2',
  },
  {
    name: 'Noto Serif',
    regular: 'https://fonts.gstatic.com/s/notoserif/v16/ga6Iaw1J5X9T9RW6j9bNfFcWaA.woff2',
    bold: 'https://fonts.gstatic.com/s/notoserif/v16/ga6Law1J5X9T9RW6j9bNdOwzfReecQ.woff2',
  },
  {
    name: 'Noto Serif Armenian',
    regular:
      'https://fonts.gstatic.com/s/notoserifarmenian/v9/3XFMEqMt3YoFsciDRZxptyCUKJmytZ0kVU-XvF7QaZuL85rnQ_zDNzDe5xNnKxyZi8Kr0f_b.woff2',
    bold: 'https://fonts.gstatic.com/s/notoserifarmenian/v9/3XFMEqMt3YoFsciDRZxptyCUKJmytZ0kVU-XvF7QaZuL85rnQ_zDNzDe5xNnKxyZVcWr0f_b.woff2',
  },
  {
    name: 'Noto Serif Bengali',
    regular:
      'https://fonts.gstatic.com/s/notoserifbengali/v9/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JfcAHkqy6rk.woff2',
    bold: 'https://fonts.gstatic.com/s/notoserifbengali/v9/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JffeGUqy6rk.woff2',
  },
  {
    name: 'Noto Serif Devanagari',
    regular:
      'https://fonts.gstatic.com/s/notoserifdevanagari/v9/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTA-oz-BO0Y.woff2',
    bold: 'https://fonts.gstatic.com/s/notoserifdevanagari/v9/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTDgpD-BO0Y.woff2',
  },
  {
    name: 'Noto Serif Display',
    regular:
      'https://fonts.gstatic.com/s/notoserifdisplay/v9/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVpd4ugNYjQ.woff2',
    bold: 'https://fonts.gstatic.com/s/notoserifdisplay/v9/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVqD5egNYjQ.woff2',
  },
  {
    name: 'Noto Serif Ethiopic',
    regular:
      'https://fonts.gstatic.com/s/notoserifethiopic/v9/V8mjoR7-XjwJ8_Au3Ti5tXj5Rd83frpWLK4d-taxqWw2HMWjDxBAg5S_0QsrggxCzSUTh6m8.woff2',
    bold: 'https://fonts.gstatic.com/s/notoserifethiopic/v9/V8mjoR7-XjwJ8_Au3Ti5tXj5Rd83frpWLK4d-taxqWw2HMWjDxBAg5S_0QsrggxCEyITh6m8.woff2',
  },
  {
    name: 'Noto Serif Georgian',
    regular:
      'https://fonts.gstatic.com/s/notoserifgeorgian/v9/VEMXRpd8s4nv8hG_qOzL7HOAw4nt0Sl_XxyaEduNMvi7T6Y4etRnmGhyLop-R3aSTvovbz7x.woff2',
    bold: 'https://fonts.gstatic.com/s/notoserifgeorgian/v9/VEMXRpd8s4nv8hG_qOzL7HOAw4nt0Sl_XxyaEduNMvi7T6Y4etRnmGhyLop-R3aSkP0vbz7x.woff2',
  },
  {
    name: 'Noto Serif Gujarati',
    regular:
      'https://fonts.gstatic.com/s/notoserifgujarati/v9/hESa6WBlOixO-3OJ1FTmTsmqlBRUJBVkcgNLpdsspzP2HuYycLzyOVM.woff2',
    bold: 'https://fonts.gstatic.com/s/notoserifgujarati/v9/hESa6WBlOixO-3OJ1FTmTsmqlBRUJBVkcgNLpdsspzP2Hubsd7zyOVM.woff2',
  },
  {
    name: 'Noto Serif Gurmukhi',
    regular:
      'https://fonts.gstatic.com/s/notoserifgurmukhi/v6/92z-tA9LNqsg7tCYlXdCV1VPnAEeDU0vLoYMbylXk0xTCr6-eSTpkKQ.woff2',
    bold: 'https://fonts.gstatic.com/s/notoserifgurmukhi/v6/92z-tA9LNqsg7tCYlXdCV1VPnAEeDU0vLoYMbylXk0xTCr5gfiTpkKQ.woff2',
  },
  {
    name: 'Noto Serif Hebrew',
    regular:
      'https://fonts.gstatic.com/s/notoserifhebrew/v9/k3k0o9MMPvpLmixYH7euCwmkS9DohjX1-kRyiqyBqIxnoLbp93i9IKrXKF_qVAwSMGo1vw.woff2',
    bold: 'https://fonts.gstatic.com/s/notoserifhebrew/v9/k3k0o9MMPvpLmixYH7euCwmkS9DohjX1-kRyiqyBqIxnoLbp93i9IKrXKF_qVNIVMGo1vw.woff2',
  },
  {
    name: 'Noto Serif JP',
    regular:
      'https://fonts.gstatic.com/s/notoserifjp/v15/xn7mYHs72GKoTvER4Gn3b5eMXNvMckQlPa61uNKcCqOfbtLXoH3502Rk.119.woff2',
    bold: 'https://fonts.gstatic.com/s/notoserifjp/v15/xn77YHs72GKoTvER4Gn3b5eMZAaKRkt3Q8XVs92JGKiledLupW3zxgEInRsa.119.woff2',
  },
  {
    name: 'Noto Serif KR',
    regular:
      'https://fonts.gstatic.com/s/notoserifkr/v15/3Jn7SDn90Gmq2mr3blnHaTZXduUBwuF9Wxop-KlAZIoTrf6uFZh_9Q.119.woff2',
    bold: 'https://fonts.gstatic.com/s/notoserifkr/v15/3JnmSDn90Gmq2mr3blnHaTZXTjhH8O4xIn9V9qdcXo8YlPu-H40amXOQLQ.119.woff2',
  },
  {
    name: 'Noto Serif Kannada',
    regular:
      'https://fonts.gstatic.com/s/notoserifkannada/v9/v6-8GZHLJFKIhClqUYqXDiWqpxQxWSPoW6bz-l4hGHiNgcYDQflD7g.woff2',
    bold: 'https://fonts.gstatic.com/s/notoserifkannada/v9/v6-8GZHLJFKIhClqUYqXDiWqpxQxWSPoW6bz-l4hGHiNgRgEQflD7g.woff2',
  },
  {
    name: 'Noto Serif Khmer',
    regular:
      'https://fonts.gstatic.com/s/notoserifkhmer/v9/-F6UfidqLzI2JPCkXAO2hmogq0146FxtbwKEr951z5s6lI40sDRH_AVhUKdN6B8AUExL.woff2',
    bold: 'https://fonts.gstatic.com/s/notoserifkhmer/v9/-F6UfidqLzI2JPCkXAO2hmogq0146FxtbwKEr951z5s6lI40sDRH_AVhUKdNNhgAUExL.woff2',
  },
  {
    name: 'Noto Serif Lao',
    regular:
      'https://fonts.gstatic.com/s/notoseriflao/v9/3y9C6bYwcCjmsU8JEzCMxEwQfEBLk3f0rlSqCdaM_LlSNZ59oNw0BWH8VeMKnv2plw.woff2',
    bold: 'https://fonts.gstatic.com/s/notoseriflao/v9/3y9C6bYwcCjmsU8JEzCMxEwQfEBLk3f0rlSqCdaM_LlSNZ59oNw0BWH8VT0Nnv2plw.woff2',
  },
  {
    name: 'Noto Serif Malayalam',
    regular:
      'https://fonts.gstatic.com/s/notoserifmalayalam/v9/JIAZUU5sdmdP_HMcVcZFcH7DeVBeGVgSMEk2cmVDq1ihUXL1t-xvglYG.woff2',
    bold: 'https://fonts.gstatic.com/s/notoserifmalayalam/v9/JIAZUU5sdmdP_HMcVcZFcH7DeVBeGVgSMEk2cmVDq1ihUXL1aetvglYG.woff2',
  },
  {
    name: 'Noto Serif Myanmar',
    regular:
      'https://fonts.gstatic.com/s/notoserifmyanmar/v7/VuJsdM7F2Yv76aBKKs-bHMQfAHUw3jnFvRDp.woff2',
    bold: 'https://fonts.gstatic.com/s/notoserifmyanmar/v7/VuJvdM7F2Yv76aBKKs-bHMQfAHUw3jnNfDT8YPH3.woff2',
  },
  {
    name: 'Noto Serif Nyiakeng Puachue Hmong',
    regular:
      'https://fonts.gstatic.com/s/notoserifnyiakengpuachuehmong/v9/5h1jibMoOmIC3YuzLC-NZyLDZC8iwh-MTC8ggAjEhePFNRVcneAFp44kcYMUkNqVKhqPPMvwQkc.woff2',
    bold: 'https://fonts.gstatic.com/s/notoserifnyiakengpuachuehmong/v9/5h1jibMoOmIC3YuzLC-NZyLDZC8iwh-MTC8ggAjEhePFNRVcneAFp44kcYMUkNqVKsSIPMvwQkc.woff2',
  },
  {
    name: 'Noto Serif SC',
    regular:
      'https://fonts.gstatic.com/s/notoserifsc/v16/H4chBXePl9DZ0Xe7gG9cyOj7oqP0dTpxZbB9E9gjjmzKvaeKHUTtJDWv3z-us4bxD8F5og.119.woff2',
    bold: 'https://fonts.gstatic.com/s/notoserifsc/v16/H4c8BXePl9DZ0Xe7gG9cyOj7mn6ySzUpCNMKEN0nmGnGv-OMEQDgKS-k5SiuioPhBdQcziZZTQ.119.woff2',
  },
  {
    name: 'Noto Serif Sinhala',
    regular:
      'https://fonts.gstatic.com/s/notoserifsinhala/v9/DtVEJwinQqclnZE2CnsPug9lgGC3y2F2nehQ7Eg4EdBKWxPiDxMivFLgRXs_-pGxR2M2z6M.woff2',
    bold: 'https://fonts.gstatic.com/s/notoserifsinhala/v9/DtVEJwinQqclnZE2CnsPug9lgGC3y2F2nehQ7Eg4EdBKWxPiDxMivFLgRXs_-pFvQGM2z6M.woff2',
  },
  {
    name: 'Noto Serif TC',
    regular:
      'https://fonts.gstatic.com/s/notoseriftc/v17/XLYgIZb5bJNDGYxLBibeHZ0BhnJsUnN3PrBufRbmGqUtcg4pzRPk5AEpzv6YzI9aTbOhf6M.119.woff2',
    bold: 'https://fonts.gstatic.com/s/notoseriftc/v17/XLY9IZb5bJNDGYxLBibeHZ0Bvq8qbXwuVcgUbQ3zF6M3eg1qzxeg6QwzxcSPzLZfXbm0Gs_7uos.119.woff2',
  },
  {
    name: 'Noto Serif Tamil',
    regular:
      'https://fonts.gstatic.com/s/notoseriftamil/v10/LYjndHr-klIgTfc40komjQ5OObazYp-6H94dBF-RX6nNRJfi-Gf55IgAecattN-h4fb2.woff2',
    bold: 'https://fonts.gstatic.com/s/notoseriftamil/v10/LYjndHr-klIgTfc40komjQ5OObazYp-6H94dBF-RX6nNRJfi-Gf55IgAecatatih4fb2.woff2',
  },
  {
    name: 'Noto Serif Telugu',
    regular:
      'https://fonts.gstatic.com/s/notoseriftelugu/v9/tDbl2pCbnkEKmXNVmt2M1q6f4HWbbj6MRbYEeav7Fe9D9TCAooee.woff2',
    bold: 'https://fonts.gstatic.com/s/notoseriftelugu/v9/tDbl2pCbnkEKmXNVmt2M1q6f4HWbbj6MRbYEeav7Fe9DKzeAooee.woff2',
  },
  {
    name: 'Noto Serif Thai',
    regular:
      'https://fonts.gstatic.com/s/notoserifthai/v9/k3kyo80MPvpLmixYH7euCxWpSMu3-gcWGj0hHAKGvUQlUv_bCKDUSzB5L0oiF9RCAGo.woff2',
    bold: 'https://fonts.gstatic.com/s/notoserifthai/v9/k3kyo80MPvpLmixYH7euCxWpSMu3-gcWGj0hHAKGvUQlUv_bCKDUSzB5L0r8ENRCAGo.woff2',
  },
  {
    name: 'Noto Serif Tibetan',
    regular:
      'https://fonts.gstatic.com/s/notoseriftibetan/v9/gokGH7nwAEdtF9N45n0Vaz7O-pk0wsvxHeDXMfqguoCmIrYcDTzhdA.woff2',
    bold: 'https://fonts.gstatic.com/s/notoseriftibetan/v9/gokGH7nwAEdtF9N45n0Vaz7O-pk0wsvxHeDXMfqguoCmImgbDTzhdA.woff2',
  },
  {
    name: 'Noto Serif Yezidi',
    regular:
      'https://fonts.gstatic.com/s/notoserifyezidi/v9/XLYPIYr5bJNDGYxLBibeHZAn3B5KJENnQjbfhMSVZspD2yEUyFuI.woff2',
    bold: 'https://fonts.gstatic.com/s/notoserifyezidi/v9/XLYPIYr5bJNDGYxLBibeHZAn3B5KJENnQjbfhMSVZspDBSYUyFuI.woff2',
  },
  {
    name: 'Nunito',
    regular: 'https://fonts.gstatic.com/s/nunito/v16/XRXV3I6Li01BKofINeaB.woff2',
    bold: 'https://fonts.gstatic.com/s/nunito/v16/XRXW3I6Li01BKofA6sKUYevI.woff2',
  },
  {
    name: 'Nunito Sans',
    regular: 'https://fonts.gstatic.com/s/nunitosans/v8/pe0qMImSLYBIv1o4X1M8cce9I9s.woff2',
    bold: 'https://fonts.gstatic.com/s/nunitosans/v8/pe03MImSLYBIv1o4X1M8cc9iB85tU1E.woff2',
  },
  {
    name: 'Old Standard TT',
    regular: 'https://fonts.gstatic.com/s/oldstandardtt/v13/MwQubh3o1vLImiwAVvYawgcf2eVeqlq4.woff2',
    bold: 'https://fonts.gstatic.com/s/oldstandardtt/v13/MwQrbh3o1vLImiwAVvYawgcf2eVWEX-tS1Zf.woff2',
  },
  {
    name: 'Oleo Script',
    regular: 'https://fonts.gstatic.com/s/oleoscript/v9/rax5HieDvtMOe0iICsUccChdu08.woff2',
    bold: 'https://fonts.gstatic.com/s/oleoscript/v9/raxkHieDvtMOe0iICsUccCDmnlrf0Ts.woff2',
  },
  {
    name: 'Oleo Script Swash Caps',
    regular:
      'https://fonts.gstatic.com/s/oleoscriptswashcaps/v8/Noaj6Vb-w5SFbTTAsZP_7JkCS08K-jCzDn_HAX2kSA.woff2',
    bold: 'https://fonts.gstatic.com/s/oleoscriptswashcaps/v8/Noag6Vb-w5SFbTTAsZP_7JkCS08K-jCzDn_HCcaBXYImng.woff2',
  },
  {
    name: 'Open Sans',
    regular:
      'https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVI.woff2',
    bold: 'https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1x4gaVI.woff2',
  },
  {
    name: 'Orbitron',
    regular:
      'https://fonts.gstatic.com/s/orbitron/v19/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nyGy6BoWgz.woff2',
    bold: 'https://fonts.gstatic.com/s/orbitron/v19/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nyxSmBoWgz.woff2',
  },
  {
    name: 'Oswald',
    regular: 'https://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvsUZiZQ.woff2',
    bold: 'https://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs1y9osUZiZQ.woff2',
  },
  {
    name: 'Overlock',
    regular: 'https://fonts.gstatic.com/s/overlock/v10/Z9XVDmdMWRiN1_T9Z7TZ6Ow.woff2',
    bold: 'https://fonts.gstatic.com/s/overlock/v10/Z9XSDmdMWRiN1_T9Z7xizfmLtrw.woff2',
  },
  {
    name: 'Overpass',
    regular: 'https://fonts.gstatic.com/s/overpass/v5/qFdH35WCmI96Ajtm81GlU9s.woff2',
    bold: 'https://fonts.gstatic.com/s/overpass/v5/qFdA35WCmI96Ajtm81l6d87N4ho.woff2',
  },
  {
    name: 'Overpass Mono',
    regular: 'https://fonts.gstatic.com/s/overpassmono/v6/_Xmq-H86tzKDdAPa-KPQZ-AC1i-0tg.woff2',
    bold: 'https://fonts.gstatic.com/s/overpassmono/v6/_Xm3-H86tzKDdAPa-KPQZ-AC3vCQo_CXBg.woff2',
  },
  {
    name: 'Oxanium',
    regular: 'https://fonts.gstatic.com/s/oxanium/v6/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G81JfkiLD3U.woff2',
    bold: 'https://fonts.gstatic.com/s/oxanium/v6/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G82XeUiLD3U.woff2',
  },
  {
    name: 'Oxygen',
    regular: 'https://fonts.gstatic.com/s/oxygen/v10/2sDfZG1Wl4LcnbuKjk0m.woff2',
    bold: 'https://fonts.gstatic.com/s/oxygen/v10/2sDcZG1Wl4LcnbuCNWgzaGW5.woff2',
  },
  {
    name: 'PT Sans',
    regular: 'https://fonts.gstatic.com/s/ptsans/v12/jizaRExUiTo99u79D0KExQ.woff2',
    bold: 'https://fonts.gstatic.com/s/ptsans/v12/jizfRExUiTo99u79B_mh0O6tLQ.woff2',
  },
  {
    name: 'PT Sans Caption',
    regular: 'https://fonts.gstatic.com/s/ptsanscaption/v13/0FlMVP6Hrxmt7-fsUFhlFXNIlpcaeg_x.woff2',
    bold: 'https://fonts.gstatic.com/s/ptsanscaption/v13/0FlJVP6Hrxmt7-fsUFhlFXNIlpcSwSrkTA-v.woff2',
  },
  {
    name: 'PT Sans Narrow',
    regular: 'https://fonts.gstatic.com/s/ptsansnarrow/v12/BngRUXNadjH0qYEzV7ab-oWlsbCGwR0.woff2',
    bold: 'https://fonts.gstatic.com/s/ptsansnarrow/v12/BngSUXNadjH0qYEzV7ab-oWlsbg95AiFW_0.woff2',
  },
  {
    name: 'PT Serif',
    regular: 'https://fonts.gstatic.com/s/ptserif/v12/EJRVQgYoZZY2vCFuvAFWzr8.woff2',
    bold: 'https://fonts.gstatic.com/s/ptserif/v12/EJRSQgYoZZY2vCFuvAnt66qSVys.woff2',
  },
  {
    name: 'Padauk',
    regular: 'https://fonts.gstatic.com/s/padauk/v8/RrQRboJg-id7OnbxbEXh.woff2',
    bold: 'https://fonts.gstatic.com/s/padauk/v8/RrQSboJg-id7Onb512D005hF.woff2',
  },
  {
    name: 'Palanquin',
    regular: 'https://fonts.gstatic.com/s/palanquin/v6/9XUnlJ90n1fBFg7ceXwccVtN.woff2',
    bold: 'https://fonts.gstatic.com/s/palanquin/v6/9XUilJ90n1fBFg7ceXwUrn9Yw5Gr.woff2',
  },
  {
    name: 'Palanquin Dark',
    regular: 'https://fonts.gstatic.com/s/palanquindark/v7/xn75YHgl1nqmANMB-26xC7yuF86JRks.woff2',
    bold: 'https://fonts.gstatic.com/s/palanquindark/v7/xn76YHgl1nqmANMB-26xC7yuF8ZWYl4yd8o.woff2',
  },
  {
    name: 'Passion One',
    regular: 'https://fonts.gstatic.com/s/passionone/v11/PbynFmL8HhTPqbjUzux3JEuR9ls.woff2',
    bold: 'https://fonts.gstatic.com/s/passionone/v11/Pby6FmL8HhTPqbjUzux3JEMq007vyJc.woff2',
  },
  {
    name: 'Petrona',
    regular:
      'https://fonts.gstatic.com/s/petrona/v16/mtGl4_NXL7bZo9XXq35wRLONYyOjFk6NsTRGFY8.woff2',
    bold: 'https://fonts.gstatic.com/s/petrona/v16/mtGl4_NXL7bZo9XXq35wRLONYyOjFk5TtjRGFY8.woff2',
  },
  {
    name: 'Philosopher',
    regular: 'https://fonts.gstatic.com/s/philosopher/v14/vEFV2_5QCwIS4_Dhez5jcWBuT00.woff2',
    bold: 'https://fonts.gstatic.com/s/philosopher/v14/vEFI2_5QCwIS4_Dhez5jcWjValgb8tc.woff2',
  },
  {
    name: 'Piazzolla',
    regular:
      'https://fonts.gstatic.com/s/piazzolla/v16/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7LYxkL13gA.woff2',
    bold: 'https://fonts.gstatic.com/s/piazzolla/v16/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7IGwUL13gA.woff2',
  },
  {
    name: 'Play',
    regular: 'https://fonts.gstatic.com/s/play/v12/6aez4K2oVqwIvtU2Hw.woff2',
    bold: 'https://fonts.gstatic.com/s/play/v12/6ae84K2oVqwItm4TCpAy2g.woff2',
  },
  {
    name: 'Playfair Display',
    regular:
      'https://fonts.gstatic.com/s/playfairdisplay/v22/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvXDXbtM.woff2',
    bold: 'https://fonts.gstatic.com/s/playfairdisplay/v22/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKebunDXbtM.woff2',
  },
  {
    name: 'Playfair Display SC',
    regular:
      'https://fonts.gstatic.com/s/playfairdisplaysc/v10/ke85OhoaMkR6-hSn7kbHVoFf7ZfgMPr_lbkMEA.woff2',
    bold: 'https://fonts.gstatic.com/s/playfairdisplaysc/v10/ke80OhoaMkR6-hSn7kbHVoFf7ZfgMPr_nQIpBcwXLg.woff2',
  },
  {
    name: 'Podkova',
    regular:
      'https://fonts.gstatic.com/s/podkova/v20/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWtFzfU_GIs.woff2',
    bold: 'https://fonts.gstatic.com/s/podkova/v20/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWubyvU_GIs.woff2',
  },
  {
    name: 'Poppins',
    regular: 'https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJfecg.woff2',
    bold: 'https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2',
  },
  {
    name: 'Pragati Narrow',
    regular: 'https://fonts.gstatic.com/s/pragatinarrow/v8/vm8vdRf0T0bS1ffgsPB7WZ-mD274wNI.woff2',
    bold: 'https://fonts.gstatic.com/s/pragatinarrow/v8/vm8sdRf0T0bS1ffgsPB7WZ-mD2ZD5cd4EpI.woff2',
  },
  {
    name: 'Pridi',
    regular: 'https://fonts.gstatic.com/s/pridi/v6/2sDQZG5JnZLfkcWdqWg.woff2',
    bold: 'https://fonts.gstatic.com/s/pridi/v6/2sDdZG5JnZLfkc1CjX0kT0A.woff2',
  },
  {
    name: 'Prompt',
    regular: 'https://fonts.gstatic.com/s/prompt/v5/-W__XJnvUD7dzB2KYNod.woff2',
    bold: 'https://fonts.gstatic.com/s/prompt/v5/-W_8XJnvUD7dzB2Cv_4IaWMu.woff2',
  },
  {
    name: 'Proza Libre',
    regular: 'https://fonts.gstatic.com/s/prozalibre/v5/LYjGdGHgj0k1DIQRyUEyyEoodNo.woff2',
    bold: 'https://fonts.gstatic.com/s/prozalibre/v5/LYjbdGHgj0k1DIQRyUEyyEL3UM_YeJE.woff2',
  },
  {
    name: 'Public Sans',
    regular:
      'https://fonts.gstatic.com/s/publicsans/v7/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuFpmJxAco.woff2',
    bold: 'https://fonts.gstatic.com/s/publicsans/v7/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuyJ6JxAco.woff2',
  },
  {
    name: 'Puritan',
    regular: 'https://fonts.gstatic.com/s/puritan/v14/845YNMgkAJ2VTtIoxJ36RA.woff2',
    bold: 'https://fonts.gstatic.com/s/puritan/v14/845dNMgkAJ2VTtIozCbfUdmp-g.woff2',
  },
  {
    name: 'Quantico',
    regular: 'https://fonts.gstatic.com/s/quantico/v10/rax-HiSdp9cPL3KIF7xrJD0.woff2',
    bold: 'https://fonts.gstatic.com/s/quantico/v10/rax5HiSdp9cPL3KIF7TQAShdu08.woff2',
  },
  {
    name: 'Quattrocento',
    regular: 'https://fonts.gstatic.com/s/quattrocento/v12/OZpEg_xvsDZQL_LKIF7q4jP3w2j6.woff2',
    bold: 'https://fonts.gstatic.com/s/quattrocento/v12/OZpbg_xvsDZQL_LKIF7q4jP_eE3vcKnY.woff2',
  },
  {
    name: 'Quattrocento Sans',
    regular:
      'https://fonts.gstatic.com/s/quattrocentosans/v13/va9c4lja2NVIDdIAAoMR5MfuElaRB0zJt08.woff2',
    bold: 'https://fonts.gstatic.com/s/quattrocentosans/v13/va9Z4lja2NVIDdIAAoMR5MfuElaRB0RyklrRPXw.woff2',
  },
  {
    name: 'Quicksand',
    regular:
      'https://fonts.gstatic.com/s/quicksand/v24/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkP8o58a-wg.woff2',
    bold: 'https://fonts.gstatic.com/s/quicksand/v24/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkCEv58a-wg.woff2',
  },
  {
    name: 'Rajdhani',
    regular: 'https://fonts.gstatic.com/s/rajdhani/v10/LDIxapCSOBg7S-QT7p4HM-Y.woff2',
    bold: 'https://fonts.gstatic.com/s/rajdhani/v10/LDI2apCSOBg7S-QT7pbYF_Oreec.woff2',
  },
  {
    name: 'Raleway',
    regular:
      'https://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCIPrE.woff2',
    bold: 'https://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpbCIPrE.woff2',
  },
  {
    name: 'Rambla',
    regular: 'https://fonts.gstatic.com/s/rambla/v8/snfrs0ip98hx6mrEJLgP.woff2',
    bold: 'https://fonts.gstatic.com/s/rambla/v8/snfos0ip98hx6mrMn50aOfl5.woff2',
  },
  {
    name: 'Ranga',
    regular: 'https://fonts.gstatic.com/s/ranga/v8/C8ct4cYisGb28q6FJj0.woff2',
    bold: 'https://fonts.gstatic.com/s/ranga/v8/C8cg4cYisGb28qY-AygW43w.woff2',
  },
  {
    name: 'Rasa',
    regular: 'https://fonts.gstatic.com/s/rasa/v8/xn76YHIn1mWmVKl8ZtAM9NrJfN5GJV4yd8o.woff2',
    bold: 'https://fonts.gstatic.com/s/rasa/v8/xn76YHIn1mWmVKl8ZtAM9NrJfN6YIl4yd8o.woff2',
  },
  {
    name: 'Recursive',
    regular:
      'https://fonts.gstatic.com/s/recursive/v26/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadCCk317tQ0.woff2',
    bold: 'https://fonts.gstatic.com/s/recursive/v26/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadBclH17tQ0.woff2',
  },
  {
    name: 'Red Hat Display',
    regular:
      'https://fonts.gstatic.com/s/redhatdisplay/v7/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbmyWQk8z-A.woff2',
    bold: 'https://fonts.gstatic.com/s/redhatdisplay/v7/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbrKRQk8z-A.woff2',
  },
  {
    name: 'Red Hat Text',
    regular:
      'https://fonts.gstatic.com/s/redhattext/v6/RrQCbohi_ic6B3yVSzGBrMx6ZI_cy1A6Ok2ML7hwZrHQcA.woff2',
    bold: 'https://fonts.gstatic.com/s/redhattext/v6/RrQCbohi_ic6B3yVSzGBrMx6ZI_cy1A6Ok2ML2Z3ZrHQcA.woff2',
  },
  {
    name: 'Red Rose',
    regular:
      'https://fonts.gstatic.com/s/redrose/v8/QdVISTYiLBjouPgEUajvsfWwDtc3MH8yrfszdT6t.woff2',
    bold: 'https://fonts.gstatic.com/s/redrose/v8/QdVISTYiLBjouPgEUajvsfWwDtc3MH8yc_wzdT6t.woff2',
  },
  {
    name: 'Reem Kufi',
    regular:
      'https://fonts.gstatic.com/s/reemkufi/v11/2sDPZGJLip7W2J7v7wQZZE1I0yCmYzzQtuZnIGaV3w.woff2',
    bold: 'https://fonts.gstatic.com/s/reemkufi/v11/2sDPZGJLip7W2J7v7wQZZE1I0yCmYzzQtjhgIGaV3w.woff2',
  },
  {
    name: 'Roboto',
    regular: 'https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4mxK.woff2',
    bold: 'https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfBBc4.woff2',
  },
  {
    name: 'Roboto Condensed',
    regular:
      'https://fonts.gstatic.com/s/robotocondensed/v19/ieVl2ZhZI2eCN5jzbjEETS9weq8-19K7DQ.woff2',
    bold: 'https://fonts.gstatic.com/s/robotocondensed/v19/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meGCQYbw.woff2',
  },
  {
    name: 'Roboto Mono',
    regular:
      'https://fonts.gstatic.com/s/robotomono/v13/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vq_ROW4.woff2',
    bold: 'https://fonts.gstatic.com/s/robotomono/v13/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_AP2_ROW4.woff2',
  },
  {
    name: 'Roboto Slab',
    regular:
      'https://fonts.gstatic.com/s/robotoslab/v16/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojISmb2Rj.woff2',
    bold: 'https://fonts.gstatic.com/s/robotoslab/v16/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoUoOmb2Rj.woff2',
  },
  {
    name: 'Rokkitt',
    regular:
      'https://fonts.gstatic.com/s/rokkitt/v20/qFdb35qfgYFjGy5hukqqhw5XeRgdi1ryd6DMGb8.woff2',
    bold: 'https://fonts.gstatic.com/s/rokkitt/v20/qFdb35qfgYFjGy5hukqqhw5XeRgdi1oscKDMGb8.woff2',
  },
  {
    name: 'Rosario',
    regular:
      'https://fonts.gstatic.com/s/rosario/v19/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM68YCVc0fec.woff2',
    bold: 'https://fonts.gstatic.com/s/rosario/v19/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM6_GDlc0fec.woff2',
  },
  {
    name: 'Rowdies',
    regular: 'https://fonts.gstatic.com/s/rowdies/v5/ptRJTieMYPNBAK21_rBDwQ.woff2',
    bold: 'https://fonts.gstatic.com/s/rowdies/v5/ptRMTieMYPNBAK219gtm1On4KA.woff2',
  },
  {
    name: 'Rubik',
    regular: 'https://fonts.gstatic.com/s/rubik/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4iFV0U1.woff2',
    bold: 'https://fonts.gstatic.com/s/rubik/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-2Y-FV0U1.woff2',
  },
  {
    name: 'Ruda',
    regular: 'https://fonts.gstatic.com/s/ruda/v17/k3kKo8YQJOpFgHQ1mQ5VkEbUKaJFsh_50qk.woff2',
    bold: 'https://fonts.gstatic.com/s/ruda/v17/k3kKo8YQJOpFgHQ1mQ5VkEbUKaKbtR_50qk.woff2',
  },
  {
    name: 'Rufina',
    regular: 'https://fonts.gstatic.com/s/rufina/v8/Yq6V-LyURyLy-aKCpB5l.woff2',
    bold: 'https://fonts.gstatic.com/s/rufina/v8/Yq6W-LyURyLy-aKKHztwu8Zf.woff2',
  },
  {
    name: 'STIX Two Text',
    regular:
      'https://fonts.gstatic.com/s/stixtwotext/v1/YA9Gr02F12Xkf5whdwKf11l0jbKkeidMTtZ5Yihg2ROfURA.woff2',
    bold: 'https://fonts.gstatic.com/s/stixtwotext/v1/YA9Gr02F12Xkf5whdwKf11l0jbKkeidMTtZ5Yii-3hOfURA.woff2',
  },
  {
    name: 'Sahitya',
    regular: 'https://fonts.gstatic.com/s/sahitya/v7/6qLAKZkOuhnuqlJAWSAPPg.woff2',
    bold: 'https://fonts.gstatic.com/s/sahitya/v7/6qLFKZkOuhnuqlJAUZsqKyMlEw.woff2',
  },
  {
    name: 'Saira',
    regular:
      'https://fonts.gstatic.com/s/saira/v8/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA71rCks8xlg.woff2',
    bold: 'https://fonts.gstatic.com/s/saira/v8/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA74TFks8xlg.woff2',
  },
  {
    name: 'Saira Condensed',
    regular: 'https://fonts.gstatic.com/s/sairacondensed/v6/EJROQgErUN8XuHNEtX81i9TmEkrvoutF.woff2',
    bold: 'https://fonts.gstatic.com/s/sairacondensed/v6/EJRLQgErUN8XuHNEtX81i9TmEkrnfc9Q962f.woff2',
  },
  {
    name: 'Saira Extra Condensed',
    regular:
      'https://fonts.gstatic.com/s/sairaextracondensed/v6/-nFiOHYr-vcC7h8MklGBkrvmUG9rbpkisrTj6Ej0.woff2',
    bold: 'https://fonts.gstatic.com/s/sairaextracondensed/v6/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrN2zh2wph.woff2',
  },
  {
    name: 'Saira Semi Condensed',
    regular:
      'https://fonts.gstatic.com/s/sairasemicondensed/v6/U9MD6c-2-nnJkHxyCjRcnMHcWVWV1cWRRX8MaOY.woff2',
    bold: 'https://fonts.gstatic.com/s/sairasemicondensed/v6/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXfTTPMRiXk.woff2',
  },
  {
    name: 'Sansita',
    regular: 'https://fonts.gstatic.com/s/sansita/v5/QldONTRRphEb_-V7LB6xTA.woff2',
    bold: 'https://fonts.gstatic.com/s/sansita/v5/QldLNTRRphEb_-V7JKWUWX5-ww.woff2',
  },
  {
    name: 'Sansita Swashed',
    regular:
      'https://fonts.gstatic.com/s/sansitaswashed/v5/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSW7RpXT0few.woff2',
    bold: 'https://fonts.gstatic.com/s/sansitaswashed/v5/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSW2puXT0few.woff2',
  },
  {
    name: 'Sarabun',
    regular: 'https://fonts.gstatic.com/s/sarabun/v8/DtVjJx26TKEr37c9aBVJnw.woff2',
    bold: 'https://fonts.gstatic.com/s/sarabun/v8/DtVmJx26TKEr37c9YMptilss6w.woff2',
  },
  {
    name: 'Sarala',
    regular: 'https://fonts.gstatic.com/s/sarala/v5/uK_y4riEZv4o1w9hDRcS.woff2',
    bold: 'https://fonts.gstatic.com/s/sarala/v5/uK_x4riEZv4o1w9ptjIHPd-Z.woff2',
  },
  {
    name: 'Sarpanch',
    regular: 'https://fonts.gstatic.com/s/sarpanch/v6/hESy6Xt4NCpRuk6Pzi2HTos.woff2',
    bold: 'https://fonts.gstatic.com/s/sarpanch/v6/hES16Xt4NCpRuk6PziVYap7Y3nA.woff2',
  },
  {
    name: 'Scada',
    regular: 'https://fonts.gstatic.com/s/scada/v9/RLpxK5Pv5qumeVJvzTQ.woff2',
    bold: 'https://fonts.gstatic.com/s/scada/v9/RLp8K5Pv5qumeVrU6CEnT1Y.woff2',
  },
  {
    name: 'Scheherazade',
    regular: 'https://fonts.gstatic.com/s/scheherazade/v20/YA9Ur0yF4ETZN60keViq1kQQsJmp.woff2',
    bold: 'https://fonts.gstatic.com/s/scheherazade/v20/YA9Lr0yF4ETZN60keViq1kQYC7y8idfU.woff2',
  },
  {
    name: 'Scheherazade New',
    regular:
      'https://fonts.gstatic.com/s/scheherazadenew/v3/4UaZrFhTvxVnHDvUkUiHg8jprP4DOwRmOw.woff2',
    bold: 'https://fonts.gstatic.com/s/scheherazadenew/v3/4UaerFhTvxVnHDvUkUiHg8jprP4DM79DLlEI-Q.woff2',
  },
  {
    name: 'Sen',
    regular: 'https://fonts.gstatic.com/s/sen/v2/6xKjdSxYI9_3nPWN.woff2',
    bold: 'https://fonts.gstatic.com/s/sen/v2/6xKudSxYI9__J9CYLUv0.woff2',
  },
  {
    name: 'Share',
    regular: 'https://fonts.gstatic.com/s/share/v11/i7dEIFliZjKNF6VKFrs.woff2',
    bold: 'https://fonts.gstatic.com/s/share/v11/i7dJIFliZjKNF63xM665UEM.woff2',
  },
  {
    name: 'Shippori Mincho',
    regular:
      'https://fonts.gstatic.com/s/shipporimincho/v7/VdGGAZweH5EbgHY6YExcZfDoj0BA3peS7qtgjp7PoXk4LwzLxlAlXE-_3UM.119.woff2',
    bold: 'https://fonts.gstatic.com/s/shipporimincho/v7/VdGDAZweH5EbgHY6YExcZfDoj0B4A9Gm4fke5f7ErmwqJDbcxmkgTEWquC8NvBI.119.woff2',
  },
  {
    name: 'Shippori Mincho B1',
    regular:
      'https://fonts.gstatic.com/s/shipporiminchob1/v10/~ChQKElNoaXBwb3JpIE1pbmNobyBCMSAFQhQKEGphcGFuZXNlX2RlZmF1bHQQAA==.119.woff2',
    bold: 'https://fonts.gstatic.com/s/shipporiminchob1/v10/~ChcKElNoaXBwb3JpIE1pbmNobyBCMRjYBCAFQhQKEGphcGFuZXNlX2RlZmF1bHQQAA==.119.woff2',
  },
  {
    name: 'Signika',
    regular:
      'https://fonts.gstatic.com/s/signika/v12/vEFO2_JTCgwQ5ejvMV0O96D01E8J0tJXHKbBjM4.woff2',
    bold: 'https://fonts.gstatic.com/s/signika/v12/vEFO2_JTCgwQ5ejvMV0O96D01E8J0tKJG6bBjM4.woff2',
  },
  {
    name: 'Signika Negative',
    regular:
      'https://fonts.gstatic.com/s/signikanegative/v11/E218_cfngu7HiRpPX3ZpNE4kY5zKYvWhrw.woff2',
    bold: 'https://fonts.gstatic.com/s/signikanegative/v11/E217_cfngu7HiRpPX3ZpNE4kY5zKaiqFupbJ6g.woff2',
  },
  {
    name: 'Sintony',
    regular: 'https://fonts.gstatic.com/s/sintony/v8/XoHm2YDqR7-98cVUETMtug.woff2',
    bold: 'https://fonts.gstatic.com/s/sintony/v8/XoHj2YDqR7-98cVUGYgIr9AJkw.woff2',
  },
  {
    name: 'Skranji',
    regular: 'https://fonts.gstatic.com/s/skranji/v8/OZpDg_dtriVFNerMUzykkw.woff2',
    bold: 'https://fonts.gstatic.com/s/skranji/v8/OZpGg_dtriVFNerMW4eBhlvHwQ.woff2',
  },
  {
    name: 'Solway',
    regular: 'https://fonts.gstatic.com/s/solway/v5/AMOQz46Cs2uTAOCmhXo8.woff2',
    bold: 'https://fonts.gstatic.com/s/solway/v5/AMOTz46Cs2uTAOCuPl8pnccR.woff2',
  },
  {
    name: 'Sora',
    regular: 'https://fonts.gstatic.com/s/sora/v3/xMQOuFFYT72X5wkB_18qmnndmSdSnk-NKQI.woff2',
    bold: 'https://fonts.gstatic.com/s/sora/v3/xMQOuFFYT72X5wkB_18qmnndmSeMmU-NKQI.woff2',
  },
  {
    name: 'Source Code Pro',
    regular: 'https://fonts.gstatic.com/s/sourcecodepro/v14/HI_SiYsKILxRpg3hIP6sJ7fM7PqlPevW.woff2',
    bold: 'https://fonts.gstatic.com/s/sourcecodepro/v14/HI_XiYsKILxRpg3hIP6sJ7fM7Pqt4s_Ds-cq.woff2',
  },
  {
    name: 'Source Sans Pro',
    regular: 'https://fonts.gstatic.com/s/sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7l.woff2',
    bold: 'https://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlxdu.woff2',
  },
  {
    name: 'Source Serif Pro',
    regular:
      'https://fonts.gstatic.com/s/sourceserifpro/v11/neIQzD-0qpwxpaWvjeD0X88SAOeauXQ-oA.woff2',
    bold: 'https://fonts.gstatic.com/s/sourceserifpro/v11/neIXzD-0qpwxpaWvjeD0X88SAOeasasatSyqxA.woff2',
  },
  {
    name: 'Space Grotesk',
    regular:
      'https://fonts.gstatic.com/s/spacegrotesk/v6/V8mQoQDjQSkFtoMM3T6r8E7mF71Q-gOoraIAEj7oUXskPMA.woff2',
    bold: 'https://fonts.gstatic.com/s/spacegrotesk/v6/V8mQoQDjQSkFtoMM3T6r8E7mF71Q-gOoraIAEj42VnskPMA.woff2',
  },
  {
    name: 'Space Mono',
    regular: 'https://fonts.gstatic.com/s/spacemono/v6/i7dPIFZifjKcF5UAWdDRYEF8RQ.woff2',
    bold: 'https://fonts.gstatic.com/s/spacemono/v6/i7dMIFZifjKcF5UAWdDRaPpZUFWaHg.woff2',
  },
  {
    name: 'Spartan',
    regular: 'https://fonts.gstatic.com/s/spartan/v3/l7gAbjR61M69yt8Z8w6FZf9WoBxdBrGFuV6JABQ.woff2',
    bold: 'https://fonts.gstatic.com/s/spartan/v3/l7gAbjR61M69yt8Z8w6FZf9WoBxdBrFbvl6JABQ.woff2',
  },
  {
    name: 'Spectral',
    regular: 'https://fonts.gstatic.com/s/spectral/v7/rnCr-xNNww_2s0amA9M5kng.woff2',
    bold: 'https://fonts.gstatic.com/s/spectral/v7/rnCs-xNNww_2s0amA9vmtm3BafY.woff2',
  },
  {
    name: 'Spectral SC',
    regular: 'https://fonts.gstatic.com/s/spectralsc/v6/KtkpALCRZonmalTgyPmRfsWl42E.woff2',
    bold: 'https://fonts.gstatic.com/s/spectralsc/v6/Ktk0ALCRZonmalTgyPmRfs16x3TQN4Y.woff2',
  },
  {
    name: 'Srisakdi',
    regular: 'https://fonts.gstatic.com/s/srisakdi/v6/yMJRMIlvdpDbkB0A-gq7dy0.woff2',
    bold: 'https://fonts.gstatic.com/s/srisakdi/v6/yMJWMIlvdpDbkB0A-gIAUjh2qtA.woff2',
  },
  {
    name: 'Stardos Stencil',
    regular:
      'https://fonts.gstatic.com/s/stardosstencil/v11/X7n94bcuGPC8hrvEOHXOgaKCc2Th6F52.woff2',
    bold: 'https://fonts.gstatic.com/s/stardosstencil/v11/X7n44bcuGPC8hrvEOHXOgaKCc2TpU3tjuQWs.woff2',
  },
  {
    name: 'Stick No Bills',
    regular:
      'https://fonts.gstatic.com/s/sticknobills/v1/bWts7ffXZwHuAa9Uld-oEK4QKlxj9f9t_7uEmjcVv8QLLbKx.woff2',
    bold: 'https://fonts.gstatic.com/s/sticknobills/v1/bWts7ffXZwHuAa9Uld-oEK4QKlxj9f9t_7uEmjcVYcMLLbKx.woff2',
  },
  {
    name: 'Sulphur Point',
    regular: 'https://fonts.gstatic.com/s/sulphurpoint/v4/RLp5K5vv8KaycDcazWFPBj2adf4Yug.woff2',
    bold: 'https://fonts.gstatic.com/s/sulphurpoint/v4/RLpkK5vv8KaycDcazWFPBj2afUU9r64PUQ.woff2',
  },
  {
    name: 'Sumana',
    regular: 'https://fonts.gstatic.com/s/sumana/v5/4UaDrE5TqRBjGj-29xLj.woff2',
    bold: 'https://fonts.gstatic.com/s/sumana/v5/4UaArE5TqRBjGj--TDf24I3M.woff2',
  },
  {
    name: 'Sura',
    regular: 'https://fonts.gstatic.com/s/sura/v7/SZc23FL5PbyzJfleWg.woff2',
    bold: 'https://fonts.gstatic.com/s/sura/v7/SZc53FL5PbyzLUJ7TzrMkA.woff2',
  },
  {
    name: 'Suwannaphum',
    regular: 'https://fonts.gstatic.com/s/suwannaphum/v20/jAnCgHV7GtDvc8jbe8hXXLWi9cE.woff2',
    bold: 'https://fonts.gstatic.com/s/suwannaphum/v20/jAnfgHV7GtDvc8jbe8hXXL0Z0NS7exA.woff2',
  },
  {
    name: 'Syncopate',
    regular: 'https://fonts.gstatic.com/s/syncopate/v12/pe0sMIuPIYBCpEV5eFdCBfe_.woff2',
    bold: 'https://fonts.gstatic.com/s/syncopate/v12/pe0pMIuPIYBCpEV5eFdKvtKqBP5v.woff2',
  },
  {
    name: 'Syne',
    regular: 'https://fonts.gstatic.com/s/syne/v3/8vIS7w4qzmVxsWxjBZRjr0FKM_04uQ6jTY8.woff2',
    bold: 'https://fonts.gstatic.com/s/syne/v3/8vIS7w4qzmVxsWxjBZRjr0FKM_3mvg6jTY8.woff2',
  },
  {
    name: 'Tajawal',
    regular: 'https://fonts.gstatic.com/s/tajawal/v4/Iura6YBj_oCad4k1nzGBCw.woff2',
    bold: 'https://fonts.gstatic.com/s/tajawal/v4/Iurf6YBj_oCad4k1l4qkHrFpiQ.woff2',
  },
  {
    name: 'Tangerine',
    regular: 'https://fonts.gstatic.com/s/tangerine/v12/IurY6Y5j_oScZZow4VOxCZZM.woff2',
    bold: 'https://fonts.gstatic.com/s/tangerine/v12/Iurd6Y5j_oScZZow4VO5srNZi5FN.woff2',
  },
  {
    name: 'Taviraj',
    regular: 'https://fonts.gstatic.com/s/taviraj/v6/ahcZv8Cj3ylylTXzTOkrUg.woff2',
    bold: 'https://fonts.gstatic.com/s/taviraj/v6/ahccv8Cj3ylylTXzRDYPR-5RgA.woff2',
  },
  {
    name: 'Teko',
    regular: 'https://fonts.gstatic.com/s/teko/v10/LYjNdG7kmE0gfaN9pQ.woff2',
    bold: 'https://fonts.gstatic.com/s/teko/v10/LYjCdG7kmE0gdXxZsCRgqA.woff2',
  },
  {
    name: 'Texturina',
    regular:
      'https://fonts.gstatic.com/s/texturina/v9/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2eYGsUn0Zs.woff2',
    bold: 'https://fonts.gstatic.com/s/texturina/v9/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2dGHcUn0Zs.woff2',
  },
  {
    name: 'Thasadith',
    regular: 'https://fonts.gstatic.com/s/thasadith/v4/mtG44_1TIqPYrd_f5R1ot0MV.woff2',
    bold: 'https://fonts.gstatic.com/s/thasadith/v4/mtG94_1TIqPYrd_f5R1gDGYA3wSz.woff2',
  },
  {
    name: 'Tienne',
    regular: 'https://fonts.gstatic.com/s/tienne/v15/AYCKpX7pe9YCRP07l0nG.woff2',
    bold: 'https://fonts.gstatic.com/s/tienne/v15/AYCJpX7pe9YCRP0zLGzTR3Jv.woff2',
  },
  {
    name: 'Tillana',
    regular: 'https://fonts.gstatic.com/s/tillana/v6/VuJxdNvf35P4qJ1OSKHdOQ.woff2',
    bold: 'https://fonts.gstatic.com/s/tillana/v6/VuJ0dNvf35P4qJ1OQH75LI5GZA.woff2',
  },
  {
    name: 'Tinos',
    regular: 'https://fonts.gstatic.com/s/tinos/v16/buE4poGnedXvwjX7fmQ.woff2',
    bold: 'https://fonts.gstatic.com/s/tinos/v16/buE1poGnedXvwj1AW3Fu0C8.woff2',
  },
  {
    name: 'Titillium Web',
    regular: 'https://fonts.gstatic.com/s/titilliumweb/v10/NaPecZTIAOhVxoMyOr9n_E7fdMPmDQ.woff2',
    bold: 'https://fonts.gstatic.com/s/titilliumweb/v10/NaPDcZTIAOhVxoMyOr9n_E7ffBzCGItzYw.woff2',
  },
  {
    name: 'Tomorrow',
    regular: 'https://fonts.gstatic.com/s/tomorrow/v5/WBLmrETNbFtZCeGqgRXSe2A.woff2',
    bold: 'https://fonts.gstatic.com/s/tomorrow/v5/WBLhrETNbFtZCeGqgR0NX3XPDMU.woff2',
  },
  {
    name: 'Tourney',
    regular:
      'https://fonts.gstatic.com/s/tourney/v1/AlZa_ztDtYzv1tzq1wcJnbVt7xseomk-tNs7qrzTWbyt8n7GOQFCYjB0.woff2',
    bold: 'https://fonts.gstatic.com/s/tourney/v1/AlZa_ztDtYzv1tzq1wcJnbVt7xseomk-tNs7qrzTWbyt8n7G5wZCYjB0.woff2',
  },
  {
    name: 'Trirong',
    regular: 'https://fonts.gstatic.com/s/trirong/v6/7r3GqXNgp8wxdOdOn4so2A.woff2',
    bold: 'https://fonts.gstatic.com/s/trirong/v6/7r3DqXNgp8wxdOdOl1QMzalB5w.woff2',
  },
  {
    name: 'Trispace',
    regular:
      'https://fonts.gstatic.com/s/trispace/v6/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbH9rkQ5-zQ.woff2',
    bold: 'https://fonts.gstatic.com/s/trispace/v6/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbKFskQ5-zQ.woff2',
  },
  {
    name: 'Trochut',
    regular: 'https://fonts.gstatic.com/s/trochut/v10/CHyjV-fDDlP9bDIw1nOCfA.woff2',
    bold: 'https://fonts.gstatic.com/s/trochut/v10/CHymV-fDDlP9bDIw3sinaV0uMw.woff2',
  },
  {
    name: 'Truculenta',
    regular:
      'https://fonts.gstatic.com/s/truculenta/v6/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMlAigwAPHw.woff2',
    bold: 'https://fonts.gstatic.com/s/truculenta/v6/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMo4lgwAPHw.woff2',
  },
  {
    name: 'Turret Road',
    regular: 'https://fonts.gstatic.com/s/turretroad/v2/pxiAypMgpcBFjE84Zv-fE0tCMPI.woff2',
    bold: 'https://fonts.gstatic.com/s/turretroad/v2/pxidypMgpcBFjE84Zv-fE0P5FeeMaEw.woff2',
  },
  {
    name: 'Ubuntu',
    regular: 'https://fonts.gstatic.com/s/ubuntu/v15/4iCs6KVjbNBYlgoKfw72.woff2',
    bold: 'https://fonts.gstatic.com/s/ubuntu/v15/4iCv6KVjbNBYlgoCxCvjsGyN.woff2',
  },
  {
    name: 'Ubuntu Mono',
    regular: 'https://fonts.gstatic.com/s/ubuntumono/v10/KFOjCneDtsqEr0keqCMhbCc6CsQ.woff2',
    bold: 'https://fonts.gstatic.com/s/ubuntumono/v10/KFO-CneDtsqEr0keqCMhbC-BL9H1tY0.woff2',
  },
  {
    name: 'Unkempt',
    regular: 'https://fonts.gstatic.com/s/unkempt/v12/2EbnL-Z2DFZue0DSeYEV8g.woff2',
    bold: 'https://fonts.gstatic.com/s/unkempt/v12/2EbiL-Z2DFZue0DScTow5zK5qg.woff2',
  },
  {
    name: 'Unna',
    regular: 'https://fonts.gstatic.com/s/unna/v16/AYCEpXzofN0NOp8LlQ.woff2',
    bold: 'https://fonts.gstatic.com/s/unna/v16/AYCLpXzofN0NMiQugG7jRQ.woff2',
  },
  {
    name: 'Urbanist',
    regular:
      'https://fonts.gstatic.com/s/urbanist/v1/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDyx4vEZmq.woff2',
    bold: 'https://fonts.gstatic.com/s/urbanist/v1/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDFRkvEZmq.woff2',
  },
  {
    name: 'Varta',
    regular: 'https://fonts.gstatic.com/s/varta/v5/Qw3AZQpJHj_6LzHUngWbrFkDH1x9tD40jbs_.woff2',
    bold: 'https://fonts.gstatic.com/s/varta/v5/Qw3AZQpJHj_6LzHUngWbrFkDH1x9ajk0jbs_.woff2',
  },
  {
    name: 'Vesper Libre',
    regular: 'https://fonts.gstatic.com/s/vesperlibre/v14/bx6CNxyWnf-uxPdXDHUD_RdICEWM.woff2',
    bold: 'https://fonts.gstatic.com/s/vesperlibre/v14/bx6dNxyWnf-uxPdXDHUD_RdAs2CZ1YML.woff2',
  },
  {
    name: 'Volkhov',
    regular: 'https://fonts.gstatic.com/s/volkhov/v12/SlGQmQieoJcKemNecTUEhQ.woff2',
    bold: 'https://fonts.gstatic.com/s/volkhov/v12/SlGVmQieoJcKemNeeY4hkHNSbQ.woff2',
  },
  {
    name: 'Vollkorn',
    regular:
      'https://fonts.gstatic.com/s/vollkorn/v13/0ybgGDoxxrvAnPhYGzMlQLzuMasz6Df2MHGeHmmc.woff2',
    bold: 'https://fonts.gstatic.com/s/vollkorn/v13/0ybgGDoxxrvAnPhYGzMlQLzuMasz6Df27naeHmmc.woff2',
  },
  {
    name: 'Vollkorn SC',
    regular: 'https://fonts.gstatic.com/s/vollkornsc/v6/j8_v6-zQ3rXpceZj9cqnViF-Pn4.woff2',
    bold: 'https://fonts.gstatic.com/s/vollkornsc/v6/j8_y6-zQ3rXpceZj9cqnVimhGmuta7I.woff2',
  },
  {
    name: 'Work Sans',
    regular:
      'https://fonts.gstatic.com/s/worksans/v11/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXBi8Jpg.woff2',
    bold: 'https://fonts.gstatic.com/s/worksans/v11/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K5fQBi8Jpg.woff2',
  },
  {
    name: 'Yaldevi',
    regular: 'https://fonts.gstatic.com/s/yaldevi/v1/cY9afj6VW0NMrDWtDNzCOwlPMq9SLpdxJwvvZRM.woff2',
    bold: 'https://fonts.gstatic.com/s/yaldevi/v1/cY9afj6VW0NMrDWtDNzCOwlPMq9SLpevIAvvZRM.woff2',
  },
  {
    name: 'Yanone Kaffeesatz',
    regular:
      'https://fonts.gstatic.com/s/yanonekaffeesatz/v18/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIfto9tWZd2GK.woff2',
    bold: 'https://fonts.gstatic.com/s/yanonekaffeesatz/v18/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIftoKNKZd2GK.woff2',
  },
  {
    name: 'Yantramanav',
    regular: 'https://fonts.gstatic.com/s/yantramanav/v6/flU8Rqu5zY00QEpyWJYWN5fzXeY.woff2',
    bold: 'https://fonts.gstatic.com/s/yantramanav/v6/flUhRqu5zY00QEpyWJYWN59IePNeKBM.woff2',
  },
  {
    name: 'Yrsa',
    regular: 'https://fonts.gstatic.com/s/yrsa/v8/wlprgwnQFlxs_wD3CFSMYmFaaCieSONS_LU.woff2',
    bold: 'https://fonts.gstatic.com/s/yrsa/v8/wlprgwnQFlxs_wD3CFSMYmFaaChAT-NS_LU.woff2',
  },
  {
    name: 'Zen Kaku Gothic Antique',
    regular:
      'https://fonts.gstatic.com/s/zenkakugothicantique/v3/6qLQKYkHvh-nlUpKPAdoVFBtfxDzIn1eCzpB21-ltQKhYvUbFlj7bzN2DjeYWqumVE-qzQ.119.woff2',
    bold: 'https://fonts.gstatic.com/s/zenkakugothicantique/v3/6qLVKYkHvh-nlUpKPAdoVFBtfxDzIn1eCzpB22cc8jauMItwdlP0eiF9NCCYY662XlrPoX7MjA.119.woff2',
  },
  {
    name: 'Zen Kaku Gothic New',
    regular:
      'https://fonts.gstatic.com/s/zenkakugothicnew/v3/gNMYW2drQpDw0GjzrVNFf_valaDBcznOkj4KXW7oIgA9sLCeNoPLhe6Lpg_IbDMC.119.woff2',
    bold: 'https://fonts.gstatic.com/s/zenkakugothicnew/v3/gNMVW2drQpDw0GjzrVNFf_valaDBcznOqodNaWG6XGtdu7-LJIjxku6yox_CeVZufvVB.119.woff2',
  },
  {
    name: 'Zen Maru Gothic',
    regular:
      'https://fonts.gstatic.com/s/zenmarugothic/v3/o-0SIpIxzW5b-RxT-6A8jWAtCp-k6Cp2NqD0lNmc0DbQUnIZ7kZUNZMp2Vc.119.woff2',
    bold: 'https://fonts.gstatic.com/s/zenmarugothic/v3/o-0XIpIxzW5b-RxT-6A8jWAtCp-cUW1COfKK_7mX3yPCWUgO7n9RJZk8vDuG3WM.119.woff2',
  },
  {
    name: 'Zen Old Mincho',
    regular:
      'https://fonts.gstatic.com/s/zenoldmincho/v3/tss0ApVaYytLwxTqcxfMyBveyYby621Qy71JT3kBgWHP-z3MekRWhFKJ1A.119.woff2',
    bold: 'https://fonts.gstatic.com/s/zenoldmincho/v3/tss3ApVaYytLwxTqcxfMyBveyb5LrFlfmcMiL3IOlHPEwSrMQ0FGjkfsuOCSDQ.119.woff2',
  },
  {
    name: 'Zilla Slab',
    regular: 'https://fonts.gstatic.com/s/zillaslab/v6/dFa6ZfeM_74wlPZtksIFajo6_Q.woff2',
    bold: 'https://fonts.gstatic.com/s/zillaslab/v6/dFa5ZfeM_74wlPZtksIFYuUe6HOpWw.woff2',
  },
  {
    name: 'Zilla Slab Highlight',
    regular:
      'https://fonts.gstatic.com/s/zillaslabhighlight/v10/gNMbW2BrTpK8-inLtBJgMMfbm6uNVDvRxitPaWE.woff2',
    bold: 'https://fonts.gstatic.com/s/zillaslabhighlight/v10/gNMUW2BrTpK8-inLtBJgMMfbm6uNVDvRxiP0THT_aGQ.woff2',
  },
]

export default googleFonts
