import { SubmitResponse } from 'sdk/executions/submit'
import { createSlice } from '@reduxjs/toolkit'

import { Bootstrap, executionsBootstrap } from 'thunks/executions/bootstrap'
import { executionsSubmit } from 'thunks/executions/submit'

export type PipelineState = {
  pipeline?: Bootstrap['pipeline']
  redirect?: SubmitResponse['redirect']
}

export type PipeActions = {}

const initialState: PipelineState = {}

const slice = createSlice({
  name: 'pipeline',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(executionsBootstrap.fulfilled, (state, { payload }) => {
      state.pipeline = payload.pipeline
    })
    builder.addCase(executionsSubmit.fulfilled, (state, { payload }) => {
      if (payload.redirect) {
        state.redirect = payload.redirect
      }
    })
  },
})

export default slice
export const actions = slice.actions
