import { useCallback } from 'react'

import { EncryptionFn, useStage } from './useStage'

export default function() {
  const encryptionFn: EncryptionFn<'document-signature'> = useCallback(
    async (req, encrypt) => {
      req.signature = await encrypt(req.signature)
      return req
    },
    []
  )

  return useStage('document-signature', encryptionFn)
}
