import { JourneyClient } from '../'
import { BootstrapReply } from './bootstrap'
import { CreateReply, CreateRequest } from './create'
import { RetrieveRequest, RetrieveResponse } from './retrieve'
import { SubmitResponse } from './submit'

class ExecutionClient {
  private client: JourneyClient

  constructor(client: JourneyClient) {
    this.client = client
  }

  async create(request: CreateRequest): Promise<CreateReply> {
    return await this.client.request('POST', `/api/client/executions`, request)
  }

  async bootstrap(): Promise<BootstrapReply> {
    return await this.client.request('GET', `/api/client/executions/bootstrap`)
  }

  async submit(): Promise<SubmitResponse> {
    return await this.client.request('POST', `/api/client/executions/submit`)
  }

  async retrieve(request: RetrieveRequest): Promise<RetrieveResponse> {
    return await this.client.request('GET', `/api/client/executions/${request.id}`)
  }
}

export default ExecutionClient
