import classNames from 'classnames'
import React, { FC, HTMLProps } from 'react'

import { ActionTimer } from 'hooks/useActionTimer'
import { idleTimer } from 'sdk/react/hooks/util/useActionTimer'

import { ReactComponent as CheckmarkIcon } from '~p/assets/checkmark.svg'
import { ReactComponent as SpinnerThirdIcon } from '~p/assets/spinner-third.svg'

import s from './FooterButton.module.scss'

type Props = {
  timer?: ActionTimer
  className?: string
} & HTMLProps<HTMLButtonElement>

const FooterButton: FC<Props> = ({ timer: origTimer, children, className, ...rest }) => {
  const timer = origTimer?.id === 'submit' ? origTimer : idleTimer

  return (
    <button
      className={classNames(s.FooterButton, className)}
      {...rest}
      disabled={rest.disabled}
      type={rest.type === 'submit' ? 'submit' : 'button'}
      data-loading={timer?.isLoading || rest.disabled ? true : false}
    >
      <div>
        {timer?.didSucceed ? (
          <CheckmarkIcon />
        ) : timer?.isLoading ? (
          <SpinnerThirdIcon className={s.Loading} />
        ) : (
          <span>{children}</span>
        )}
      </div>
    </button>
  )
}

export default FooterButton
