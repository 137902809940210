import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { BootstrapReply } from '../../executions/bootstrap'
import { SubmitResponse } from '../../executions/submit'
import { useJourneyContext } from '../hooks'
import stageHooks from '../hooks/map'
import useBootstrap from '../hooks/useBootstrap'
import PipelineStage, { RenderFn } from './Stage'

type CommonProps = {
  token: string
  onBootstrap: (bootstrap: BootstrapReply) => void
  onSuccess: (res: SubmitResponse) => void
  onError: (error: Error) => void
}

type PropsWithChildren = CommonProps & {
  children: RenderFn
}

type PropsWithRender = CommonProps & {
  render: RenderFn
}

type Props = PropsWithChildren | PropsWithRender

const Pipeline = ({
  token,
  onBootstrap,
  onSuccess,
  onError,
  ...rest
}: Props): ReactElement => {
  const [stageIdx, setStageIdx] = useState(0)
  const { stages, bootstrap } = useBootstrap(token, onError)
  const { journey } = useJourneyContext()

  useEffect(() => {
    if (!bootstrap) return
    onBootstrap(bootstrap)
  }, [bootstrap])

  const stage = useMemo(() => stages && stages[stageIdx], [stages, stageIdx])

  const handleSuccess = useCallback(async () => {
    if (!stages) return

    if (stageIdx < stages.length - 1) {
      setStageIdx(stageIdx + 1)
    } else {
      const executionRes = await journey.executions.submit()
      onSuccess(executionRes)
    }
  }, [stageIdx, stages])

  if (!stage) return <></>

  const renderFunc = 'render' in rest ? rest.render : rest.children

  return (
    <PipelineStage
      key={stage.type}
      stage={stage}
      hook={stageHooks[stage.type]}
      render={renderFunc}
      onSuccess={handleSuccess}
      onError={onError}
    />
  )
}

export default Pipeline
