import { JourneyClient } from '../'
import { EncryptionHelper, Encryptor } from '../crypto'
import { SubmitRequest } from '../stages/submit_request'
import { SubmitResponse } from '../stages/submit_response'
import {
  doesStageTypeRequireEncryption,
  doesStageTypeRequireInitiation,
  EncryptionKey,
  StageType,
  StageTypeWithEncryption,
  StageTypeWithInitiation,
} from '../types'
import { InitiateRequest } from './initiate_request'
import { InitiateResponse } from './initiate_response'

type InitiationEncryptionResponse =
  InitiateResponse<StageTypeWithEncryption> & {
    encryptionKey: EncryptionKey
  }

type JINKey = {
  issuer: string
  id: string
}

class Stage<T extends StageType> {
  private client: JourneyClient
  private type: T

  private jinKey?: T extends StageTypeWithEncryption ? JINKey : undefined
  private encryptor?: EncryptionHelper

  constructor(client: JourneyClient, type: T) {
    this.client = client
    this.type = type
  }

  async initiate(
    request: T extends StageTypeWithInitiation
      ? InitiateRequest<T>['data']
      : void
  ): Promise<InitiateResponse<StageTypeWithInitiation>> {
    if (!doesStageTypeRequireInitiation(this.type)) {
      throw new Error(`This stage doesn't support the initiation step`)
    }

    const resp = await this.client.stages.initiate<StageTypeWithInitiation>({
      type: this.type,
      data: request,
    })

    if (doesStageTypeRequireEncryption(this.type)) {
      const { encryptionKey, ...rest } = resp as InitiationEncryptionResponse

      const { jinKey } = this
      this.jinKey = {
        issuer: encryptionKey.issuer,
        id: encryptionKey.keyId,
      } as typeof jinKey

      const publicKey = atob(encryptionKey.key)
      this.encryptor = new Encryptor(publicKey)

      return rest
    }

    return resp
  }

  async submit(request: SubmitRequest<T>['data']): Promise<SubmitResponse<T>> {
    const payload = {
      type: this.type,
      encryptionKey: await this.encryptionKey(),
      data: request,
    } as SubmitRequest<T>

    return await this.client.stages.submit(payload)
  }

  async encrypt(data: string): Promise<string> {
    if (!this.encryptor || !this.encryptor.key) {
      throw new Error(`encryptor isn't ready to encrypt data`)
    }
    return await this.encryptor.encrypt(data)
  }

  async encryptionKey(): Promise<EncryptionKey | undefined> {
    if (!this.jinKey || !this.encryptor) return

    return {
      issuer: this.jinKey.issuer,
      keyId: this.jinKey.id,
      key: await this.encryptor.key(),
    }
  }
}

export default Stage
