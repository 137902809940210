import { BootstrapReply } from 'sdk/executions/bootstrap'
import { StageTypeWithHook } from 'sdk/react/hooks/map'
import { StageFor } from 'sdk/types'
import { createAsyncThunk } from '@reduxjs/toolkit'

type Pipeline = Omit<BootstrapReply['pipeline'], 'stages'>

export type Bootstrap = Omit<BootstrapReply, 'pipeline'> & {
  pipeline: Pipeline & {
    stages: StageFor<StageTypeWithHook>[]
  }
}

export const executionsBootstrap = createAsyncThunk<Bootstrap, Bootstrap>(
  'executions/bootstrap',
  async bootstrap => bootstrap
)
