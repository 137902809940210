import qs from 'qs'
import React, { FC, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import useActionTimer from 'hooks/useActionTimer'

import { useSecureLink } from './_useSecureLink'
import LoadingDots from 'components/molecules/LoadingDots'

const EntrySecureLink: FC = () => {
  const { error, clearError, authenticate } = useSecureLink()
  const { uniqueId, linkId } = useParams<{ uniqueId: string; linkId: string }>()

  const timer = useActionTimer()
  const history = useHistory()
  useEffect(() => {
    if (!uniqueId || !linkId) return
      ; (async () => {
        try {
          timer.start()
          console.log(uniqueId, linkId)

          const resp = await authenticate({ uniqueId, linkId })

          const uri = `/pipeline?${qs.stringify(resp)}`
          history.replace(uri)
        } catch (err) {
          console.error(err)
          timer.reset()
        }
      })()
  }, [timer, history, authenticate, uniqueId, linkId])

  return <LoadingDots className='pt-28' />
}

export default EntrySecureLink
