export default function insertSpaces(num: string, indexes: number[]) {
  for (let i = indexes.length - 1; i >= 0; i--) {
    if (indexes[i] >= num.length) {
      continue
    }
    const index = indexes[i]
    num = num.slice(0, index) + ' ' + num.slice(index)
  }
  return num
}
