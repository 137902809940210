import { journey } from 'sdk/react/hooks/useJourneyContext'
import { UnwrapReply, UnwrapRequest } from 'sdk/transfers/unwrap'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const transfersUnwrap = createAsyncThunk<UnwrapReply, UnwrapRequest>(
  'transfers/unwrap',
  async ({ token }) => {
    return await journey.transfers.unwrap({ token })
  }
)
