import { AuthTokenPayload } from 'sdk/auth/types'
import { journey } from 'sdk/react/hooks/useJourneyContext'
import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { entriesSAML2 } from 'thunks/entries/saml2'
import { transfersUnwrap } from 'thunks/transfers/unwrap'

export type JWTState = {
  token?: string
  payload?: AuthTokenPayload
}

const initialState: JWTState = {}

const setJWT: CaseReducer<JWTState, PayloadAction<string>> = (state, action) => {
  const claims = journey.auth.decodeToken(action.payload)

  return {
    token: action.payload,
    payload: claims,
  }
}

const slice = createSlice({
  name: 'jwt',
  initialState,
  reducers: {
    setJWT,
  },
  extraReducers: builder => {
    builder.addCase(entriesSAML2.fulfilled, (state, { payload }) => {
      state.token = payload.token
      state.payload = journey.auth.decodeToken(payload.token)
    })
    builder.addCase(transfersUnwrap.fulfilled, (state, { payload }) => {
      state.token = payload.token
      state.payload = journey.auth.decodeToken(payload.token)
    })
  },
})

export default slice
export const actions = slice.actions
