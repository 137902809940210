import { JourneyClient } from '../'
import { RetrieveReply, RetrieveRequest } from './retrieve'

class ShortcodeClient {
  private client: JourneyClient

  constructor(client: JourneyClient) {
    this.client = client
  }

  async retrieve(request: RetrieveRequest): Promise<RetrieveReply> {
    const { code } = request
    return await this.client.request('GET', `/api/client/shortcodes/${code}`)
  }
}

export default ShortcodeClient
