import React, { FC } from 'react'
import { t } from 'translation'

import s from './index.module.scss'
import { ReactComponent as SecuredByJourney } from './Secured-By-Journey.svg'
import { ReactComponent as ZKBadge } from './Zero-Knowledge-Badge.svg'

const DataPrivacy: FC = () => {
  return (
    <a href="https://journeyid.com/" target="_blank" rel="noreferrer">
      <div className={s.DataPrivacy}>
        <header>
          {t(`For the strongest data privacy and security possible, we use Zero Knowledge&reg; to
          protect your data and our agents.`)}{' '}
          <strong> {t('Learn More')} &raquo;</strong>
        </header>
        <main>
          <ZKBadge className="h-10" />
          <SecuredByJourney className="h-9" />
        </main>
      </div>
    </a>
  )
}

export default DataPrivacy
