import { WebsocketRequest } from '../sessions/websocket'

import { JourneyClient } from '../'
import Websocket from '../websockets'
import { FeedbackReply, FeedbackRequest } from './feedback'

class SessionClient {
  private client: JourneyClient

  constructor(client: JourneyClient) {
    this.client = client
  }

  async feedback(request: FeedbackRequest): Promise<FeedbackReply> {
    const { id, ...rest } = request
    return await this.client.request('POST', `/api/client/sessions/${id}/feedback`, rest)
  }

  websocket(request: WebsocketRequest): Websocket<'session'> {
    const { id } = request
    return new Websocket<'session'>(this.client, `/sessions/${id}`)
  }
}

export default SessionClient
