import React, { ComponentProps, FC, HTMLProps } from 'react'

import { ReactComponent as CheckIcon } from 'assets/icons/check.svg'
import { ReactComponent as SpinnerThird } from 'assets/icons/spinner-third.svg'
import { ReactComponent as TimesIcon } from 'assets/icons/times.svg'
import Button, { ButtonProps } from 'components/molecules/Button'
import { ActionTimer } from 'hooks/useActionTimer'

type Props = {
  size?: 'sm'
  variant?: ButtonProps['variant']
  timer: ActionTimer
  loadingText: string
  completedText: string
  failedText?: string
} & ComponentProps<typeof Button> &
  Omit<HTMLProps<HTMLButtonElement>, 'size' | 'onClick'>

const LoadingButton: FC<Props> = ({
  variant = 'primary',
  timer,
  loadingText,
  completedText,
  failedText,
  children,
  ...rest
}) => {
  function formatted() {
    if (timer.isLoading) {
      return (
        <>
          <SpinnerThird className="self-center w-4 mr-4 text-gray-600 fill-current animate-spin" />
          {loadingText}
        </>
      )
    } else if (timer.didSucceed) {
      return (
        <>
          <CheckIcon className="self-center w-4 mr-2 text-green-700 fill-current" />
          <span className="text-green-700">{completedText}</span>
        </>
      )
    } else if (timer.didFail && failedText) {
      return (
        <>
          <TimesIcon className="self-center w-3 mr-3 text-red-700 fill-current" />
          <span className="text-red-700">{failedText}</span>
        </>
      )
    }
    return children
  }

  return (
    <Button disabled={!timer.isReady} variant={timer.isReady ? variant : 'tertiary'} {...rest}>
      <div className="flex mx-auto">{formatted()}</div>
    </Button>
  )
}

export default LoadingButton
