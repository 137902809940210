import { format } from 'date-fns'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { enUS as enUSLocale, es as esLocale, ja as jaLocale } from 'date-fns/locale'

import enUS from './en_US.json'
import esMX from './es_MX.json'
import jaJP from './ja_JP.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en-US',
    resources: {
      'ja-JP': {},
      ja: {},
      'en-US': {},
      'es-MX': {},
    },
    keySeparator: '__',
    nsSeparator: false,
    detection: {
      caches: [],
    },
  })

i18n.addResources('ja-JP', 'translation', jaJP)
i18n.addResources('ja', 'translation', jaJP)
i18n.addResources('en-US', 'translation', enUS)
i18n.addResources('es-MX', 'translation', esMX)

export default i18n
export const t = i18n.t.bind(i18n) as (key: string, values?: Record<string, any>) => string

const locales = {
  en: enUSLocale,
  ja: jaLocale,
  es: esLocale,
}

const lang = i18n.language.slice(0, 2) as keyof typeof locales
const opts = { locale: locales[lang] }

export function i18nFormat(date: Date, formatStr: string): string {
  return format(date, formatStr, opts)
}
