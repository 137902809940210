import { JourneyClient } from '../'
import { SubmitRequest } from '../stages/submit_request'
import { SubmitResponse } from '../stages/submit_response'
import { StageType, StageTypeWithInitiation } from '../types'
import { InitiateRequest } from './initiate_request'
import { InitiateResponse } from './initiate_response'

class StageClient {
  private client: JourneyClient

  constructor(client: JourneyClient) {
    this.client = client
  }

  async initiate<T extends StageTypeWithInitiation>(
    request: InitiateRequest<T>
  ): Promise<InitiateResponse<T>> {
    return await this.client.request('POST', '/api/client/stages/initiate', request)
  }

  async submit<T extends StageType>(request: SubmitRequest<T>): Promise<SubmitResponse<T>> {
    return await this.client.request('POST', '/api/client/stages/submit', request)
  }
}

export default StageClient
