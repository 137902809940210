import { JourneyClient } from '../'
import { OAuth2Reply, OAuth2Request } from '../entries/oauth2'
import { SAML2Reply, SAML2Request } from './saml2'
import { SecureLinkReply, SecureLinkRequest } from './secure_link'

class EntryClient {
  private client: JourneyClient

  constructor(client: JourneyClient) {
    this.client = client
  }

  async oauth2(request: OAuth2Request): Promise<OAuth2Reply> {
    return await this.client.request('POST', '/api/client/entries/oauth2/authorize', request)
  }

  async saml2(request: SAML2Request): Promise<SAML2Reply> {
    return await this.client.request('POST', '/api/client/entries/saml2', request)
  }
  async secureLink(request: SecureLinkRequest): Promise<SecureLinkReply> {
    return await this.client.request('POST', '/api/client/entries/link', request)
  }
}

export default EntryClient
