import React, { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'
import { t } from 'translation'

import Field from 'components/molecules/Form/Field'
import LoadingButton from 'components/molecules/LoadingButton'
import { ActionTimer } from 'hooks/useActionTimer'
import useQueryString from 'hooks/useQueryString'

import ErrorMessage from '~pc/atoms/ErrorMessage'
import Frame from '~pc/molecules/Frame'

type QueryParams = {
  logo?: string
  primaryColor?: string
  UniqueID?: string
  unique_id?: string
}

type Props = {
  error?: string
  timer: ActionTimer
  onChange: () => void
  onSubmit: (uniqueId: string) => void
}

const LoginScreen: FC<Props> = ({ error, timer, onChange, onSubmit }) => {
  const [storedUniqueId, storeUniqueId] = useLocalStorage('uniqueId', '')
  const [rememberMe, setRememberMe] = useLocalStorage('rememberMe', true)
  const params = useQueryString<QueryParams>()

  const [uniqueId, setUniqueId] = useState(
    () => params.unique_id || params.UniqueID || storedUniqueId || ''
  )

  useEffect(() => {
    if (!params.primaryColor) return
    const s = document.documentElement.style
    s.setProperty('--theme-primary', params.primaryColor)
  }, [params.primaryColor])

  useEffect(() => {
    if (!params.UniqueID) return
    setUniqueId(params.UniqueID)
    onSubmit(params.UniqueID)
  }, [params, onSubmit])

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    onChange()
    setUniqueId(e.target.value)
  }

  function handleSubmit(e: FormEvent) {
    e.preventDefault()
    if (rememberMe) storeUniqueId(uniqueId)
    onSubmit(uniqueId)
  }

  return (
    <Frame hasLoaded logoUrl={params.logo ?? '/themes/default/header-logo.svg'}>
      <section className="w-full max-w-sm mx-auto mt-16">
        <header className="space-y-2 text-center">
          <h1 className="text-2xl font-bold">{t('Single Sign-On')}</h1>
          <h2>{t('Please enter your Unique ID to login')}</h2>
        </header>
        <main className="items-center py-8 md:py-12">
          <div className="w-full px-4">
            <form onSubmit={handleSubmit}>
              {error && <ErrorMessage className="pb-4 -mt-4">{error}</ErrorMessage>}

              <Field
                label={
                  <div className="flex items-center justify-between w-full">
                    <span>Unique ID</span>
                    <span
                      className="text-xs font-semibold text-center text-gray-500"
                      onClick={() => {
                        localStorage.removeItem('uniqueId')
                        setRememberMe(!rememberMe)
                        if (rememberMe) setUniqueId('')
                      }}
                    >
                      {rememberMe ? "don't" : ''} remember me
                    </span>
                  </div>
                }
                name="UniqueID"
                value={uniqueId}
                onChange={handleChange}
                autoFocus
                className="mb-6"
              />

              <LoadingButton
                type="submit"
                block
                timer={timer}
                loadingText={t('Signing in...')}
                completedText=""
                failedText={t('Unable to start authentication')}
                disabled={!uniqueId}
                className="mt-3 bg-theme-primary"
              >
                {t('Sign in')}
              </LoadingButton>
            </form>
          </div>
        </main>
      </section>
    </Frame>
  )
}

export default LoginScreen
