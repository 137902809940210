import ReconnectingWebSocket from 'reconnecting-websocket'

import { JourneyClient } from './'
import { SessionWebsocketEvent, SessionWebsocketMessage } from './sessions/websocket'

type WebsocketModel = 'session'

export type MessageFor<T extends WebsocketModel> = MessageCore<T> & {
  timestamp: string
} & {
    session: SessionWebsocketMessage
  }[T]

type MessageCore<T extends WebsocketModel> = {
  event: EventFor<T>
}

type EventFor<T extends WebsocketModel> = {
  session: SessionWebsocketEvent
}[T]

export type CallbackFor<T extends WebsocketModel> = (message: MessageFor<T>) => void

class Websocket<T extends WebsocketModel> {
  callback?: CallbackFor<T>
  websocket?: ReconnectingWebSocket

  constructor(client: JourneyClient, uri: string) {
    const origin = `wss://${client.origin || window.location.host}`
    const url = `${origin}/api/client/ws${uri}`
    const ws = new ReconnectingWebSocket(url)

    ws.addEventListener('open', () => {
      ws.send(`CONNECT ${client.authToken}`)
    })

    const self = this
    ws.addEventListener('message', ev => {
      const data = JSON.parse(ev.data) as MessageFor<T>
      self.callback && self.callback(data)
    })

    this.websocket = ws
  }

  close(): void {
    this.websocket?.close()
    delete this.websocket
  }
}

export default Websocket
