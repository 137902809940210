import React, { FC } from 'react'

import { ReactComponent as Icon } from 'assets/icons/exclamation-circle-solid.svg'

import styles from './index.module.scss'

type Props = {
  error: {
    message?: string
  }
}

const ErrorMessage: FC<Props> = ({ error }) => {
  if (!error) {
    return null
  }

  return (
    <div className={styles.ErrorMessage}>
      <Icon />
      <span>{error.message}</span>
    </div>
  )
}

export default ErrorMessage
