import { StageType, StageTypeRequiringMobile } from 'sdk/types'

import { PipelineError } from '~p/pages/Error'

const r = {
  pipeline: {
    bootstrap: '/pipeline',
    execution: '/pipeline/execution',
    success: '/pipeline/success',
    failed: '/pipeline/failed',
    redirect: '/pipeline/redirect',
    error: (type?: PipelineError | string) => `/pipeline/error/${type || ':type'}`,
  },
  entry: {
    oauth2: '/oauth2/authorize',
    saml2: '/saml2/login',
    secureLink: '/link/:linkId/:uniqueId',
  },
  drawer: {
    root: '/drawer/:code',
    pipeline: '/drawer/pipeline',
  },
  transfer: {
    incoming: (stage?: StageTypeRequiringMobile) => `/tx/${stage || ':stage'}`,
  },
  shortcodes: {
    retrieve: (code: string = ':code') => `/s/${code}`,
  },
  stage: (stageType: StageType, page?: string) => `/pipeline/${stageType}${page ? `/${page}` : ''}`,
  sso: {
    scan: '/sso/scan',
  },
}

export default r
