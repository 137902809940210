import { Agent } from 'sdk/types'
import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { executionsBootstrap } from 'thunks/executions/bootstrap'
import { executionsSubmit } from 'thunks/executions/submit'

export type SessionState = {
  session?: {
    id: string
    externalRef: string
    isAuthenticated?: boolean
  }
  agent?: Agent
  completedPipelines: {
    id: string
    title: string
  }[]
}

const initialState: SessionState = {
  completedPipelines: [],
}

const setIsAuthenticated: CaseReducer<SessionState, PayloadAction<boolean>> = (
  state,
  { payload }
) => {
  return {
    ...state,
    isAuthenticated: payload,
  }
}

const slice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setIsAuthenticated,
  },
  extraReducers: builder => {
    builder.addCase(executionsBootstrap.fulfilled, (state, { payload }) => {
      state.session = payload.session
      state.agent = payload.agent
    })
    builder.addCase(executionsSubmit.fulfilled, (state, { payload }) => {
      state.completedPipelines.unshift(payload.pipeline)

      if (!state.session) return

      state.session.isAuthenticated = payload.session.isAuthenticated
    })
  },
})

export default slice
export const actions = slice.actions
